import React from "react";
import { Header, Image, Segment } from "semantic-ui-react";

export default function SBIsClosing() {
  return (
    <div>
      <div
        className='ui vertical masthead center aligned segment basic'
        style={{ marginTop: "10px" }}>
        <Segment basic size='large'>
          <Header size='huge' textAlign='center'>
            <Image src='/assets/images/MoneyAppIcon.png' circular />
            <br />
            <p>
            Sarah's Budget will close on Feb 28, 2022. Please back up your data before then.
            </p>
          </Header>
        </Segment>
      </div>
    </div>
  );
}
