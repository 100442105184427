import { useDispatch } from "react-redux";
import { listenToBudgetMonthsAction } from "../components/Budget/Reducer/budgetActions";
import { convertMonthEntityToTxs } from "../components/Budget/Reducer/budgetHelpers";
import * as budgetdb from "../sbdb.client/budgetdb";
import useFirestoreCollection from "./useFirestoreCollection";
import _ from "lodash";

export default function useBudget(accountId) {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => budgetdb.getBudgetMonthsQuery(accountId),
    data: (monthEntities) => {
      dispatch(
        listenToBudgetMonthsAction(
          _.reduce(
            monthEntities,
            (res, monthEntity) => {
              res[monthEntity.id] = convertMonthEntityToTxs(monthEntity);
              return res;
            },
            {}
          )
        )
      );
    },
    deps: [dispatch, accountId],
  });
}
