import React from "react";
import { Element } from "react-scroll";
import { Card } from "semantic-ui-react";

const OVER_BUDGET_COLOR = "red";
const SAVINGS_COLOR = "green";

export default function BudgetMonthHeaderViewNoDrag({
  ym,
  monthName,
  incomeBudgeted,
  expenseBudgeted,
  savingsBudgeted,
  incomeActual,
  expenseActual,
  savingsActual,
}) {
  // Actual amounts will change during the month as more txs are imported so don't color
  // them red all the time.
  // Budgeted amounts are set in advance for the whole month so DO color total budgeted expense
  // red if the user has budgeted more than they'll earn.
  const expensesColor = Math.abs(expenseBudgeted) > incomeBudgeted ? OVER_BUDGET_COLOR : "black";
  const savingsColor = savingsBudgeted < 0 ? SAVINGS_COLOR : "black";
  return (
    <Card centered style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
      <Card.Content>
        <Card.Header textAlign='center'>
          <Element name={ym}>{monthName}</Element>
        </Card.Header>
        <Card.Meta textAlign='center' style={{ color: "rgba(0, 0, 0, 0.7)" }}>
          <span>
            Income: {!isNaN(incomeActual) && `${Math.round(incomeActual)} / `}{" "}
            {Math.round(incomeBudgeted)}
          </span>
          <br />
          <span>
            Expenses: {!isNaN(expenseActual) && `${Math.round(expenseActual)} / `}{" "}
            <span style={{ color: expensesColor }}>{Math.round(expenseBudgeted)}</span>
          </span>
          <br />
          <span>
            Savings: {!isNaN(savingsActual) && `${Math.round(savingsActual)} / `}{" "}
            <span style={{ color: savingsColor }}>{Math.round(savingsBudgeted)}</span>
          </span>
        </Card.Meta>
      </Card.Content>
    </Card>
  );
}
