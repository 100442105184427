import axios from "axios";
import { getFirestoreDb } from "../app/configureFirebase";
import { dataFromSnapshot } from "./firestore/firestoreService";
import * as mystuffdb from "./mystuffdb";
import _ from "lodash";
import * as accountsdb from "./accountsdb";
import * as goalsdb from "./goalsdb";
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export const SUBSCRIPTION_LEVEL_FREE_USER = 1;

// Call this the first time to set createdAt and email.
// subscriptionExpiration can be a date or null. Null means it's expired or never purchased.
// Call updateUserProfile to change displayName or photoURL.
export async function setNewUserProfileData(
  user,
  subscriptionExpiration = null,
  subscriptionLevel = 0
) {
  try {
    // console.log("setNewUserProfileData", user);
    if (!user) {
      throw new Error("user", user);
    }
    if (!user.providerData || user.providerData.length === 0) {
      throw new Error("user.providerData", user.providerData);
    }
    const db = getFirestoreDb();
    // console.log("Sending user info to DB...", user);
    await setDoc(doc(db, "users", user.uid), {
      displayName: user.displayName,
      email: user.email,
      providerId: user.providerData[0].providerId, // Auth Provider: google.com, facebook.com, etc.
      // User UID according to the login provider. Firestore Auth gives us a differe uid, but we need
      // the original to be able to map between the two.
      providerUidDontUse: user.providerData[0].uid,
      photoURL: user.photoURL || "",
      // Trial expires 14 days after createdAt.
      // Free users (pre-React users, family/friends) get subscriptionExpiration in the future.
      createdAt: serverTimestamp(),
      subscriptionCustomerID: "",
      subscriptionProvider: 0, // 0 is none, 1 is Stripe, 2 is Apple
      subscriptionExpiration,
      // 0 is pre-React user or other Free member (i.e. from the old Azure/ASP.Net site)
      // 1 is trial: treat this like paid users because they've entered payment info.
      // 2 is paid subscriber (only paid option for now; could do basic/pro later)
      // TODO: Could add a field to keep track of different types of free accounts, i.e. freeReason
      subscriptionLevel,
    });
    // console.log("Done sending user info to DB");

    // Also need mystuff (empty),
    await mystuffdb.init(user.uid);
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

// Change email, displayName or photoURL.
// TODO: unit test this.
export async function updateUserProfile(profile) {
  const user = getAuth().currentUser;
  try {
    if (
      user.displayName !== profile.displayName ||
      user.email !== profile.email ||
      user.photoURL !== profile.photoURL
    ) {
      await user.updateProfile({
        displayName: profile.displayName,
        photoURL: profile.photoURL || null,
      });

      // Don't change subscription fields! Modifying a subscription on purpose
      // is a different function.
      await updateDoc(
        getUserProfileRef(user.uid),
        _.pick(profile, ["email", "displayName", "photoURL"])
      );

      // Accounts and Goals have an Owners field that has a copy of email and displayName so
      // if either of those changed, we have to update account info and goal info.
      if (user.displayName !== profile.displayName || user.email !== profile.email) {
        await accountsdb.updateAccountOwnerProfile(user.uid, profile.displayName, profile.email);

        await goalsdb.updateGoalOwnerProfile(user.uid, profile.displayName, profile.email);
      }
    }
  } catch (error) {
    throw error;
  }
}

export async function getUserProfile(userId) {
  try {
    const db = getFirestoreDb();
    const userDoc = await getDoc(doc(db, "users", userId));
    return dataFromSnapshot(userDoc);
  } catch (error) {
    console.log("error", error);
    return null;
  }
}

export function getUserProfileRef(userId) {
  const db = getFirestoreDb();
  return doc(db, "users", userId);
}

export async function isUserAllowed(email) {
  try {
    if (!email) {
      throw new Error("Error finding user. Missing info.");
    }
    
    // Ask the server if this user is in our allowed users table
    const userResponse = await axios.post("/budgetadmin-isUserAllowedByEmail", {
      email,
    });
    // console.log('userResponse', userResponse);

    return userResponse.data.isAllowed;
  } catch (error) {
    console.log('error', error);
    return false;
  }
}

export async function getUserByEmail(email) {
  try {
    if (!email) {
      throw new Error("Error finding user. Missing info.");
    }

    const userResponse = await axios.post("/budgetadmin-getUserByEmail", {
      email,
    });
    // console.log('userResponse', userResponse);

    return userResponse.data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}
