import { SET_USER, SIGN_IN_USER, REGISTER_USER_UNSUBSCRIBE_FUNCS, SIGN_OUT_USER, SET_LINK_TOKEN, BEGIN_LOGIN } from "./authConstants";
import { appLoaded } from "../../../app/Reducer/appActions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as usersdb from "../../../sbdb.client/usersdb";
import { dataFromSnapshot } from "../../../sbdb.client/firestore/firestoreService";
import { listenToCurrentUserProfile } from "../../User/Reducer/profileActions";
import * as mystuffdb from "../../../sbdb.client/mystuffdb";
import { clearGoalsAction, setHiddenGoalIdsAction } from "../../Goals/Reducer/goalsActions";
import { clearBudgetAction, selectedAccountChangedAction } from "../../Budget/Reducer/budgetActions";
import { clearAccountsAction } from "../../Accounts/Reducer/accountsActions";
import { clearBudgetUIAction } from "../../Budget/Reducer/UI/budgetuiActions";
import { asyncClearError } from "../../Async/Reducer/asyncActions";
import { clearRepeatAction } from "../../RepeatTxs/Reducer/repeatTxsActions";
import { clearSpendingAction } from "../../Spending/Reducer/spendingActions";
import { clearSpendingUIAction } from "../../Spending/Reducer/UI/spendinguiActions";
import { getFirebaseApp } from "../../../app/configureFirebase";
import { onSnapshot } from "firebase/firestore";

export function signInUser(user) {
  return {
    type: SIGN_IN_USER,
    payload: {
      user,
    },
  };
}

export function registerUserUnsubscribeFuncs(unsubscribeFuncs) {
  return {
    type: REGISTER_USER_UNSUBSCRIBE_FUNCS,
    payload: {
      unsubscribeFuncs,
    },
  };
}

export function beginLoginAction() {
  return {
    type: BEGIN_LOGIN,
    payload: {},
  }
}

function clearAllUserState(dispatch) {
  // accounts
  dispatch(clearAccountsAction());
  // TODO: Async and ListenToCurrentUserProfile are fragile because we could add more state and it wouldn't be cleared. They're good for now.
  dispatch(asyncClearError());
  dispatch(listenToCurrentUserProfile(null));

  dispatch(clearBudgetAction());
  dispatch(clearBudgetUIAction());
  dispatch(clearRepeatAction());

  dispatch(clearSpendingAction());
  dispatch(clearSpendingUIAction());

  dispatch(clearGoalsAction());
}

export function verifyAuth() {
  return function (dispatch, getState) {
    const firebaseAuth = getAuth(getFirebaseApp());
    return onAuthStateChanged(firebaseAuth, async (user) => {
      if (user?.email && await usersdb.isUserAllowed(user.email)) {
        let userProfile = await usersdb.getUserProfile(user.uid);
        if (!userProfile) {
          console.log("Setting new user profile data");
          await usersdb.setNewUserProfileData(user);

          userProfile = await usersdb.getUserProfile(user.uid);
          console.log(userProfile);
        } else if (
          userProfile.email !== user.email ||
          userProfile.displayName !== user.displayName ||
          userProfile.photoURL !== user.photoURL
        ) {
          // This changes the user's profile and email/displayName on accounts, goals
          await usersdb.updateUserProfile(user);
        }
        // TODO: update the last time the user visited SB.com

        // Clear out data from a previous user in case they logged out and logged in
        // as someone else.
        // Do this before hooking up the myStuff and profile listeners to prevent a race condition.
        clearAllUserState(dispatch);

        // Do this before registering listeners in case a callback needs the user profile info.
        dispatch(signInUser(userProfile));

        const myStuffRef = mystuffdb.getMyStuffRef(user.uid);
        const myStuffUnsubscribe = onSnapshot(myStuffRef, (snapshot) => {
          const myStuffUpd = dataFromSnapshot(snapshot);
          if (myStuffUpd) {
            // Only set selectedAccountId if we don't already have one.
            // If the user has two browsers open, we don't want to change the selected
            // account in both browsers, just in the one they're working on.
            if (!getState().budget?.selectedAccountId) {
              // NOTE: Don't dispatch changeSelectedAccountThunk because that writes to myStuff, which
              // would cause an infinite update loop.
              dispatch(selectedAccountChangedAction(myStuffUpd.selectedAccountId));
            }
            // But hidden goals can apply to all open browser instances.
            dispatch(setHiddenGoalIdsAction(myStuffUpd.hiddenGoalIds));
          }
        });

        const profileRef = usersdb.getUserProfileRef(user.uid);
        const profileUnsubscribe = onSnapshot(profileRef, (snapshot) => {
          dispatch(listenToCurrentUserProfile(dataFromSnapshot(snapshot)));
          dispatch(appLoaded());
        });

        dispatch(registerUserUnsubscribeFuncs([myStuffUnsubscribe, profileUnsubscribe]));
      } else {
        const unsubs = getState().auth.unsubscribeFuncs;
        for (const unsubFunc of unsubs) {
          unsubFunc();
        }
        // In case they log in again or go back or something.
        clearAllUserState(dispatch);

        dispatch(signOutUser());
        dispatch(appLoaded());
      }
    });
  };
}

export function signOutUser() {
  return {
    type: SIGN_OUT_USER,
  };
}

// This is for TestComponent (sandbox)
export function setUser(user) {
  return {
    type: SET_USER,
    payload: {
      user,
    },
  };
}

export function setUserLinkTokenAction(linkToken) {
  return {
    type: SET_LINK_TOKEN,
    payload: {
      linkToken,
    },
  };
}
