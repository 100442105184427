import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  asyncActionStart,
  asyncActionError,
  asyncActionFinish,
} from "../components/Async/Reducer/asyncActions";
import { dataFromSnapshot } from "../sbdb.client/firestore/firestoreService";
import { onSnapshot } from "firebase/firestore";

export default function useFirestoreDoc({ query, data, deps, shouldExecute = true }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryRef = query && query();
    if (!shouldExecute || !queryRef) {
      return;
    }
    dispatch(asyncActionStart());
    const unsubscribe = onSnapshot(queryRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          dispatch(asyncActionError({ code: "not-found", message: "Could not find document" }));
          return;
        }
        data(dataFromSnapshot(snapshot));
        dispatch(asyncActionFinish());
      },
      (error) => {
        dispatch(asyncActionError(error));
      }
    );
    return () => {
      unsubscribe();
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
