import React from "react";
import { Card } from "semantic-ui-react";
import AccountsListItem from "./AccountsListItem";

export default function AccountsList({ accounts }) {
  return (
    <Card.Group stackable>
      {accounts.length !== 0 &&
        accounts.map((account) => <AccountsListItem account={account} key={account.id} />)}
    </Card.Group>
  );
}
