import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Image } from "semantic-ui-react";

export default function AccountListItem({ account: { id, name, description } }) {
  return (
    <Card>
      <Card.Content textAlign='center'>
        <Image avatar src='/assets/images/MoneyAppIcon.png' size='tiny' floated='left' />
        <Card.Header>{name}</Card.Header>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button as={Link} basic to={`/app/accounts/${id}`} color='purple'>
          Edit
        </Button>
      </Card.Content>
    </Card>
  );
}
