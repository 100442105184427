import { getFirestoreDb } from "../app/configureFirebase";
import { dataFromSnapshot } from "./firestore/firestoreService";
import {
  getDocs,
  getDoc,
  arrayUnion,
  arrayRemove,
  updateDoc,
  doc,
  query,
  collection,
  where,
  writeBatch,
  deleteField,
  addDoc,
  increment,
} from "firebase/firestore";
import _ from "lodash";

export function getGoalRef(goalId) {
  const db = getFirestoreDb();
  return doc(db, "goals", goalId);
}

export async function getGoal(goalId) {
  try {
    return dataFromSnapshot(await getDoc(getGoalRef(goalId)));
  } catch (error) {
    console.log(`Error getting goal ${goalId}`, error);
  }
}

export function getGoalsQuery(userId) {
  const db = getFirestoreDb();
  return query(collection(db, "goals"), where("ownerIds", "array-contains", userId));
}

// goal must have an owner - the user who is creating it.
export async function createGoal(
  userId,
  userDisplayName,
  userEmail,
  goalName,
  targetDate,
  targetAmount
) {
  const goalEntity = {
    name: goalName,
    targetDate,
    targetAmount,
    budgetedAmount: 0.0,
    actualAmount: 0.0,
    owners: {
      [userId]: {
        displayName: userDisplayName,
        email: userEmail,
      },
    },
    ownerIds: [userId],
  };

  try {
    const db = getFirestoreDb();
    const newGoalRef = await addDoc(collection(db, "goals"), goalEntity);
    return newGoalRef.id;
  } catch (error) {
    console.log("Error creating goal", error);
    throw error;
  }
}

export async function deleteGoal(goalId) {
  try {
    if (!goalId) {
      throw new Error("Error deleting goal. Missing info.");
    }
    await getGoalRef(goalId).delete();
  } catch (error) {
    console.log(`Error deleting goal ${goalId}`, error);
    throw error;
  }
}

// goalAmountChanges: {
//   [goalId]: { budgetedChange, actualChange }
// }
export async function applyGoalAmountChanges(goalAmountChanges) {
  const db = getFirestoreDb();
  const batch = writeBatch(db);
  Object.keys(goalAmountChanges).forEach((goalId) => {
    if (!goalId) {
      return;
    }
    batch.update(getGoalRef(goalId), {
      budgetedAmount: increment(goalAmountChanges[goalId]?.budgetedChange || 0.0),
      actualAmount: increment(goalAmountChanges[goalId]?.actualChange || 0.0),
    });
  });
  await batch.commit();
}

export async function addUserToGoal(userId, email, displayName, goalId) {
  if (!goalId || !userId) {
    throw new Error("Error adding user to the goal. Missing info.");
  }
  // Update goal owners
  await updateDoc(getGoalRef(goalId), {
    [`owners.${userId}`]: { email, displayName },
    ownerIds: arrayUnion(userId),
  });
}

// Don't let users remove themselves if they're the last owner.
// Users can remove themselves if other people still own the goal though.
// This is different from deleting the goal because other people might still be using it and
// the current user doesn't want to use it any more.
// Firestore security rules check that the current user is an owner before letting them make changes.
export async function removeUserFromGoal(userId, goalId) {
  if (!goalId || !userId) {
    throw new Error("Error removing user from the goal: Missing info.");
  }
  const goalInfo = await getGoal(goalId);
  if (!goalInfo) {
    throw new Error(
      "Error removing user from the goal: Goal does not exist or the user does not have permission."
    );
  }
  if (Object.keys(goalInfo.owners).length === 1) {
    throw new Error("Error removing user from the goal: No other owners. Delete the goal instead.");
  }
  await updateDoc(getGoalRef(goalId), {
    [`owners.${userId}`]: deleteField(),
    ownerIds: arrayRemove(userId),
  });
}

// Call this from the goal details page to change goal name, target date, or
// target amount.
export async function updateGoal(goalId, newValues) {
  if (!goalId || !newValues) {
    console.log("Error updating goal.");
    return;
  }

  try {
    await updateDoc(getGoalRef(goalId), newValues);
  } catch (error) {
    console.log("Error updating goal", error);
    throw error;
  }
}

export async function updateGoalOwnerProfile(userId, newDisplayName, newEmail) {
  const db = getFirestoreDb();
  const goalDocs = await getDocs(getGoalsQuery(userId));
  const goalIds = _.map(goalDocs, "id");
  const goalsBatch = writeBatch(db);
  // Update goal info: owners with the new info
  for (const goalId of goalIds) {
    goalsBatch.set(
      getGoalRef(goalId),
      {
        [`owners.${userId}`]: { email: newEmail, displayName: newDisplayName },
        ownerIds: arrayUnion(userId),
      },
      { merge: true }
    );
  }
  // TODO: Retries
  await goalsBatch.commit();
}

