import React from "react";
import { Card } from "semantic-ui-react";
import GoalsListItem from "./GoalsListItem";

// Each goal must have isHidden property (from mystuff.hiddenGoalIds)
export default function GoalsList({ goals, onHideUnhideClicked }) {
  return (
    <Card.Group stackable>
      {goals.length !== 0 &&
        goals.map((goal) => (
          <GoalsListItem goal={goal} key={goal.id} onHideUnhideClicked={onHideUnhideClicked} />
        ))}
    </Card.Group>
  );
}
