import { getFirestoreDb } from "../app/configureFirebase";
import { query, collection, where, getDoc, getDocs, arrayUnion, arrayRemove, doc, updateDoc, deleteField, addDoc } from "firebase/firestore"
import * as sbm from "./util/sampleBudgetMonth";
import * as repeatdb from "./repeatdb";
import { toYyyyMm } from "../util/helpers";
import * as budgetdb from "./budgetdb";
import { dataFromSnapshot } from "./firestore/firestoreService";
import { writeBatch } from "firebase/firestore";
import _ from "lodash";
import axios from "axios";

export function getAccountRef(accountId) {
  if (!accountId) {
    return null;
  }
  const db = getFirestoreDb();
  return doc(db, "accounts", accountId);
}

export async function getAccount(accountId) {
  if (!accountId) {
    return null;
  }
  try {
    const accountInfoSnap = await getDoc(getAccountRef(accountId));
    const accountInfoDoc = dataFromSnapshot(accountInfoSnap);
    const ownersArray = _.map(accountInfoDoc.ownerIds, (ownerIdM) => ({
      id: ownerIdM,
      ...accountInfoDoc.owners[ownerIdM], // displayName, email
    }));
    const accountInfo = {
      id: accountId,
      name: accountInfoDoc.name,
      description: accountInfoDoc.description,
      owners: _.sortBy(ownersArray, "displayName"),
    };
    return accountInfo;
  } catch (error) {
    console.log(`Error getting account ${accountId}`, error);
  }
}

export function getAccountsQuery(userId) {
  if (!userId) {
    return null;
  }
  const db = getFirestoreDb();
  return query(collection(db, "accounts"), where("ownerIds", "array-contains", userId));
}

export async function createAccount(
  userId,
  userDisplayName,
  userEmail,
  accountName,
  description,
  makeSampleMonth
) {
  const today = new Date();

  const accountInfoEntity = {
    name: accountName,
    description,
    owners: {
      [userId]: {
        displayName: userDisplayName,
        email: userEmail,
      },
    },
    ownerIds: [userId],
  };

  
  try {
    const db = getFirestoreDb();
    // Make the account info doc
    const newAccountRef = await addDoc(collection(db, "accounts"), accountInfoEntity);
    // add account info - get account id
    const accountId = newAccountRef.id;

    if (makeSampleMonth) {
      const smBatch = writeBatch(db);
      const sampleMonth = sbm.createSampleMonth(today);
      const yyyymm = toYyyyMm(today);
      // Insert the budget month into the DB
      smBatch.set(budgetdb.getMonthRef(accountId, yyyymm), sampleMonth.budget);
      // Insert the repeating txs object into the DB
      smBatch.set(repeatdb.getRepeatTxsRef(accountId), sampleMonth.repeating);
      await smBatch.commit();
    }

    return accountId;
  } catch (error) {
    console.log("Error creating budget account", error);
    throw error;
  }
}

// Firestore security rules check that the current user is a account owner before letting them make changes
export async function addUserToAccount(userId, email, displayName, accountId) {
  if (!accountId || !userId) {
    throw new Error("Error adding user to the budget account. Missing info.");
  }
  // Update account owners
  await updateDoc(getAccountRef(accountId), {
    [`owners.${userId}`]: { email, displayName },
    ownerIds: arrayUnion(userId),
  });
}

// NOTE: Callers must run the Cloud Function unlinkAllUserBankAccountsFromBudget before calling this.
// Don't let users remove themselves if they're the last owner.
// Users can remove themselves if other people still own the account though.
// This is different from deleting the account because other people might still be using it and
// the current user doesn't want to use it any more.
// Firestore security rules check that the current user is an owner before letting them make changes.
export async function removeUserFromAccount(userId, accountId) {
  if (!accountId || !userId) {
    throw new Error("Error removing user from the budget account: Missing info.");
  }
  const accountInfo = await getAccount(accountId);
  if (!accountInfo) {
    throw new Error(
      "Error removing user from the budget account: Account does not exist or the user does not have permission."
    );
  }
  if (Object.keys(accountInfo.owners).length === 1) {
    throw new Error(
      "Error removing user from the budget account: No other owners. Delete the account instead."
    );
  }
  // console.log(`Removing the user ${userId} from the account document ${accountId}`);
  await updateDoc(getAccountRef(accountId), {
    [`owners.${userId}`]: deleteField(),
    ownerIds: arrayRemove(userId),
  });
}

export async function updateAccount(accountId, newValues) {
  if (!accountId || !newValues) {
    throw new Error("Error updating account. Missing info.");
  }
  try {
    // update account info
    await updateDoc(getAccountRef(accountId), newValues);
  } catch (error) {
    console.log("Error updating budget account", error);
    throw error;
  }
}

export async function updateAccountOwnerProfile(userId, newDisplayName, newEmail) {
  const db = getFirestoreDb();
  const accountsBatch = writeBatch(db);
  // Get account IDs for this user
  const accountDocs = await getDocs(getAccountsQuery(userId));
  const accountIds = _.map(accountDocs, "id");
  // Update account info: owners with the new info
  for (const accountId of accountIds) {
    accountsBatch.set(
      getAccountRef(accountId),
      {
        [`owners.${userId}`]: { email: newEmail, displayName: newDisplayName },
        ownerIds: arrayUnion(userId),
      },
      { merge: true }
    );
  }
  // TODO: Retries
  await accountsBatch.commit();
}


export async function deleteAccount(accountId) {
  if (!accountId) {
    throw new Error("Error deleting account. Missing info.");
  }
  try {
    // Do it on the server because we have to delete budget months, spending months,
    // repeat txs doc, and update creds, then delete the account doc.
    await axios.post("/budgetadmin-deleteBudget", {
      accountId,
    });
  } catch (error) {
    console.log("Error deleting budget account", error);
    throw error;
  }
}

