import { CLEAR_BUDGET_UI, BUDGET_UI_SHOW_MONTHS, BUDGET_UI_SET_CLOSEST_TXID_TO_TODAY } from "./budgetuiConstants"


export function clearBudgetUIAction() {
  return {
    type: CLEAR_BUDGET_UI,
  }
}
// Pass in strings: yyyyMM
export function budgetuiShowMonthsAction(fromMonth, toMonth) {
  return {
    type: BUDGET_UI_SHOW_MONTHS,
    payload: {
      firstBudgetYmLoaded: fromMonth,
      lastBudgetYmLoaded: toMonth,
    }
  }
}

export function budgetuiSetClosestTxIdToTodayAction(txId) {
  return {
    type: BUDGET_UI_SET_CLOSEST_TXID_TO_TODAY,
    payload: {
      closestTxIdToToday: txId,
    }
  }
}