import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MyTextInput from "../../UI/Form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { deleteGoalThunk, updateGoalThunk } from "../Reducer/goalsThunks";
import { Button, Confirm, List, Ref, Segment } from "semantic-ui-react";
import MyDateInput from "../../UI/Form/MyDateInput";
import useSelectedGoal from "../../../hooks/useSelectedGoal";
import LoadingComponent from "../../UI/LoadingComponent";
import { asyncClearError } from "../../Async/Reducer/asyncActions";
import AppPageMenu from "../../UI/Menus/AppPageMenu";

export default function GoalDetailsPage({ match }) {
  const dispatch = useDispatch();
  const selectedGoal = useSelector((state) => state.goals.selectedGoal) || {};
  const { loading: asyncLoading, error } = useSelector((state) => state.async);
  const [loadingPage, setLoadingPage] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  // Get ID from the URL
  const showGoalId = match.params.id;
  const navigate = useNavigate();
  const contextRef = createRef();

  useSelectedGoal(showGoalId);

  const loading = asyncLoading || loadingPage;

  useEffect(
    function () {
      setLoadingPage(true);
      if (error) {
        // Recover by going back to the goals page.
        dispatch(asyncClearError());

        navigate("/app/goals");
      }

      setLoadingPage(false);
    },
    [dispatch, navigate, error]
  );

  if (loading) {
    return <LoadingComponent content='Loading goal...' />;
  }
  // if no goals, go back to the goals page - it will ask the user to create a goal
  if (!showGoalId || !selectedGoal) {
    navigate('/app/goals');
    return;
  }

  const { name, targetDate, targetAmount } = selectedGoal;

  function handleConfirmDelete() {
    setConfirmDelete(true);
  }
  function handleDeleteCanceled() {
    setConfirmDelete(false);
  }
  function handleDeleteConfirmed() {
    async function doDeleteGoal() {
      // Delete the goal for all users.
      await dispatch(deleteGoalThunk(showGoalId));

      setConfirmDelete(false);

      navigate("/app/goals");
    }
    doDeleteGoal();
  }

  return (
    <Formik
      initialValues={{ name, targetDate, targetAmount }}
      validationSchema={Yup.object({
        name: Yup.string().required("Please enter a goal name"),
        targetDate: Yup.date("Please enter a valid date").required("Please enter a target date"),
        targetAmount: Yup.number("Please enter a valid amount").required(
          "Please enter a target amount"
        ),
      })}
      onSubmit={async (values, { resetForm, setSubmitting, setErrors }) => {
        try {
          await dispatch(updateGoalThunk(showGoalId, values));
          setSubmitting(false);
          resetForm({ values: { name, targetDate, targetAmount } });
        } catch (error) {
          setErrors({ auth: "Error updating the goal." });
          setSubmitting(false);
        }
      }}>
      {({ isSubmitting, isValid, dirty, errors, resetForm }) => (
        <Ref innerRef={contextRef}>
          <div>
            <AppPageMenu
              loading={loading}
              ref={contextRef}
              title={selectedGoal?.name ?? "Looking for Goal..."}
              subtitle='Goal Details'
            />
            <Form className='ui form' style={{ marginTop: "75px" }}>
              {/* TODO: Make this link match up with the goal sharing page (pixel alignment) */}
              <Button basic onClick={() => navigate("/app/goals/")} disabled={isSubmitting}>
                &lt; Goals
              </Button>

              <Segment className='editgoalaccount' basic>
                <MyTextInput name='name' placeholder='Goal Name' />
                <MyDateInput name='targetDate' placeholder='Target Date' />
                <MyTextInput
                  name='targetAmount'
                  placeholder='+ for saving, - for spending'
                  type='number'
                  label='Target Amount'
                />

                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  style={{ visibility: dirty ? "visible" : "hidden" }}
                  type='submit'
                  size='large'
                  positive
                  content='Save Changes'
                />
                <Button
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                  style={{ visibility: dirty ? "visible" : "hidden" }}
                  size='large'
                  content='Cancel'
                  onClick={() => resetForm()}
                />

                <List selection>
                  <List.Item as={Link} to={`/app/goalsharing/${showGoalId}`}>
                    Goal Sharing &gt;
                  </List.Item>
                </List>

                <div style={{ marginTop: "70px", textAlign: "center" }}>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type='button'
                    size='large'
                    negative
                    compact
                    onClick={handleConfirmDelete}
                    content='Delete'
                  />
                  <Confirm
                    open={confirmDelete}
                    header='Are you sure?'
                    content='This will delete the goal for all shared owners.'
                    onCancel={handleDeleteCanceled}
                    onConfirm={handleDeleteConfirmed}
                    confirmButton='Delete'
                    size='tiny'
                  />
                </div>
              </Segment>
            </Form>
          </div>
        </Ref>
      )}
    </Formik>
  );
}
