import { getFirestoreDb } from "../app/configureFirebase";
import { getDocs, getDoc, where, limitToLast, orderBy, updateDoc, doc, query, collection } from "firebase/firestore";
import { format } from "date-fns";
import { dataFromSnapshot } from "./firestore/firestoreService";
import { makeFSId } from "./util/fsHelper";

export function getBudgetMonthsQuery(accountId) {
  if (!accountId) {
    return null;
  }
  const db = getFirestoreDb();
  return query(collection(db, `accounts/${accountId}/budget`),
    where("id", ">=", "201501"),
    where("id", "<=", "500001")); // <= 500001 so we don't listen to other docs
}

export function getMonthRef(accountId, yyyymm) {
  if (!accountId || !yyyymm) {
    return null;
  }
  const db = getFirestoreDb();
  return doc(db, `accounts/${accountId}/budget`, yyyymm);
}
// Txs are in 1-month blocks. It's up to the caller to put together a list of txs from
// the month data, which means figure out the balances.
export async function getMonth(accountId, yyyymm) {
  try {
    if (!accountId || !yyyymm) {
      return null;
    }
    return dataFromSnapshot(await getDoc(getMonthRef(accountId, yyyymm)));
  } catch (error) {
    console.log(`Error getting budget month ${accountId} ${yyyymm}`, error);
    throw error;
  }
}

// Used by tests
export async function updateMonthStartBalance(accountId, yyyymm, startBalance) {
  try {
    if (!accountId || !yyyymm) {
      throw new Error("Error updating balances. Missing info.");
    }
    await updateDoc(getMonthRef(accountId, yyyymm), { startBalance });
  } catch (error) {
    console.log("Error updating budget month start balance", error);
    throw error;
  }
}

// Returns YM for the last full or partial month.
export async function getLastYm(accountId) {
  if (!accountId) {
    return null;
  }
  const db = getFirestoreDb();
  const lastYmQuery = query(
    collection(db, `accounts/${accountId}/budget`),
    where("id", ">=", "201501"),
    orderBy("id"),
    limitToLast(1)
  );
  const lastMonthSnapshot = await getDocs(lastYmQuery);
  return lastMonthSnapshot.docs.length === 1 && lastMonthSnapshot.docs[0].exists()
    ? lastMonthSnapshot.docs[0].id
    : null;
}

// Full months are created from repeat txs. Partial months
// have some txs, but not from repeat txs.
// Returns null if there are no full months in the budget.
export async function getLastFullYm(accountId) {
  if (!accountId) {
    return null;
  }
  const db = getFirestoreDb();
  const lastFullYmQuery = query(
    collection(db, `accounts/${accountId}/budget`),
    where("id", ">=", "201501"),
    where("isFullMonth", "==", true),
    orderBy("id"),
    limitToLast(1)
  );
  const lastMonthSnapshot = await getDocs(lastFullYmQuery);
  return lastMonthSnapshot.docs.length === 1 && lastMonthSnapshot.docs[0].exists()
    ? // Don't need dataFromSnapshot because we're just returning the id (YM)
      lastMonthSnapshot.docs[0].id
    : null;
}

export function makeId(theDate, category) {
  if (!theDate) {
    throw new Error("Date is required");
  }
  if (!category) {
    throw new Error("Category is required");
  }
  return makeFSId(`${format(theDate, "yyyyMMdd")}_${category}`);
}
