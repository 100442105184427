import React from "react";
import { Label, Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../UI/Modals/Reducer/modalActions";
import ModalWrapper from "../../UI/Modals/ModalWrapper";
import MyTextInput from "../../UI/Form/MyTextInput";
import MySelectInput from "../../UI/Form/MySelectInput";
import SaveCancelConfirmDeleteButtons from "../../UI/Modals/SaveCancelConfirmDeleteButtons/SaveCancelConfirmDeleteButtons";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { getBudgetTxSelectOptions } from "../Reducer/UI/spendinguiHelpers";
import _ from "lodash";

// values are the current Formik form values, used for conditionally showing fields.
// budgetTxsNearDate:
export default function SpendingItemSplitsModal({
  title,
  spendingTxToEdit,
  onSaveSplits,
  // onDelete, // TODO: Allow users to delete actual txs??
  budgetTxsNearDate,
}) {
  const dispatch = useDispatch();

  if (!spendingTxToEdit || !budgetTxsNearDate || budgetTxsNearDate.length === 0) {
    // Bug somewhere
    dispatch(closeModal());
    return;
  }

  // We shouldn't be here if budgetTxsNearDate is empty.
  // [None] isn't an option for the splits page.
  const budgetTxOptions = getBudgetTxSelectOptions(budgetTxsNearDate);

  const splitsArray =
    Object.entries(spendingTxToEdit.splits || {}).map((split) => ({
      id: split[0],
      amount: split[1],
    })) ?? [];

  const splitAmountWrongSignMsg =
    spendingTxToEdit.amount > 0 ? "Amounts must be positive" : "Amounts must be negative";

  return (
    <ModalWrapper size='mini' header={title}>
      <Formik
        initialValues={{
          splits: [splitsArray],
        }}
        validationSchema={Yup.object({
          splits: Yup.array()
            .of(
              Yup.object().shape({
                amount: Yup.number()
                  .required("*")
                  .test("amount-test", splitAmountWrongSignMsg, function (value) {
                    // This handles 0 too - splits can't be 0
                    return (
                      (Math.sign(spendingTxToEdit.amount) < 0 && value < 0) ||
                      (Math.sign(spendingTxToEdit.amount) > 0 && value > 0)
                    );
                  }),
              })
            )
            .test("test-sum", "Splits can't add up to more than the transaction", function (splits) {
              return (
                Math.abs(_.sumBy(splits, "amount")) <=
                Math.abs(spendingTxToEdit.amount)
              );
            }),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            // Remove any null or undefined selections (Yup makes this unnecessary now).
            onSaveSplits(values.splits.filter((valF) => valF.id && valF.amount));

            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            setErrors({ auth: "Error updating the item." });
            setSubmitting(false);
          }
        }}>
        {({ isSubmitting, isValid, dirty, errors, values: { splits } }) => (
          <Form className='ui form'>
            <FieldArray name='splits'>
              {({ insert, remove, push }) => (
                <>
                  {splits.length > 0 &&
                    splits.map((split, index) => (
                      <>
                        <MyTextInput
                          name={`splits.${index}.amount`}
                          placeholder='0.00'
                          type='number'
                          label='Amount'
                        />
                        <MySelectInput
                          name={`splits.${index}.id`}
                          placeholder='[None]'
                          options={budgetTxOptions}
                          label='Budget Category'
                        />
                        <Button type='button' onClick={() => remove(index)} icon='delete' />
                      </>
                    ))}
                  <Button onClick={() => push({ id: null, amount: 0.0 })} icon='plus'></Button>
                </>
              )}
            </FieldArray>

            {errors.auth && (
              <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
            )}
            <SaveCancelConfirmDeleteButtons
              showDelete={false}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
