import React, { createRef } from "react";
import SpendingMonthHeaderView from "./SpendingMonthHeaderView";
import SpendingListItem from "../SpendingListItem/SpendingListItem";
import { Divider, Grid, Sticky } from "semantic-ui-react";

const ROW_COLOR_EVEN_MONTH = "evenmonth";
const ROW_COLOR_ODD_MONTH = "oddmonth";

export default function SpendingListMonthItem({
  month,
  ym,
  monthName,
  budgetTxsNearDate,
  onEditSplits,
  onAssignBudgetTx,
}) {
  const { incomeActual, expenseActual, txs: spendingTxs } = month;

  const ymColor = parseInt(ym) % 2 === 0 ? ROW_COLOR_EVEN_MONTH : ROW_COLOR_ODD_MONTH;

  function getRowColor(txDate, ix) {
    // "Today" = next upcoming date
    // Today is highlighted (all txs with today's date, or closest to today, but not before).
    // Yes, closest_to_today will be wrong if we load way in the future before we load around today,
    // but it will correct itself as the user scrolls up to today.
    // All odd rows get color.
    // Odd months get one color and even months get a different color.
    // All even rows are default color.
    return ix % 2 === 1 ? ymColor : null; // SUI doesn't accept 'white', but null is okay.
  }

  const contextRef = createRef();

  return (
    <Grid>
      <Grid.Column>
        <div ref={contextRef}>
          <Sticky context={contextRef} offset={135}>
            <Grid centered columns={16}>
              {/* The Card used in SpendingMonthHeaderView centers itself in its parent
                  so make the column as wide as possible to work on all screen sizes.
                 */}
              <Grid.Column width={16}>
                <SpendingMonthHeaderView
                  ym={ym}
                  monthName={monthName}
                  incomeActual={incomeActual}
                  expenseActual={expenseActual}
                />
              </Grid.Column>
            </Grid>
          </Sticky>
          <Divider horizontal />
          {month.sortedTxIds.map((txId, ix) => {
            return (
              <SpendingListItem
                ym={ym}
                txId={txId}
                tx={spendingTxs[txId]}
                key={txId}
                budgetTxsNearDate={budgetTxsNearDate}
                rowColor={getRowColor(spendingTxs[txId].date, ix)}
                onEditSplits={() => onEditSplits(ym, txId, budgetTxsNearDate)}
                onAssignBudgetTx={onAssignBudgetTx}
              />
            );
          })}
        </div>
      </Grid.Column>
    </Grid>
  );
}
