import {
  parseYyyyMm,
  toYyyyMm,
  nextYyyyMm,
  prevYyyyMm,
  parseBudgetTxIdToYyyyMm,
} from "../util/helpers";
import moment from "moment";
import { getMonthEndBalance } from "../components/Budget/Reducer/budgetHelpers";
import * as budgetdb from "./budgetdb";
import * as repeatdb from "./repeatdb";
import * as goalsdb from "./goalsdb";
import _ from "lodash";
import { deleteField, writeBatch, increment } from "firebase/firestore";
import { getFirestoreDb, isTestEnv } from "../app/configureFirebase";

export const MAX_BUDGET_YM = toYyyyMm(moment().add(18, "months").endOf("month").toDate());

export async function getOrCreateMonthWithStartBalance(
  accountId,
  ym,
  firstYmWithChanges,
  lastYmWithChanges,
  prevMonthEndBalance
) {
  let isDone = false;
  let isNewMonth = false;
  let isStartBalanceChanged = false;

  let curMonth = await budgetdb.getMonth(accountId, ym);
  if (!curMonth) {
    isNewMonth = true;
    // if it's the first month we've loaded and it doesn't exist in the
    // budget yet, we need to get the start balance for curMonth
    // (== prev month end balance).
    if (ym === firstYmWithChanges) {
      // If prevYm before SOB or this is the first month in the budget,
      // startBalance will be 0.
      const prevMonth = await budgetdb.getMonth(accountId, prevYyyyMm(firstYmWithChanges));
      if (prevMonth) {
        // If prevMonth doesn't exist, this will return 0, which is correct assuming
        // we don't have gap months in the budget before this month.
        prevMonthEndBalance = getMonthEndBalance(prevMonth);
      }
    }
    // Whether it's first or not, the month isn't in the DB so create it with
    // the right start balance.
    curMonth = {
      id: ym,
      txs: {},
      startBalance: prevMonthEndBalance,
    };
  } else if (ym > lastYmWithChanges && curMonth.startBalance === prevMonthEndBalance) {
    // If we're after the last changes to apply and the balance is correct, we
    // can stop here.
    // *** IMPORTANT *** Don't confuse lastYmWithChanges with EOB here!!!
    isDone = true;
  } else if (ym !== firstYmWithChanges) {
    // Update start balance for each month after the first one. We're not changing txs
    // before firstYmWithChanges so it's startBalance must still be correct.
    if (curMonth.startBalance !== prevMonthEndBalance) {
      curMonth.startBalance = prevMonthEndBalance;
      isStartBalanceChanged = true;
    }
  }

  return { curMonth, isNewMonth, isDone, isStartBalanceChanged };
}

// Probably best to stick to simpler cases:
// Don't create and delete months at the same time (even if they're different months).
// Creating full months is mutually exclusive with creating partial months (or updates).
// Create full months ONLY IMMEDIATELY AFTER last full month.
//
// Helper for applyAccountsMonthsTxsToDb (part of setup).
// Splitting this out makes the logic easier to read and we can test it separately.
// 1st param: accountsMonthsTxs[accountId] (with months)
// Returns: { firstYmToUpdate,  Could be before or after SOB. Undefined if no months.
//   lastYmToUpdate,    Could be before or after EOB.
// }
// NOTE: the default value of {} fixes the case where the parameter is undefined or
// null and doesn't have a months field: https://stackoverflow.com/a/48433029
export function getYmsToApply({ months: accountMonths } = {}) {
  if (!accountMonths) {
    return { firstYmToUpdate: undefined, lastYmToUpdate: undefined };
  }
  const ymsToApply = Object.keys(accountMonths);
  // From the first month we're going to change, to the last month we'll change.
  return {
    firstYmToUpdate: _.min(ymsToApply),
    lastYmToUpdate: _.max(ymsToApply),
  };
}

// See if we have any txs, return empty object if not.
export function getTxsToAdd(accountsMonthsTxs, accountId, ym) {
  return accountsMonthsTxs[accountId].months[ym] &&
    accountsMonthsTxs[accountId].months[ym].txsToAdd &&
    Object.keys(accountsMonthsTxs[accountId].months[ym].txsToAdd).length > 0
    ? accountsMonthsTxs[accountId].months[ym].txsToAdd
    : {};
}
// See if we have any tx ids, return empty array if not.
export function getTxIdsToDelete(accountsMonthsTxs, accountId, ym) {
  return accountsMonthsTxs[accountId].months[ym] &&
    Array.isArray(accountsMonthsTxs[accountId].months[ym].txIdsToDelete)
    ? accountsMonthsTxs[accountId].months[ym].txIdsToDelete
    : [];
}

// The firestore emulator doesn't update subobjects inside docs so we need to write the whole doc
// to the emulator, but this is more efficient for production.
export function mergeMonthTxUpdates(txIdsToDelete, txsToAdd) {
  // Delete first
  let newTxsUpdates = {};
  if (_.isArray(txIdsToDelete) && txIdsToDelete.length > 0) {
    newTxsUpdates = _.reduce(
      txIdsToDelete,
      (res, delTxId) => {
        // For firestore, the key needs to be dot-notation for the field
        // you want to delete.
        res[`txs.${delTxId}`] = deleteField();
        return res;
      },
      {}
    );
  }
  if (_.isObjectLike(txsToAdd) && !_.isEmpty(txsToAdd)) {
    newTxsUpdates = _.reduce(
      Object.entries(txsToAdd),
      (res, newTxEntry) => {
        // For a firestore update, the key needs to be dot-notation for the field
        // you want to update (set). We're adding txs to an existing txs collection,
        // but we don't want to completely replace the txs object, just add the new txs to it.
        // We will replace any duplicates, but that's okay.
        // Entry[0] is the tx ID, Entry[1] is the tx.
        res[`txs.${newTxEntry[0]}`] = newTxEntry[1];
        return res;
      },
      newTxsUpdates // start with the deletions and add to it
    );
  }
  return newTxsUpdates;
}

// accountsMonthsTxs = { accountId: {
//   months: {
//     ym: {
//       isFullMonth: bool,   // optional, defaults to undefined/false
//       txsToAdd: { id: {} },  // Either this or txIdsToDelete or BOTH
//       isDeleteMonth: bool,  // optional
//       txIdsToDelete: [ id ]  // optional
//     }
//   }
// } }
// Combine passed in monthsTxs with existing months in target account, or create
// partial months, or delete months.
// For update, either delete and add, or just add if ID didn't change (date+category).
// Update balances.
// Commit to the DB.
// Updates goal amount changes for deleted txs (done here so we know xfer tgt amounts)
export async function applyAccountsMonthsTxsToDb(accountsMonthsTxs, goalAmountChanges) {
  for (let accountId of Object.keys(accountsMonthsTxs || {})) {
    if (
      !accountsMonthsTxs[accountId].months ||
      Object.keys(accountsMonthsTxs[accountId].months).length === 0
    ) {
      // This is probably an error...but it's recoverable.
      continue;
    }

    // lastBudgetYm can be undefined for an empty budget. That's okay - we might
    // be adding txs now.
    const lastBudgetYm = await budgetdb.getLastYm(accountId);

    // Months with changes that need to be applied (passed in accountsMonthsTxs)
    let { firstYmToUpdate, lastYmToUpdate } = getYmsToApply(accountsMonthsTxs[accountId]);
    // We checked above, but double-check.
    if (!firstYmToUpdate || !lastYmToUpdate) {
      // This is probably an error...but it's recoverable.
      console.log("apply: first/last YmToUpdate are undefined, doing nothing (BUG)");
      continue;
    }

    const db = getFirestoreDb();
    const batch = writeBatch(db);

    let prevMonthEndBalance = 0;

    let ym = firstYmToUpdate;
    // Verified _.max does the right thing if lastBudgetYm is undefined (empty budget)
    let lastYm = _.max([lastYmToUpdate, lastBudgetYm]);
    while (ym <= lastYm) {
      let { curMonth, isNewMonth, isDone, isStartBalanceChanged } =
        await getOrCreateMonthWithStartBalance(
          accountId,
          ym,
          firstYmToUpdate, // This IS the first month with changes
          lastYmToUpdate,
          prevMonthEndBalance
        );
      if (isDone) {
        // No more txs to add or delete, and startBalance is correct so we don't
        // need to update startBalance on any more months.
        break;
      }

      const newTxsToAdd = getTxsToAdd(accountsMonthsTxs, accountId, ym);
      const newTxIdsToDelete = getTxIdsToDelete(accountsMonthsTxs, accountId, ym);

      // add the new txs if we had any for this month.
      if (isNewMonth) {
        if (newTxsToAdd) {
          // txIdsToDelete doesn't apply if it's a new month (it wasn't in the DB)
          curMonth.txs = newTxsToAdd;
          // || false in case it's undefined
          curMonth.isFullMonth = accountsMonthsTxs[accountId].months[ym]?.isFullMonth || false;
          batch.set(budgetdb.getMonthRef(accountId, ym), curMonth);
        }
        // else don't add an empty month. Just move on.
      } else if (
        // isDeleteMonth is a flag for the UI when the user clicks the Delete Month button.
        accountsMonthsTxs[accountId].months[ym]?.isDeleteMonth ||
        // Or maybe the user is deleting the last txs in the month (and not adding/updating).
        (_.isEmpty(newTxsToAdd) &&
          JSON.stringify(newTxIdsToDelete.sort()) ===
            JSON.stringify(Object.keys(curMonth.txs).sort()))
      ) {
        // get goal amount updates for all txs we're deleting.
        Object.values(curMonth.txs).forEach((delTx) => {
          if (delTx.goalId) {
            if (!(delTx.goalId in goalAmountChanges)) {
              goalAmountChanges[delTx.goalId] = { budgetedChange: 0, actualChange: 0 };
            }
            goalAmountChanges[delTx.goalId].budgetedChange -= delTx.amount;
            goalAmountChanges[delTx.goalId].actualChange -= delTx.actualAmount;
          }
        });

        // Update curMonth so we can get the next start balance below.
        curMonth.txs = {};
        batch.delete(budgetdb.getMonthRef(accountId, ym));
      } else if (!_.isEmpty(newTxsToAdd) || newTxIdsToDelete.length > 0) {
        // Adding some, deleting some, NOT deleting the whole month.

        // Get goal amount updates for all txs we're deleting.
        newTxIdsToDelete.forEach((delTxId) => {
          const delTx = curMonth.txs[delTxId];
          if (delTx?.goalId) {
            if (!(delTx.goalId in goalAmountChanges)) {
              goalAmountChanges[delTx.goalId] = { budgetedChange: 0, actualChange: 0 };
            }
            goalAmountChanges[delTx.goalId].budgetedChange -= delTx.amount;
            goalAmountChanges[delTx.goalId].actualChange -= delTx.actualAmount;
          }
        });

        // Update curMonth so we can get the next start balance below.
        newTxIdsToDelete.forEach((txId) => delete curMonth.txs[txId]);
        Object.entries(newTxsToAdd).forEach((entry) => (curMonth.txs[entry[0]] = entry[1]));
        // The month was full before (keep it full), or it's full now, or false/undefined = false: still partial.
        curMonth.isFullMonth =
          curMonth.isFullMonth || accountsMonthsTxs[accountId].months[ym].isFullMonth || false;

        // If it's production, just send updates. The emulator on localhost doesn't support updating objects inside documents.
        if (!isTestEnv()) {
          // Get the updates:
          // 'txs.date_category': { tx fields }, 'txs.date_category2': { tx2 fields }...
          const newTxsUpdates = mergeMonthTxUpdates(newTxIdsToDelete, newTxsToAdd);
          batch.update(budgetdb.getMonthRef(accountId, ym), {
            startBalance: curMonth.startBalance,
            // The month was full before (keep it full), or it's full now, or false/undefined = false: still partial.
            isFullMonth: curMonth.isFullMonth,
            ...newTxsUpdates,
          });
        } else {
          // The firestore emulator doesn't update subobjects in docs so we have to write the whole month.
          batch.set(budgetdb.getMonthRef(accountId, ym), curMonth);
        }
      } else {
        // No txs to add/update? Just update this month's startBalance (if necessary).
        if (isStartBalanceChanged) {
          batch.update(budgetdb.getMonthRef(accountId, ym), {
            startBalance: curMonth.startBalance,
          });
        }
      }

      // calc next start balance
      prevMonthEndBalance = getMonthEndBalance(curMonth);
      ym = nextYyyyMm(ym);
    }

    await batch.commit();
  }
}

// repeatTx is a template, but frequency can be 0 and timeUnits can be '' for 1-time txs.
// Call this function to create all the transfer target txs too.
// Returns number of repeatTx inserted and newAccountsMonthsTxs is modified.
export function createTxsFromRepeatTx(
  newAccountsMonthsTxs,
  accountId,
  { startDate, frequency, timeUnits, category, isTransfer, amount, isDone, goalId, isSavings }, // repeatTx
  fromDate,
  toDate
) {
  if (!newAccountsMonthsTxs || !startDate || !fromDate || !toDate) {
    throw new Error("Error creating repeating items. Missing info.");
  }
  let nextDate = startDate;
  let newTxCount = 0;
  // Find the first nextDate in ymToCreate.
  // After a long time (years), this might slow down a little, but the advantage
  // is that it works even if the user deletes a whole month and wants to create
  // it again. Keeping context of each repeat tx's last date is more headache.
  while (frequency > 0 && timeUnits && moment(nextDate).isBefore(fromDate, "day")) {
    nextDate = moment(nextDate).add(frequency, timeUnits).toDate();
  }
  // Create all instances of rep in ymToCreate
  while (moment(nextDate).isBetween(fromDate, toDate, "day", "[]")) {
    const curYm = toYyyyMm(nextDate);
    if (!newAccountsMonthsTxs[accountId]) {
      newAccountsMonthsTxs[accountId] = { months: {} };
    }
    if (!newAccountsMonthsTxs[accountId].months[curYm]) {
      // This isn't a full month - it's just the txs we'll add to a month, so we don't need
      // id or startBalance here.
      newAccountsMonthsTxs[accountId].months[curYm] = { txsToAdd: {} };
    }

    ++newTxCount;
    newAccountsMonthsTxs[accountId].months[curYm].txsToAdd[budgetdb.makeId(nextDate, category)] = {
      date: nextDate,
      category,
      isTransfer,
      amount,
      isDone: isDone || false,
      isSavings: isSavings || false,
      isBalanceOverride: false,
      notes: "",
      goalId: goalId || null,
    };
    // Only the first tx can be done if the user marked it done when they added it
    isDone = false;

    if (isTransfer) {
      // rep.category is the target account id.
      if (!newAccountsMonthsTxs[category]) {
        newAccountsMonthsTxs[category] = { months: {} };
      }
      if (!newAccountsMonthsTxs[category].months[curYm]) {
        newAccountsMonthsTxs[category].months[curYm] = { txsToAdd: {} };
      }
      newAccountsMonthsTxs[category].months[curYm].txsToAdd[budgetdb.makeId(nextDate, accountId)] =
        {
          date: nextDate,
          category: accountId, // from the target account's perspective, category is the source account id
          isTransfer: true,
          amount: -amount,
          isDone: false,
          isSavings: false,
          isBalanceOverride: false,
          notes: "",
          goalId: null, // Goal only applies to the source account so we don't cancel it out by adding it twice.
        };
    }

    // Is it a 1-time tx?
    if (0 === frequency || !timeUnits) {
      break;
    }
    nextDate = moment(nextDate).add(frequency, timeUnits).toDate();
  }
  // Number of txs we added to source account.
  return newTxCount;
}

// repeatTxs: [ { category, amount, startDate, goalId, ... } ]
// Returns goalAmountChanges: { goalId: { budgetedChanged, actualChanged } }. Modifies newAccountsMonthsTxs.
export function createMonthsFromRepeatTxs(
  newAccountsMonthsTxs,
  accountId,
  repeatTxs,
  fromDate,
  toDate
) {
  if (!accountId || !repeatTxs) {
    throw new Error("Error creating new month. Missing info.");
  }
  let goalAmountChanges = {};

  // Each rep can make multiple txs (repeats multiple times in a month)
  repeatTxs.forEach((repeatTx) => {
    const newTxCount = createTxsFromRepeatTx(
      newAccountsMonthsTxs,
      accountId,
      repeatTx,
      fromDate,
      toDate
    );
    if (repeatTx.goalId) {
      if (!(repeatTx.goalId in goalAmountChanges)) {
        goalAmountChanges[repeatTx.goalId] = { budgetedChange: 0, actualChange: 0 };
      }
      goalAmountChanges[repeatTx.goalId].budgetedChange += newTxCount * repeatTx.amount;
      // New txs don't have actual amounts yet.
    }
  });

  Object.values(newAccountsMonthsTxs[accountId].months).forEach(
    (month) => (month.isFullMonth = true)
  );

  return goalAmountChanges;
}

// TODO: Instead of read-only permissions, add a button to copy months to another account. Users can make a copy account that others can edit. Users can also make multiple test accounts, starting with copies of each other. Transfers would be cut (change category name and set isTransfer=false).

// Creates the month AFTER lastBudgetFullYm.
// Pass in lastBudgetFullYm because we might have it in the store (cache) so that
// avoids another DB read. Same for selAccountRepeatTxs.
export async function createNewMonth(selAccountId, options) {
  if (!selAccountId) {
    // Hacking, error, no accounts, ?
    return;
  }
  const lastBudgetFullYm =
    options["lastBudgetFullYm"] || (await budgetdb.getLastFullYm(selAccountId));
  if (lastBudgetFullYm >= MAX_BUDGET_YM) {
    throw new Error("You've reached into the future as far as possible right now.");
  }

  const selAccountRepeatTxs =
    options["selAccountRepeatTxs"] || (await repeatdb.getRepeatTxs(selAccountId));
  if (!selAccountRepeatTxs || selAccountRepeatTxs.length === 0) {
    // No repeating txs?
    return;
  }

  // If the budget is empty or no full months, create THIS month (today)
  const today = new Date();
  const fromDate = !lastBudgetFullYm
    ? moment(today).startOf("month").toDate()
    : moment(lastBudgetFullYm, "YYYYMM").add(1, "month").startOf("month").toDate();

  const toDate = !lastBudgetFullYm
    ? moment(today).endOf("month").toDate()
    : moment(lastBudgetFullYm, "YYYYMM").add(1, "month").endOf("month").toDate();

  let newAccountsMonthsTxs = {};
  const goalAmountChanges = createMonthsFromRepeatTxs(
    newAccountsMonthsTxs,
    selAccountId,
    selAccountRepeatTxs,
    fromDate,
    toDate
  );

  await applyAccountsMonthsTxsToDb(newAccountsMonthsTxs, goalAmountChanges);

  await goalsdb.applyGoalAmountChanges(goalAmountChanges);
}

export function addTxToMonth(newAccountsMonthsTxs, goalAmountChanges, accountId, newTx) {
  if (!accountId || !newTx) {
    throw new Error("Error adding new budget item. Missing info.");
  }
  const newYm = toYyyyMm(newTx.date);

  if (!newAccountsMonthsTxs[accountId].months[newYm]) {
    newAccountsMonthsTxs[accountId].months[newYm] = { txsToAdd: {} };
  }

  const newTxId = budgetdb.makeId(newTx.date, newTx.category);
  // Must update the goal amounts before we add (or importantly, replace) the tx in newAccountsMonthsTxs.
  // If this tx has already been added (via create new month), don't update the goal twice:
  // remove the original amount and add the new amount. Also double-check that the first
  // instance has a goalId (the repeatTx may be different from the updated/new tx).
  // Effectively, we need to remove the repeat tx and add the new one.
  const prevTx = newAccountsMonthsTxs[accountId].months[newYm].txsToAdd[newTxId];
  if (prevTx?.goalId) {
    if (!(prevTx.goalId in goalAmountChanges)) {
      goalAmountChanges[prevTx.goalId] = { budgetedChange: 0, actualChange: 0 };
    }
    goalAmountChanges[prevTx.goalId].budgetedChange -= prevTx.amount;
    goalAmountChanges[prevTx.goalId].actualChange -= prevTx.actualAmount;
  }
  if (newTx.goalId) {
    if (!(newTx.goalId in goalAmountChanges)) {
      goalAmountChanges[newTx.goalId] = { budgetedChange: 0, actualChange: 0 };
    }
    goalAmountChanges[newTx.goalId].budgetedChange += newTx.amount;
    goalAmountChanges[newTx.goalId].actualChange += newTx.actualAmount;
  }

  newAccountsMonthsTxs[accountId].months[newYm].txsToAdd[newTxId] = {
    ...newTx,
  };

  if (newTx.isTransfer) {
    if (!newAccountsMonthsTxs[newTx.category]) {
      newAccountsMonthsTxs[newTx.category] = { months: {} };
    }
    if (!newAccountsMonthsTxs[newTx.category].months[newYm]) {
      newAccountsMonthsTxs[newTx.category].months[newYm] = { txsToAdd: {} };
    }
    newAccountsMonthsTxs[newTx.category].months[newYm].txsToAdd[
      budgetdb.makeId(newTx.date, accountId)
    ] = {
      ...newTx,
      category: accountId, // from the target account's perspective, category is the source account id
      amount: -newTx.amount,
      isDone: newTx.isDone ?? false, // isDone should be the same in both accounts since it's a transfer; assume it completes in both accounts at the same time
      isSavings: newTx.isSavings ?? false,
      isBalanceOverride: false,
      notes: "",
      goalId: null, // Goal only applies to the source account so we don't cancel it out by adding it twice.
    };
  }
}

// frequency, timeUnits can be 0, '' for a 1-time tx.
// If tx is a transfer, category is the target account id.
// TODO: Unit test this. (Create non-repeating tx past EOB)
export async function createBudgetTx(accountId, tx, frequency, timeUnits) {
  if (!accountId || !tx ||
      !("date" in tx) ||
      !("category" in tx) ||
      !("amount" in tx) ||
      !("isTransfer" in tx) ||
      !("isBalanceOverride" in tx) ||
      !("overrideBalance" in tx) ||
      !("notes" in tx)) {
    console.log("Error creating new budget item. Missing info.");
    throw new Error("Error creating new budget item. Missing info.");
  }
  // Add it to repeat first in case we're creating new month(s)
  if (frequency > 0 && timeUnits) {
    // add to repeating txs
    await repeatdb.createRepeatTx(accountId, {
      category: tx.category,
      isTransfer: tx.isTransfer,
      amount: tx.amount,
      goalId: tx.goalId || null, // Firestore doesn't like undefined so make it null instead.
      frequency: frequency,
      timeUnits: timeUnits,
      startDate: tx.date,
    });
  }

  // If new month (or partial month), create new full month(s) from last up to tx.date in the source account.
  // Add transfers to the target accounts.
  // Do not create new months in transfer target accounts because recursively finding
  // transfer dependencies while creating those months is too hard.
  // NOTE: Can't create new month in the past.
  const upToYm = toYyyyMm(tx.date);
  let newAccountsMonthsTxs = { [accountId]: { months: {} } };
  let goalAmountChanges = {};

  const lastFullYm = await budgetdb.getLastFullYm(accountId);

  if (!lastFullYm || upToYm > lastFullYm) {
    // Make new full months until we can add the new tx.
    const repeatTxs = await repeatdb.getRepeatTxs(accountId);
    // "Start of month" accounts for the first tx in the budget, because lastFullYm could be null.
    const fromDate =
      parseYyyyMm(nextYyyyMm(lastFullYm)) || moment(tx.date, "YYYYMM").startOf("month").toDate();
    const toDate = moment(tx.date, "YYYYMM").endOf("month").toDate();

    goalAmountChanges = createMonthsFromRepeatTxs(
      newAccountsMonthsTxs,
      accountId,
      repeatTxs,
      fromDate,
      toDate
    );

    // If it wasn't a repeat tx, add it to the new month; add it to goalAmountChanges.
    if (frequency === 0 || !timeUnits) {
      addTxToMonth(newAccountsMonthsTxs, goalAmountChanges, accountId, tx);
      // In case this is the very first tx in the budget, mark the month as full
      // because we don't need to do Create New Month for the first month. Otherwise,
      // when the user clicks Create New Month, it will do nothing but mark the
      // first month full so it'll look broken.
      if (!lastFullYm) {
        newAccountsMonthsTxs[accountId].months[upToYm].isFullMonth = true;
      }
    }
  } else {
    // We have enough full months for the new tx so make all instances of it in the current
    // budget months.
    const fromDate = tx.date;
    const toDate = moment(lastFullYm, "YYYYMM").endOf("month").toDate();
    const repeatTx = {
      ...tx,
      frequency,
      timeUnits,
      startDate: tx.date,
    };
    // This works even if frequency is 0 or timeUnits is empty (a 1-time tx).
    const newTxCount = createTxsFromRepeatTx(
      newAccountsMonthsTxs,
      accountId,
      repeatTx,
      fromDate,
      toDate
    );
    if (tx.goalId) {
      goalAmountChanges[tx.goalId] = { budgetedChange: newTxCount * tx.amount, actualChange: 0 };
    }

    // Set 1-time tx values: (only the first instance of a repeating tx can be a balance override or have notes)
    const firstTxId = budgetdb.makeId(tx.date, tx.category);
    // upToYm is the YM for tx.date
    newAccountsMonthsTxs[accountId].months[upToYm].txsToAdd[firstTxId].isBalanceOverride =
      tx.isBalanceOverride;
    newAccountsMonthsTxs[accountId].months[upToYm].txsToAdd[firstTxId].overrideBalance =
      tx.overrideBalance;
    newAccountsMonthsTxs[accountId].months[upToYm].txsToAdd[firstTxId].notes = tx.notes;
  }

  // Commit changed months to the DB
  await applyAccountsMonthsTxsToDb(newAccountsMonthsTxs, goalAmountChanges);

  // If goalId, update goal amounts:
  await goalsdb.applyGoalAmountChanges(goalAmountChanges);
}

export async function updateBudgetTx(accountId, oldTx, newTx) {
  // Delete doesn't require newTx, but update and delete require oldTx.
  if (!accountId || !oldTx) {
    throw new Error("Error updating a budget tx. Missing info.");
  }

  const newAccountsMonthsTxs = { [accountId]: { months: {} } };
  let goalAmountChanges = {};

  const oldYm = toYyyyMm(oldTx.date);
  // delete from src
  // applyAccountsMonthsTxs will give us goal updates for deleted txs because
  // it loads the month so it knows the deleted tx amount.
  newAccountsMonthsTxs[accountId].months[oldYm] = {
    txIdsToDelete: [budgetdb.makeId(oldTx.date, oldTx.category)],
    txsToAdd: {},
  };
  if (oldTx.isTransfer) {
    newAccountsMonthsTxs[oldTx.category] = {
      months: {
        [oldYm]: {
          // In the target account, the category for the id is the source account id.
          txIdsToDelete: [budgetdb.makeId(oldTx.date, accountId)],
          txsToAdd: {},
        },
      },
    };
  }

  if (newTx) {
    const upToYm = toYyyyMm(newTx.date);

    const lastFullYm = await budgetdb.getLastFullYm(accountId);

    if (!lastFullYm || upToYm > lastFullYm) {
      const repeatTxs = await repeatdb.getRepeatTxs(accountId);
      const fromDate = parseYyyyMm(nextYyyyMm(lastFullYm));
      const toDate = moment(newTx.date, "YYYYMM").endOf("month").toDate();

      goalAmountChanges = createMonthsFromRepeatTxs(
        newAccountsMonthsTxs,
        accountId,
        repeatTxs,
        fromDate,
        toDate
      );
    }

    addTxToMonth(newAccountsMonthsTxs, goalAmountChanges, accountId, newTx);
  }

  // Commit changed months to the DB. It updates goalAmountChanges with changes from
  // deleted txs and their xfer targets.
  await applyAccountsMonthsTxsToDb(newAccountsMonthsTxs, goalAmountChanges);

  // If goalId, update goal amounts:
  await goalsdb.applyGoalAmountChanges(goalAmountChanges);
}

// We need tx.date, category, and isTransfer.
// applyAccountsMonthsTxs will update any goal amount because it has to read
// the month before deleting the tx so it gets goalId from the DB.
export async function deleteBudgetTx(accountId, tx) {
  if (!accountId || !tx) {
    throw new Error("Error deleting a budget item. Missing info.");
  }
  await updateBudgetTx(accountId, tx, null);
}

// TODO: UI and function for deleting month(s) at a time for convenience.

export function getBudgetMonthActualAmountUpdates(budgetTxsAmountChanges) {
  const budgetMonthUpdates = {};
  Object.keys(budgetTxsAmountChanges || {}).forEach((budgetTxId) => {
    const ym = parseBudgetTxIdToYyyyMm(budgetTxId);
    if (ym) {
      if (!(ym in budgetMonthUpdates)) {
        budgetMonthUpdates[ym] = {};
      }
      budgetMonthUpdates[ym][`txs.${budgetTxId}.actualAmount`] =
        increment(budgetTxsAmountChanges[budgetTxId]);
    }
  });
  return budgetMonthUpdates;
}

// actualChange means actual amount from a spending tx (imported from their bank account).
// actualChange can be part of a spending tx and can be positive or negative.
// budgetTxsAmountChanges: { budgetTxId: actualChange }
export async function applyBudgetTxsActualAmountChanges(accountId, budgetTxsAmountChanges) {
  if (!accountId) {
    throw new Error("Error assigning actual txs to the budget. Missing info.");
  }
  const db = getFirestoreDb();
  const batch = writeBatch(db);

  // Make an update object for each budget month. The update object will have updates
  // for each budget tx we're changing (adding or subtracting the actual amount).
  // { ym: { budgetTxId: firestore.increment(amount) } }
  const budgetMonthUpdates = getBudgetMonthActualAmountUpdates(budgetTxsAmountChanges);
  Object.keys(budgetMonthUpdates).forEach((ym) => {
    batch.update(budgetdb.getMonthRef(accountId, ym), budgetMonthUpdates[ym]);
  });

  await batch.commit();
}
