import React from "react";
import { format } from "date-fns";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";

const ACTUAL_AMOUNT_TEXT_COLOR = "#5060A0";
const NOTES_TEXT_COLOR = "#5060A0";
const GOAL_NAME_TEXT_COLOR = "#5060A0";
const OVER_BUDGET_COLOR = "red";
const BALANCE_OVERRIDE_COLOR = "blue";
const BUDGET_INCOME_COLOR = "blue";

// 2-lines, narrower, for mobile devices
// color is background (alternating row/month colors)
export default function BudgetListItemNoDrag({
  txId,
  tx,
  goalName,
  rowColor,
  onClick,
}) {
  const myAccounts = useSelector((state) => state.accounts.accounts);
  const { closestTxIdToToday } = useSelector((state) => state.budgetui);
  const notesWidth = 16 - 2 - (tx.actualAmount ? 3 : 0) - 3;
  // For transfers, category is the target account id so we need to look
  // up the target account name.
  const tgtAccount = myAccounts.find((accountF) => accountF.id === tx.category);
  // The user might not own the target account (maybe someone
  // stopped sharing it) - just say "Transfer" in that case.
  const category = tx.isTransfer ? (tgtAccount ? tgtAccount.name : "Transfer") : tx.category;

  const amountColor = tx.amount > 0 ? BUDGET_INCOME_COLOR : "black";
  const actualAmountColor =
    tx.actualAmount && tx.actualAmount < tx.amount ? OVER_BUDGET_COLOR : ACTUAL_AMOUNT_TEXT_COLOR;
  const balanceColor =
    tx.balance < 0 ? OVER_BUDGET_COLOR : tx.isBalanceOverride ? BALANCE_OVERRIDE_COLOR : "black";

  // react-scroll uses Element to scroll to this specific item.
  const dateWithNameForScrolling =
    txId !== closestTxIdToToday ? (
      format(tx.date, "MMM d")
    ) : (
      <Element name={txId}>{format(tx.date, "MMM d")}</Element>
    );

  return (
    <Grid onClick={onClick} className='compact edititem'>
      <Grid.Row className={rowColor}>
        <Grid.Column width={2}>{dateWithNameForScrolling}</Grid.Column>
        <Grid.Column width={8}>{category}</Grid.Column>
        <Grid.Column width={3} textAlign='right'>
          <span style={{ color: amountColor }}>
            {(tx.amount > 0 ? "+" : "") + tx.amount.toFixed(2)}
            {tx.isDone && "  ✔️"}
            {tx.isSavings && "  $"}
          </span>
        </Grid.Column>
        <Grid.Column width={3} textAlign='right'>
          <span style={{ color: balanceColor }}>{`= ${tx.balance.toFixed(2)}`}</span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={rowColor}>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={notesWidth}>
          <span style={{ color: NOTES_TEXT_COLOR }}>{tx.notes}</span>
        </Grid.Column>
        {tx.actualAmount ? (
          <Grid.Column width={3} textAlign='right'>
            <span style={{ color: actualAmountColor }}>{`/ ${tx.actualAmount.toFixed(2)}`}</span>
          </Grid.Column>
        ) : (
          ""
        )}
        <Grid.Column width={3} textAlign='center'>
          {tx.goalId && goalName ? (
            <span style={{ color: GOAL_NAME_TEXT_COLOR }}>{goalName}</span>
          ) : (
            ""
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
