import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container, Divider, Message, Ref } from "semantic-ui-react";
import { Media } from "../../../../util/responsive";
import SharedOwnersList from "../../../SharedOwners/SharedOwnersList"
import useSelectedGoal from "../../../../hooks/useSelectedGoal";
import { addUserToGoalThunk, removeUserFromGoalThunk } from "../../Reducer/goalsThunks";
import { asyncClearError } from "../../../Async/Reducer/asyncActions";
import AppPageMenu from "../../../UI/Menus/AppPageMenu";
import { toast } from "react-toastify";
import * as usersdb from "../../../../sbdb.client/usersdb";
import AddSharedOwnerForm from "../../../SharedOwners/AddSharedOwnerForm";
import _ from "lodash";
import PageFooter from "../../../UI/PageFooter/PageFooter";

export default function GoalSharingPage({match}) {
  const goalId = match.params.id;
  const dispatch = useDispatch();

  const { selectedGoal } = useSelector((state) => state.goals);
  const user = useSelector((state) => state.auth.currentUser);
  const { loading: asyncLoading, error: asyncError } = useSelector((state) => state.async);
  const [ loadingPage, setLoadingPage ] = useState(true);
  const navigate = useNavigate();

  useSelectedGoal(goalId);

  const loading = asyncLoading || loadingPage;

  useEffect(function () {
    setLoadingPage(true);
    if (asyncError) {
      // Recover by going back to the goals page.
      dispatch(asyncClearError());
      navigate('/app/goals');
    }

    if (!loading && !selectedGoal) {
      // Maybe the user typed in the wrong goal ID into the URL (or an old goal, or a hacking attempt).
      navigate('/app/goals');
      return;
    }

    setLoadingPage(false);
  }, [dispatch, navigate, asyncError, loading, selectedGoal]);

  if (!goalId) {
    // Maybe the user typed in the wrong goal ID into the URL (or an old goal, or a hacking attempt).
    navigate('/app/goals');
    return;
  }

  // Users can only remove themselves if the goal has other owners. Otherwise the user
  // needs to delete the goal.
  const owners =
    selectedGoal && selectedGoal.owners && selectedGoal.owners.length > 1
      ? selectedGoal.owners
      : [];


  async function handleAddOwnerClicked(newOwnerEmail) {
    try {
      const newOwner = await usersdb.getUserByEmail(newOwnerEmail);
      // console.log('newOwner', newOwner);
      if (!newOwner) {
        return;
      }

      // Add the new owner
      await dispatch(
        addUserToGoalThunk(newOwner.id, newOwnerEmail, newOwner.displayName, goalId)
      );
    } catch (error) {
      toast.warning("User not found.");
    }
  }

  async function handleDeleteOwnerClicked(uid) {
    console.log(`Removing ${uid} from ${goalId}`);
    await dispatch(removeUserFromGoalThunk(uid, goalId));

    // If user removed themself, go back to the goals page.
    if (uid === user.uid) {
      navigate("/app/goals");
    }
  }

  const contextRef = createRef();

  return (
    <Ref innerRef={contextRef}>
      <div>
      <AppPageMenu
          loading={loading}
          ref={contextRef}
          title={selectedGoal?.name ?? "Looking for Goal..."}
          subtitle='Goal Sharing'
        />
        <div style={{ marginTop: "75px" }}>
          <Button basic onClick={() => navigate(`/app/goals/${goalId}`)}>
            &lt; Goal Details
          </Button>
        </div>
        <div style={{ paddingTop: "35px" }}>
          <Container text>
            {owners && owners.length > 1 ? (
              <SharedOwnersList
                as={Media}
                at='mobile'
                loading={loading}
                sharedOwners={owners}
                sharedItemName={selectedGoal?.name ?? ""}
                onDeleteOwnerClicked={async (uid) => await handleDeleteOwnerClicked(uid)}
              />
            ) : (
              <>
                <Divider horizontal hidden />
                <Message>
                  <Message.Header>Not Shared</Message.Header>
                  <p>Share your goals with another user by entering their email address below.</p>
                </Message>
              </>
            )}
            <div style={{ marginTop: "50px" }}>
              <AddSharedOwnerForm
                loading={loading}
                currentOwnersEmails={_.map(selectedGoal.owners, "email")}
                onAddOwnerClicked={handleAddOwnerClicked}
              />
            </div>
          </Container>
        </div>
        <PageFooter />
      </div>
    </Ref>
  );
}
