import {
  SPENDING_ASYNC_START,
  SPENDING_ASYNC_FINISH,
  SPENDING_ASYNC_ERROR,
  CLEAR_SPENDING,
  CLEAR_SPENDING_TXS,
  SPENDING_MONTHS_LOADED,
} from "./spendingConstants";

export function spendingAsyncStart() {
  return {
    type: SPENDING_ASYNC_START,
  };
}

export function spendingAsyncFinish() {
  return {
    type: SPENDING_ASYNC_FINISH,
  };
}

export function spendingAsyncError(error) {
  console.log('error', error);
  return {
    type: SPENDING_ASYNC_ERROR,
    payload: {
      error
    }
  };
}

export function listenToSpendingMonthsAction(months) {
  return {
    type: SPENDING_MONTHS_LOADED,
    payload: {
      months
    }
  }
}


export function clearSpendingAction() {
  return {
    type: CLEAR_SPENDING,
  }
}

export function clearSpendingTxsAction() {
  return {
    type: CLEAR_SPENDING_TXS,
    payload: {},
  };
}
