import useFirestoreDoc from "./useFirestoreDoc";
import { listenToSelectedGoalAction } from "../components/Goals/Reducer/goalsActions";
import { useDispatch } from "react-redux";
import * as goalsdb from "../sbdb.client/goalsdb";

export default function useSelectedGoal(goalId) {
  const dispatch = useDispatch();

  useFirestoreDoc({
    // goal with owners: { uid: { displayName, email } }
    query: () => goalsdb.getGoalRef(goalId),
    data: (goalInfoDoc) => {
      dispatch(listenToSelectedGoalAction(goalInfoDoc));
    },
    deps: [dispatch, goalId],
  })
};
