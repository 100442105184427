import React from 'react'

function PageFooter () {
  return (
    <div style={{ marginTop: "50px", paddingBottom: "20px", width: "100%", textAlign: "center" }}>
      Copyright SITW Productions, LLC &copy; 2021.&nbsp;&nbsp;
      <a href="/Legal/Terms.html">Terms &amp; Conditions</a> |&nbsp;
      <a href="/Legal/Cookies.html">Cookie Policy</a> | &nbsp;
      <a href="/Legal/Privacy.html">Privacy Policy</a> | &nbsp;
      <a href="mailto:web-support@sarahsbudget.com">Contact Us</a>
    </div>
  )
}

export default PageFooter
