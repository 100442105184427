import React from "react";
import { Menu, Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { openModal } from "../Modals/Reducer/modalActions";

export default function SignedOutMenu({loading}) {
  const dispatch = useDispatch();
  return (
    <Menu.Item position='right'>
      <Button
        onClick={() => dispatch(openModal("LoginModal"))}
        primary
        content='Login or Register'
        loading={loading}
        disabled={loading}
      />
    </Menu.Item>
  );
}
