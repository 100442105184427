import React from "react";
import { format } from "date-fns";
import { Grid, Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { getSortedAccountsSelector } from "../../../Accounts/Selector/accountsSelectors";
import { getSortedShownGoalsSelector } from "../../../Goals/Selector/goalsSelectors";

// TODO: 2 or 3 lines, narrower, for mobile devices
export default function RepeatTxsListItem({
  isHeader,
  repeatTx: { category, isTransfer, startDate, amount, frequency, timeUnits, goalId } = {},
  onClick,
}) {
  const accounts = useSelector(getSortedAccountsSelector);
  const goals = useSelector(getSortedShownGoalsSelector);

  if (isHeader) {
    // If you change column widths, do it here and below.
    return (
      <Grid onClick={onClick} style={{ backgroundColor: "white", marginTop: '-20px' }}>
        <Grid.Row>
          <Grid.Column width={2}>{"Start Date"}</Grid.Column>
          <Grid.Column width={5}>{"Category"}</Grid.Column>
          <Grid.Column width={3} textAlign='right'>
            {"Amount"}
          </Grid.Column>
          <Grid.Column width={3} textAlign='right'>
            {"Frequency "}<Icon name="sort down" />
          </Grid.Column>
          <Grid.Column width={3}>{"Goal Name"}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  // For transfers, category is the target account id so we need to look
  // up the target account name.
  if (isTransfer) {
    const tgtAccount = accounts.find((accountF) => accountF.id === category);
    // The user might not own the target account (maybe someone
    // stopped sharing it) - just say "Transfer" in that case.
    category = tgtAccount?.name || "** Transfer **";
  }

  const goalName = goalId && (goalId in goals) ? goals[goalId].name : "";

  return (
    <Grid onClick={onClick} className='medspace edititem'>
      <Grid.Row>
        <Grid.Column width={2}>{format(startDate, "MMM d")}</Grid.Column>
        <Grid.Column width={5}>{category}</Grid.Column>
        <Grid.Column width={3} textAlign='right'>
          {amount.toFixed(2)}
        </Grid.Column>
        <Grid.Column width={3} textAlign='right'>
          {frequency} {timeUnits}
        </Grid.Column>
        <Grid.Column width={3}>{goalName}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
