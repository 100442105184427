import React from "react";
import { Menu, Popup, Icon, Checkbox, Sticky } from "semantic-ui-react";
import { BudgetFeaturesMenu } from "./BudgetFeaturesMenu";
import AccountListMenu from "./AccountListMenu";
import { Link } from "react-router-dom";
import { getSortedAccountsSelector } from "../../Accounts/Selector/accountsSelectors";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const AppPageMenu = React.forwardRef(
  (
    {
      title,
      titleLink = null,
      loading = false,
      subtitle = "",
      showRepeatingTxsItem = false,
      selectedAccountId = "",
      activePageName = "",
      showScrollToToday = false,
      onScrollToToday = null,
      showAccountsMenu = false,
      onAccountSelected = null,
      showHiddenGoalsButton = false,
      showHiddenGoalsChecked = false,
      onShowHiddenGoalsClicked = null,
      showAddButton = false,
      addButtonText = "",
      onAddButtonClicked = null,
    },
    ref
  ) => {
    const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
    const sortedAccountsSel = useSelector(getSortedAccountsSelector);
    // TODO: Disable menu items if loading, if necessary (or return a dummy menu with fake buttons).
    const titleStr =
      loading || !title ? "Loading..." : isMobile && showAccountsMenu ? title.slice(0, 14) : title;
    const titleItem = titleLink ? <Link to={titleLink}>{titleStr}</Link> : titleStr;
    const sortedAccounts = showAccountsMenu ? sortedAccountsSel : [];

    return (
      <Sticky
        context={ref}
        offset={60}
        attached='top'
        className='bdpgmenu'
        style={{ backgroundColor: "white" }}>
        <div className='ui centered'>
          <Menu
            fluid
            pointing
            secondary
            style={{
              backgroundColor: "white",
              minHeight: "54px",
              paddingTop: "10px",
            }}>
            <Menu.Menu position='left'>
              {/* TODO: Replace this with a Dropdown instead of Popup */}
              <Popup
                trigger={
                  <Menu.Item>
                    <Icon name='content' />
                  </Menu.Item>
                }
                on='click'
                position='bottom left'>
                <BudgetFeaturesMenu
                  activeItem={activePageName}
                  showRepeatTxsItem={showRepeatingTxsItem}
                  showTxsItem={false}
                  accountId={selectedAccountId}
                />
              </Popup>
              {showScrollToToday && (
                <Menu.Item onClick={onScrollToToday}>
                  <Icon name='bullseye' /> {!isMobile && "Today"}
                </Menu.Item>
              )}
              {showHiddenGoalsButton && (
                <Menu.Item>
                  <Checkbox
                    label='Show hidden goals'
                    checked={showHiddenGoalsChecked}
                    onChange={(event, data) => onShowHiddenGoalsClicked(data.checked)}
                  />
                </Menu.Item>
              )}
            </Menu.Menu>
            <Menu.Menu>
              <Menu.Item header>
                <div>
                  {titleItem}
                  {!loading && subtitle && (
                    <span style={{ paddingLeft: "25px", color: "#999" }}>{subtitle}</span>
                  )}
                </div>
              </Menu.Item>
              {showAccountsMenu && (
                <AccountListMenu
                  sortedAccounts={sortedAccounts}
                  selectedAccountId={selectedAccountId}
                  onSelectAccount={onAccountSelected}
                />
              )}
            </Menu.Menu>
            <Menu.Menu position='right'>
              {/* Keep the right menu even if we don't show the add button because it centers
                the title. */}
              {showAddButton ? (
                <Menu.Item onClick={onAddButtonClicked}>
                  <Icon name='plus' /> {!isMobile && addButtonText}
                </Menu.Item>
              ) : (
                <div style={{ minWidth: "65px" }}></div>
              )}
            </Menu.Menu>
          </Menu>
        </div>
      </Sticky>
    );
  }
);

export default AppPageMenu;
