import { createSelector } from "reselect";
import { parseBudgetTxIdToDate, toYyyyMm } from "../../../../util/helpers";
import { firstBudgetDateSelector, lastBudgetDateSelector } from "../../Selector/metaSelectors";

const getFirstBudgetYmLoaded = (state) => state.budgetui.firstBudgetYmLoaded;
const getLastBudgetYmLoaded = (state) => state.budgetui.lastBudgetYmLoaded;

export const moreToLoadBackwardSelector = createSelector(
  [getFirstBudgetYmLoaded, firstBudgetDateSelector],
  (fromYm, firstBudgetDate) => {
    const firstBudgetYm = toYyyyMm(firstBudgetDate);
    // console.log(`moreToLoadBACKSel: fromYm ${fromYm}, firstYm ${firstBudgetYm}`);
    return firstBudgetYm && fromYm > firstBudgetYm;
  }
);
export const moreToLoadForwardSelector = createSelector(
  [getLastBudgetYmLoaded, lastBudgetDateSelector],
  (toYm, lastBudgetDate) => {
    const lastBudgetYm = toYyyyMm(lastBudgetDate);
    // console.log(`moreToLoadFWDSel: toYm ${toYm}, lastYm ${lastBudgetYm}`);
    return lastBudgetDate && toYm < lastBudgetYm;
  }
);

export const closestTxDateToTodaySelector = createSelector(
  (state) => state.budgetui.closestTxIdToToday,
  (closestTxIdToToday) => parseBudgetTxIdToDate(closestTxIdToToday)
);

// TODO: if budget.sortedAllTxIds.filter(>fromMonth <toMonth).length > 500?, remove a month from either end.
