import React, { useState } from "react";
import { Item, Icon, Image, Button, Confirm } from "semantic-ui-react";
import _ from "lodash";

const NonMemoSharedOwnerDetails = ({
  owner: { id: uid, displayName, email, image },
  sharedItemName,
  onDeleteOwnerClicked,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  function handleConfirmDelete() {
    setConfirmDelete(true);
  }
  function handleDeleteCanceled() {
    setConfirmDelete(false);
  }
  // uid is the owner's user id.
  function handleDeleteConfirmed(uid) {
    setConfirmDelete(false);
    onDeleteOwnerClicked(uid);
  }

  return (
    <Item>
      <Item.Content>
        <Item.Header>
          {!image ? <Icon name='meh outline' /> : <Image src={image} />}
          {displayName}
          <Button
            style={{ marginLeft: "50px" }}
            negative
            onClick={handleConfirmDelete}
            icon='x'
            basic
            size='mini'
          />
          <Confirm
            header={`Stop sharing "${sharedItemName}" with ${displayName}?`}
            content={null}
            size='tiny'
            open={confirmDelete}
            onCancel={handleDeleteCanceled}
            onConfirm={() => handleDeleteConfirmed(uid)}
            confirmButton='Yes'
          />
        </Item.Header>
        <Item.Description>{email}</Item.Description>
      </Item.Content>
    </Item>
  );
};

const isSameOwner = ({ owner: prevOwner }, { owner: nextOwner }) => {
  // Lodash does deep equals, so it will compare properties of each owner.
  return _.isEqual(prevOwner, nextOwner);
};

const SharedOwnerDetails = React.memo(NonMemoSharedOwnerDetails, isSameOwner);
export default SharedOwnerDetails;
