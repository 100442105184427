import { dataFromSnapshot } from "./firestore/firestoreService";
import { getDoc, arrayUnion, arrayRemove, updateDoc, doc, setDoc } from "firebase/firestore";
import { getFirestoreDb } from "../app/configureFirebase";

export function getMyStuffRef(userId) {
  const db = getFirestoreDb();
  return doc(db, `users/${userId}/mystuff`, "1");
}

export async function init(userId) {
  try {
    const myStuffRef = getMyStuffRef(userId);
    // Don't want to overwrite myStuff if it already exists
    let myStuffDoc = await getDoc(myStuffRef);
    if (!myStuffDoc || !myStuffDoc.exists()) {
      await setDoc(myStuffRef, {
        hiddenGoalIds: [],
        selectedAccountId: "",
        sentEmailDate: null,
      });
    }
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

// We watch myStuff in authActions so probably only unit tests will use this function.
export async function getMyStuff(userId) {
  try {
    const myStuffDoc = await getDoc(getMyStuffRef(userId));
    return dataFromSnapshot(myStuffDoc);
  } catch (error) {
    console.log("error", error);
    return null;
  }
}

export async function setSelectedAccountId(userId, accountId) {
  try {
    await updateDoc(getMyStuffRef(userId), { selectedAccountId: accountId });
  } catch (error) {
    console.log("error", error);
    return null;
  }
}

export async function hideGoalForUser(userId, goalId) {
  try {
    await updateDoc(getMyStuffRef(userId), {
      hiddenGoalIds: arrayUnion(goalId),
    });
  } catch (error) {
    console.log("error", error);
  }
}
export async function unhideGoalForUser(userId, goalId) {
  try {
    await updateDoc(getMyStuffRef(userId), {
      hiddenGoalIds: arrayRemove(goalId),
    });
  } catch (error) {
    console.log("error", error);
  }
}
