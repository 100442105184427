import { getAuth, getRedirectResult, GoogleAuthProvider, signInWithPopup, signInWithRedirect, signOut } from "firebase/auth";

export function signOutFirebase() {
  const auth = getAuth();
  return signOut(auth);
}

export async function socialLogin(selectedProvider, isMobile) {
  let provider;
  if (selectedProvider === "google") {
    provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
  }
  try {
    // signInWithRedirect is preferred on mobile devices according to Firestore docs
    const auth = getAuth();
    if (isMobile) {
      const result = await getRedirectResult(auth);
      if (!result) {
        await signInWithRedirect(auth, provider);
      }
      else {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential.accessToken || !result.user) {
          await signInWithRedirect(auth, provider);
        }
      }
    } else {
      await signInWithPopup(auth, provider);
    }
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}
