import React, { createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Ref, Header, Segment, Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../UI/Modals/Reducer/modalActions";
import AppPageMenu from "../../UI/Menus/AppPageMenu";

export default function WhatsNewPage() {
  const auth = useSelector((state) => state.auth);
  const authenticated = auth.authenticated;
  const contextRef = createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <Ref innerRef={contextRef}>
        <div>
          <div>
            {authenticated && (
              <>
                <AppPageMenu title=' ' ref={contextRef} />
              </>
            )}
          </div>
          <div>
            <div
              className='ui vertical masthead center aligned segment basic'
              style={{ marginTop: "80px" }}>
              <Segment basic size='large'>
                <Header size='huge' textAlign='center'>
                  What's New
                </Header>
              </Segment>
            </div>
            <div className='ui vertical center aligned segment basic'>
              <div style={{ maxWidth: "700px", margin: "auto", textAlign: "left" }}>
                <h1>A Completely New Website</h1>
                <p>
                  Sarah's Budget was originally created in 2006 using cutting-edge programming
                  tools. 15 years later, it didn't seem so cutting-edge. The old site worked well,
                  but wasn't a very smooth user experience compared to modern websites now.
                </p>
                <p>
                  So over a year ago, I started rewriting the website using modern Javascript and a
                  different cloud database. I'm excited to say that the work has paid off and the
                  new site is much smoother and more responsive. It works on mobile devices too.
                </p>
                <h2>No More Premium Subscriptions</h2>
                <p>
                  Sarah's Budget is still free for cashflow planning AND Goals are free now too. I
                  won't promise that it will be free forever, but enjoy!
                </p>
                <h2>No More Apps</h2>
                <p>
                  The old mobile apps aren't compatible with the new database so I decided to kill
                  the apps.
                </p>
                <p>
                  If you were using the iPhone or Android app, please delete it because it
                  won't sync with the database in the cloud. Then add the new website to your mobile
                  device's home screen.
                </p>
                <p>
                  <ol>
                    <li>Delete the old app from your mobile device</li>
                    <li>Go to the new website (still on your mobile device)</li>
                    <li>Click "Add to Home Screen" in your mobile device's browser</li>
                  </ol>
                </p>
                <p></p>
                <h1>Need Help?</h1>
                <p>
                  If you have feedback on the new website or need help with anything, plase contact
                  me at
                  <a href='mailto:web-support@sarahsbudget.com'> web-support@sarahsbudget.com</a>
                </p>
                <h3>And lastly, please tell your friends about Sarah's Budget!</h3>
                <div style={{ textAlign: "center", marginTop: "30px", paddingBottom: "50px" }}>
                  {!authenticated ? (
                    <Button
                      color='blue'
                      content='Login or Register'
                      onClick={() => dispatch(openModal("LoginModal"))}
                    />
                  ) : (
                    <Button
                      color='green'
                      content='Go to your budget'
                      onClick={() => navigate("/app/budget")}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Ref>
    </div>
  );
}
