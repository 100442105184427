import * as budgettxs from "./budgetdb.txs";
import * as goalsdb from "./goalsdb";
import * as spendingdb from "./spendingdb";

// Call this, NOT the one in spendingdb.
// oldSplits/newSplits: { [budgetTxId]: { amount, goalId } }
// amount can be <= spending tx amount
// newSplitsWithGoalIds: should be an empty object to unassociate the budget tx.
export async function updateSpendingTxSplits(
  accountId,
  spendingTxYm,
  spendingTxId,
  oldSplitsWithGoalIds,
  newSplitsWithGoalIds
) {
  if (!accountId || !spendingTxId || !spendingTxYm) {
    throw new Error("Error assigning actual txs to the budget. Missing info.");
  }
  const oldSplitsBudgetTxIds = Object.keys(oldSplitsWithGoalIds ?? {});
  const newSplitsBudgetTxIds = Object.keys(newSplitsWithGoalIds ?? {});
  if (oldSplitsBudgetTxIds.length === 0 && newSplitsBudgetTxIds.length === 0) {
    // nothing to do
    return;
  }
  const budgetTxsAmountChanges = {};
  const goalAmountChanges = {};
  // We need the goalId for each budgetTx that had/has a split
  // Update budget txs, update goals, update spending tx
  oldSplitsBudgetTxIds.forEach((budgetTxId) => {
    const oldSplit = oldSplitsWithGoalIds[budgetTxId];
    // Ignore null goalIds
    if (oldSplit.goalId) {
      if (!(oldSplit.goalId in goalAmountChanges)) {
        goalAmountChanges[oldSplit.goalId] = { actualChange: 0 };
      }
      goalAmountChanges[oldSplit.goalId].actualChange -= oldSplit.amount;
    }

    if (!(budgetTxId in budgetTxsAmountChanges)) {
      budgetTxsAmountChanges[budgetTxId] = 0;
    }
    budgetTxsAmountChanges[budgetTxId] -= oldSplit.amount;
  });

  newSplitsBudgetTxIds.forEach((budgetTxId) => {
    const newSplit = newSplitsWithGoalIds[budgetTxId];
    if (newSplit.goalId) {
      if (!(newSplit.goalId in goalAmountChanges)) {
        goalAmountChanges[newSplit.goalId] = { actualChange: 0 };
      }
      goalAmountChanges[newSplit.goalId].actualChange += newSplit.amount;
    }

    if (!(budgetTxId in budgetTxsAmountChanges)) {
      budgetTxsAmountChanges[budgetTxId] = 0;
    }
    budgetTxsAmountChanges[budgetTxId] += newSplit.amount;
  });

  const asyncResults = [];
  asyncResults.push(budgettxs.applyBudgetTxsActualAmountChanges(accountId, budgetTxsAmountChanges));
  asyncResults.push(goalsdb.applyGoalAmountChanges(goalAmountChanges));
  asyncResults.push(spendingdb.updateSpendingTxSplits(
    accountId,
    spendingTxYm,
    spendingTxId,
    newSplitsWithGoalIds
  ));
  await Promise.all(asyncResults);
}
