import _ from "lodash";
import moment from "moment";

export function getSortedTxIds(txs) {
  if (!txs) {
    return [];
  }
  return Object.keys(txs).sort();
}

// Calculate balances for each tx, for the redux store
export function convertMonthEntityToTxs(newMonthEntity) {
  if (!newMonthEntity) {
    // TODO: Is this right? what's the id (ym)?
    newMonthEntity = { startBalance: 0, txs: {} };
  }
  if (!("txs" in newMonthEntity)) {
    newMonthEntity.txs = {};
  }
  if (!("startBalance" in newMonthEntity)) {
    newMonthEntity.startBalance = 0;
  }
  // Put the txs in order, date then category.
  const sortedTxIds = getSortedTxIds(newMonthEntity.txs);

  // Go through txs and sum up the income and expenses for this month.
  const incExp = _.reduce(
    sortedTxIds,
    (res, txId) => ({
      incBd: res.incBd + (!newMonthEntity.txs[txId].isSavings ? Math.max(newMonthEntity.txs[txId].amount, 0) : 0),
      expBd: res.expBd + (!newMonthEntity.txs[txId].isSavings ? Math.min(newMonthEntity.txs[txId].amount, 0) : 0),
      savBd: res.savBd + (newMonthEntity.txs[txId].isSavings ? newMonthEntity.txs[txId].amount : 0),
      // TODO: Put these back in if we ever hook up actual txs again. savAct needs to be NaN for the month header if we don't have actual txs.
      incAct: res.incAct, // + (!newMonthEntity.txs[txId].isSavings ? Math.max(newMonthEntity.txs[txId].actualAmount, 0) : 0),
      expAct: res.expAct, // + (!newMonthEntity.txs[txId].isSavings ? Math.min(newMonthEntity.txs[txId].actualAmount, 0) : 0),
      savAct: res.savAct, // + (newMonthEntity.txs[txId].isSavings ? newMonthEntity.txs[txId].actualAmount : 0),
    }),
    { incBd: 0, expBd: 0, savBd: 0, incAct: NaN, expAct: NaN, savAct: NaN }
  );

  // Go through the txs again and compute the balance for each one.
  const result = _.reduce(
    sortedTxIds,
    (res, txId) => {
      let tx = newMonthEntity.txs[txId];
      // console.log('txId', txId)
      // console.log('tx', tx)
      tx.balance = tx.isBalanceOverride ? tx.overrideBalance : res[1] + tx.amount;
      // console.log('res[0]', res[0])
      res[0][txId] = tx;
      return [res[0], tx.balance];
    },
    [{}, newMonthEntity.startBalance]
  );

  // Put it all together for the redux store.
  const newMonth = {
    id: newMonthEntity.id,
    startBalance: newMonthEntity.startBalance,
    sortedTxIds,
    isFullMonth: newMonthEntity.isFullMonth,
    incomeBudgeted: incExp.incBd,
    expenseBudgeted: incExp.expBd,
    savingsBudgeted: incExp.savBd,
    incomeActual: incExp.incAct,
    expenseActual: incExp.expAct,
    savingsActual: incExp.savAct,
    txs: result[0],
  };

  return newMonth;
}

// Turn a month of txs from the redux store into a DB entity (no tx balances, just startBalance)
export function convertTxsToMonthEntity(newMonth) {
  if (!newMonth) {
    newMonth = { startBalance: 0, txs: {} };
  }
  if (!("txs" in newMonth)) {
    newMonth.txs = {};
  }
  if (!("startBalance" in newMonth)) {
    newMonth.startBalance = 0;
  }
  const sortedTxIds = getSortedTxIds(newMonth.txs);

  // Go through the txs again and remove the balance for each one.
  const txFields =
    sortedTxIds &&
    sortedTxIds.length > 0 &&
    Object.keys(newMonth.txs[sortedTxIds[0]]).filter((fieldName) => fieldName !== "balance");

  const txs = _.reduce(
    sortedTxIds,
    (res, txId) => ({
      ...res,
      // Everything but "balance"
      [txId]: _.pick(newMonth.txs[txId], txFields),
    }),
    {}
  );

  // Put it all together for the redux store.
  const newMonthEntity = {
    id: newMonth.id,
    // month.startBalance is REALLY prevMonth.endBalance. This is important
    // in case the first tx is an override or it gets deleted. We can't
    // always figure out start balance just by looking at the first tx.
    startBalance: newMonth.startBalance,
    txs,
  };

  return newMonthEntity;
}

export function getMonthEndBalance(month) {
  if (!month || !("txs" in month)) {
    return 0;
  }
  return _.reduce(
    Object.keys(month.txs).sort(),
    (res, txId) =>
      month.txs[txId].isBalanceOverride
        ? month.txs[txId].overrideBalance
        : res + month.txs[txId].amount,
    month.startBalance
  );
}

// Returns [ { id, date, category, amount }]
// from start of last week of prev month to end of first week next month from nearDate.
export function getBudgetTxsNearDate(budgetMonths, nearDate) {
  const fromDate = moment(nearDate).startOf("month").subtract(1, "week").toDate();
  const toDate = moment(nearDate).endOf("month").add(1, "week").toDate();
  const txsFlat = _.flatMap(budgetMonths, (month) =>
    _.flatMap(month.txs, (tx, txId) => ({
      ..._.pick(tx, ["date", "category", "amount"]),
      id: txId,
    }))
  );

  return _.sortBy(
    txsFlat.filter((txF) => txF.date >= fromDate && txF.date <= toDate),
    "id"
  );
}
