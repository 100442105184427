import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Header, Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

export default function ErrorComponent({onReset}) {
  const { error } = useSelector((state) => state.async);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Segment placeholder>
      <Header textAlign='center' content={error?.message || "Oops - we have an error"} />
      <Button
        primary
        style={{ marginTop: 20 }}
        content="Return to Sarah's Budget"
        onClick={() => {
          // console.log("Error component button clicked, resetting and going to /");
          dispatch({type: "UNHANDLED_ERROR"});
          onReset && onReset();
          navigate("/");
        }}
      />
    </Segment>
  );
}
