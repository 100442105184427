import _ from "lodash";
import { parseBudgetTxIdToYyyyMm } from "../../../util/helpers";

// Splits are from a spending tx from the database.
// Convert { [budgetTxId]: amountActual } into { [budgetTxId]: { goalId, amountActual } }
export function getSplitsWithGoalIds(splits, budgetMonths) {
  return _.reduce(
    Object.keys(splits || {}),
    (res, budgetTxId) => {
      const oldBudgetTxYm = parseBudgetTxIdToYyyyMm(budgetTxId);
      // Budget txs get deleted and that doesn't update the spending tx (splits). That's on
      // purpose: we already updated the budget and goals and if a budget tx doesn't exist
      // then the spending tx won't be associated with it.
      if (oldBudgetTxYm in budgetMonths && budgetTxId in budgetMonths[oldBudgetTxYm].txs) {
        const oldBudgetTx = budgetMonths[oldBudgetTxYm].txs[budgetTxId];
        res[budgetTxId] = {
          amount: splits[budgetTxId],
          goalId: oldBudgetTx.goalId || null,  // firestore wants null, not undefined
        };
      }
      return res;
    },
    {}
  );
}

// Sort by date
export function getSortedTxIds(txs) {
  if (!txs) {
    return [];
  }
  // make array of {id, date}, then sort by date, then return just the ids
  const txsWithDate = _.map(Object.keys(txs), txId => ({
    id: txId,
    date: txs[txId].date,
  }))
  const sortedTxsWithDate = _.sortBy(txsWithDate, 'date');
  return _.map(sortedTxsWithDate, 'id');
}


// Calculate balances for each tx, for the redux store
export function convertMonthEntityToTxs(newMonthEntity) {
  if (!newMonthEntity) {
    newMonthEntity = { txs: {} };
  }
  if (!("txs" in newMonthEntity)) {
    newMonthEntity.txs = {};
  }

  // Go through txs and sum up the income and expenses for this month.
  const incExp = _.reduce(
    Object.keys(newMonthEntity.txs), // don't have to be sorted because we're not calculating balances
    (res, txId) => ({
      incAct: res.incAct + Math.max(newMonthEntity.txs[txId].amount, 0),
      expAct: res.expAct + Math.min(newMonthEntity.txs[txId].amount, 0),
    }),
    { incAct: 0, expAct: 0 }
  );

  // Put it all together for the redux store.
  const newMonth = {
    id: newMonthEntity.id,
    sortedTxIds: getSortedTxIds(newMonthEntity.txs),
    incomeActual: incExp.incAct,
    expenseActual: incExp.expAct,
    txs: newMonthEntity.txs,
  };

  return newMonth;
}

