import React from "react";
import { useField } from "formik";
import { Checkbox, FormField } from "semantic-ui-react";

export default function MyCheckboxInput({ label, ...props }) {
  // Double-comma because the middle return value is "input" and we don't use it.
  // See https://formik.org/docs/api/useField
  const [field, , helpers] = useField(props);
  return (
    <FormField>
      <Checkbox
        checked={field.value || false}
        onChange={(e, d) => helpers.setValue(d.checked)}
        onBlur={() => helpers.setTouched(true)}
        {...props}
        label={label}
      />
    </FormField>
  );
}
