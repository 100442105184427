import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavBar from "../components/UI/Menus/NavBar";
import ModalManager from "../components/UI/Modals/ModalManager";
import HomePage from "../components/Home/HomePage";
import WhatsNewPage from "../components/Home/WhatsNew/WhatsNewPage";
import BudgetPage from "../components/Budget/BudgetPage";
import RepeatTxsPage from "../components/RepeatTxs/RepeatTxsPage";
import SpendingPage from "../components/Spending/SpendingPage";
import GoalsPage from "../components/Goals/GoalsPage";
import GoalSharingPage from "../components/Goals/GoalDetails/Sharing/GoalSharingPage";
import GoalDetailsPage from "../components/Goals/GoalDetails/GoalDetailsPage";
import AccountsPage from "../components/Accounts/AccountsPage";
import AccountDetailsPage from "../components/Accounts/AccountDetails/AccountDetailsPage";
import AccountSharingPage from "../components/Accounts/AccountDetails/Sharing/AccountSharingPage";
// import UserProfilePage from "../components/User/UserProfilePage";
import AboutUsPage from "../components/Home/AboutUsPage";
// import SignUpPage from "../components/Home/SignUp/SignUpPage";
import DeletionPage from "../components/Home/Deletion/DeletionPage";
// import SBBlog from "../components/Blog/SBBlog";
import PrivateOutlet from "../components/Auth/PrivateOutlet";
import ErrorComponent from "../components/UI/ErrorComponent";
import NotFound from "../components/UI/NotFound";
import TestComponent from "../components/TestArea/TestComponent";
import LoadingComponent from "../components/UI/LoadingComponent";
import { useSelector } from "react-redux";
import { mediaStyles } from "../util/responsive";
import { Container } from "semantic-ui-react";
import ErrorBoundary from "../components/UI/ErrorBoundary";

export default function App() {
  // const { key } = useLocation();
  const { appInitialized } = useSelector((state) => state.app);

  if (!appInitialized) {
    return <LoadingComponent content='Loading app...' />;
  }

  // TODO: Charts page - how much did I spend/earn in each category, for a selectable time period

  return (
    <div>
      <style>{mediaStyles}</style>
      <ErrorBoundary>
        <ModalManager />
        <ToastContainer position='bottom-right' hideProgressBar />
        <NavBar />
        <Container className='main'>
          <Routes>
            <Route exact path='/' element={<HomePage />} />
            <Route exact path='/test' element={<TestComponent />} />
            <Route exact path='/aboutus' element={<AboutUsPage />} />
            {/* <Route exact path='/signup' element={<SignUpPage />} /> */}
            <Route exact path='/deletion' element={<DeletionPage />} />
            {/* <Route exact path='/blog' element={<SBBlog />} /> */}
            <Route exact path='/whatsnew' element={<WhatsNewPage />} />
            <Route path="/app" element={<PrivateOutlet />}>
              <Route path='accountsharing/:id' element={<AccountSharingPage />} />
              <Route path='repeat/:id' element={<RepeatTxsPage />} />
              <Route path='accounts/:id' element={<AccountDetailsPage />} />
              <Route path='accounts' element={<AccountsPage />} />
              <Route path='budget' element={<BudgetPage />} />
              <Route path='spending' element={<SpendingPage />} />
              <Route path='goalsharing/:id' element={<GoalSharingPage />} />
              <Route path='goals/:id' element={<GoalDetailsPage />} />
              <Route path='goals' element={<GoalsPage />} />
            </Route>
            <Route path='/error' element={<ErrorComponent />} />
            <Route element={<NotFound />} />
          </Routes>
        </Container>
      </ErrorBoundary>
    </div>
  );
}
