import {
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_START,
  ASYNC_ACTION_FINISH,
  ASYNC_CLEAR_ERROR,
} from "./asyncConstants";

export function asyncActionStart() {
  return {
    type: ASYNC_ACTION_START,
  };
}

export function asyncActionFinish() {
  return {
    type: ASYNC_ACTION_FINISH,
  };
}

export function asyncActionError(error) {
  console.log("error", error);
  return {
    type: ASYNC_ACTION_ERROR,
    payload: {
      error,
    },
  };
}

export function asyncClearError() {
  return {
    type: ASYNC_CLEAR_ERROR,
  }
}

