import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../components/Async/Reducer/asyncActions";
import { dataFromSnapshot } from "../sbdb.client/firestore/firestoreService";
import { onSnapshot } from "firebase/firestore";

export default function useFirestoreCollection({ query, data, deps }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryRef = query && query();
    if (!queryRef) {
      return;
    }
    // console.log("useFSCollection Async Start");
    dispatch(asyncActionStart());
    const unsubscribe = onSnapshot(queryRef,
      (snapshot) => {
        // if (snapshot.metadata.hasPendingWrites) {
        //   // Wait for the next event.
        //   return;
        // }
        const docs = snapshot.docs.map((doc) => dataFromSnapshot(doc));
        data(docs);
        // console.log("useFSCollection Async Finish");
        dispatch(asyncActionFinish());
      },
      (error) => {
        // Logging the error code here also tells us the error happened in useFSCollection, rather
        // than just asyncActions.
        console.log('useFSCollection error code', error.code);
        dispatch(asyncActionError(error));
      }
    );
    return () => {
      unsubscribe();
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
