import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Divider, Ref, Message } from "semantic-ui-react";
import { Media } from "../../../../util/responsive";
import SharedOwnersList from "../../../SharedOwners/SharedOwnersList";
import useSelectedAccount from "../../../../hooks/useSelectedAccount";
import { addUserToAccountThunk, removeUserFromAccountThunk } from "../../Reducer/accountsThunks";
import { asyncClearError } from "../../../Async/Reducer/asyncActions";
import AppPageMenu from "../../../UI/Menus/AppPageMenu";
import { toast } from "react-toastify";
import * as usersdb from "../../../../sbdb.client/usersdb";
import AddSharedOwnerForm from "../../../SharedOwners/AddSharedOwnerForm";
import _ from "lodash";
import LoadingComponent from "../../../UI/LoadingComponent";
import PageFooter from "../../../UI/PageFooter/PageFooter";

export default function AccountSharingPage(props) {
  let { id: budgetAccountId } = useParams();
  const dispatch = useDispatch();

  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);
  const user = useSelector((state) => state.auth.currentUser);
  const { loading: asyncLoading, error: asyncError } = useSelector((state) => state.async);
  const [loadingPage, setLoadingPage] = useState(true);
  const navigate = useNavigate();

  // Listen to the firestore account info doc => accounts.selectedAccount
  useSelectedAccount(budgetAccountId);

  const loading = asyncLoading || loadingPage || !selectedAccount;

  useEffect(
    function () {
      setLoadingPage(true);
      if (asyncError) {
        // Recover by going back to the accounts page.
        dispatch(asyncClearError());
        navigate("/app/accounts");
      }

      if (!loading && !selectedAccount) {
        // Maybe the user typed in the wrong goal ID into the URL (or an old goal, or a hacking attempt).
        navigate('/app/goals');
        return;
      }
        setLoadingPage(false);
    },
    [dispatch, asyncError, loading, selectedAccount, navigate]
  );

  if (loading) {
    return <LoadingComponent />;
  }
  if (!budgetAccountId) {
    // Maybe the user typed in the wrong account ID into the URL (or an old account, or a hacking attempt).
    navigate('/app/accounts');
    return;
  }

  // Users can only remove themselves if the account has other owners. Otherwise the user
  // needs to delete the account.
  const owners =
    selectedAccount && selectedAccount.owners && selectedAccount.owners.length > 1
      ? selectedAccount.owners
      : [];

  async function handleAddOwnerClicked(newOwnerEmail) {
    try {
      const newOwner = await usersdb.getUserByEmail(newOwnerEmail);
      // console.log('newOwner', newOwner);
      if (!newOwner) {
        return;
      }

      // Add the new owner
      await dispatch(
        addUserToAccountThunk(newOwner.id, newOwnerEmail, newOwner.displayName, budgetAccountId)
      );
    } catch (error) {
      toast.warning("User not found.");
    }
  }

  async function handleDeleteOwnerClicked(uid) {
    // console.log(`Removing ${uid} from ${budgetAccountId}`);
    await dispatch(removeUserFromAccountThunk(uid, budgetAccountId));

    // If user removed themself, go back to the accounts page.
    if (uid === user.uid) {
      navigate("/app/accounts");
    }
  }

  const contextRef = createRef();

  return (
    <Ref innerRef={contextRef}>
      <div>
        <AppPageMenu
          loading={loading}
          ref={contextRef}
          title={selectedAccount?.name ?? "Looking for Account..."}
          subtitle='Account Sharing'
        />
        <div style={{ marginTop: "75px" }}>
          <Button basic onClick={() => navigate(`/app/accounts/${budgetAccountId}`)}>
            &lt; Account Details
          </Button>
        </div>
        <div style={{ paddingTop: "35px" }}>
          <Container text>
            {owners && owners.length > 1 ? (
              <SharedOwnersList
                as={Media}
                at='mobile'
                loading={loading}
                sharedOwners={owners}
                sharedItemName={selectedAccount?.name ?? ""}
                onDeleteOwnerClicked={async (uid) => await handleDeleteOwnerClicked(uid)}
              />
            ) : (
              <>
                <Divider horizontal hidden />
                <Message>
                  <Message.Header>Not Shared</Message.Header>
                  <p>Share your budget accounts with another user by entering their email address below.</p>
                </Message>
              </>
            )}
            <div style={{ marginTop: "50px" }}>
              <AddSharedOwnerForm
                loading={loading}
                currentOwnersEmails={_.map(selectedAccount.owners, "email")}
                onAddOwnerClicked={handleAddOwnerClicked}
              />
            </div>
          </Container>
        </div>
        <PageFooter />
      </div>
    </Ref>
  );
}
