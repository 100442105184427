import React from "react";
import { Grid, Label } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../UI/Modals/Reducer/modalActions";
import ModalWrapper from "../../UI/Modals/ModalWrapper";
import MyTextInput from "../../UI/Form/MyTextInput";
import MyTextArea from "../../UI/Form/MyTextArea";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyCheckboxInput from "../../UI/Form/MyCheckboxInput";
import MySelectInput from "../../UI/Form/MySelectInput";
import MyDateInput from "../../UI/Form/MyDateInput";
import SaveCancelConfirmDeleteButtons from "../../UI/Modals/SaveCancelConfirmDeleteButtons/SaveCancelConfirmDeleteButtons";
import { getSortedAccountsSelector } from "../../Accounts/Selector/accountsSelectors";
import { getSortedShownGoalsSelector } from "../../Goals/Selector/goalsSelectors";
import _ from "lodash";

// values are the current Formik form values, used for conditionally showing fields.
export default function BudgetItemDetailsModal({ title, txToEdit, onSaveBudgetTx, onDelete }) {
  const dispatch = useDispatch();

  const selectedAccountId = useSelector((state) => state.budget.selectedAccountId);
  const allGoals = useSelector((state) => state.goals.goals);
  const shownGoals = useSelector(getSortedShownGoalsSelector);
  const myAccounts = useSelector(getSortedAccountsSelector);
  const tgtAccounts = myAccounts.filter((accountF) => accountF.id !== selectedAccountId);

  if (
    txToEdit &&
    txToEdit.isTransfer &&
    !tgtAccounts.find((tgtAccountIdF) => tgtAccountIdF.id === txToEdit.category)
  ) {
    return (
      <ModalWrapper size='mini' header={title}>
        <h2>
          This is a transfer and you don't have permission to edit the target account. Ask the owner
          to share the budget account with you.
        </h2>
        {/* TODO: If the account is not shared, the target can't be someone else's account so fix the tx: make it not a transfer and let the user edit it. */}
      </ModalWrapper>
    );
  }

  // Check if the user still has this goal. If we don't find the goal, either the user is not
  // an owner any more or the goal was deleted.
  if (txToEdit && txToEdit.goalId && !_.find(allGoals, { id: txToEdit.goalId })) {
    txToEdit.goalId = null;
  }

  const tgtAccountOptions = tgtAccounts.map((tgtAccount) => ({
    key: tgtAccount.id,
    text: tgtAccount.name,
    value: tgtAccount.id,
  }));

  const timeUnitsOptions = [
    { key: "days", text: "Days", value: "days" },
    { key: "weeks", text: "Weeks", value: "weeks" },
    { key: "months", text: "Months", value: "months" },
    { key: "years", text: "Years", value: "years" },
  ];

  const goalOptions =
    shownGoals && shownGoals.length > 0
      ? shownGoals.map((goal) => ({
          key: goal.id,
          text: goal.name,
          value: goal.id,
        }))
      : [{ key: "nogoal", text: "No Goals", value: null }];

  return (
    <ModalWrapper size='mini' header={title}>
      <Formik
        initialValues={
          txToEdit
            ? {
                ...txToEdit,
                isAutoBalance: !txToEdit.isBalanceOverride,
                tgtAccountId: txToEdit.isTransfer ? txToEdit.category : "",
                category: txToEdit.isTransfer ? "" : txToEdit.category,
                isDone: txToEdit.isDone ?? false,
                isSavings: txToEdit.isSavings ?? false,
              }
            : {
                category: "",
                tgtAccountId: "", // TODO: Default to the first tgt account
                date: new Date(),
                isTransfer: false,
                amount: 0,
                isDone: false,
                isSavings: false,
                notes: "",
                goalId: null,
                isAutoBalance: true,
                overrideBalance: 0,
                isRepeating: false,
                frequency: 1,
                timeUnits: "months",
              }
        }
        validationSchema={Yup.object({
          category: Yup.string().when("isTransfer", {
            is: false,
            then: Yup.string().required("*"),
            otherwise: Yup.string(),
          }),
          tgtAccountId: Yup.string().when("isTransfer", {
            is: true,
            then: Yup.string().required("*"),
            otherwise: Yup.string(),
          }),
          date: Yup.date().required("*"),
          amount: Yup.number().required("*"),
          overrideBalance: Yup.number().when("isAutoBalance", {
            is: false,
            then: Yup.number().required("*"),
            otherwise: Yup.number(),
          }),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            // TODO: If it would be a duplicate tx (or xfer tgt is dup), UI should warn the user and ask to replace it.
            const isDuplicate = false;
            let replaceDuplicate = true; // if it's a duplicate, ask the user if we should replace it
            if (!isDuplicate || replaceDuplicate) {
              values.isBalanceOverride = !values.isAutoBalance;
              if (!values.isRepeating) {
                values.frequency = 0;
                values.timeUnits = "";
              }
              onSaveBudgetTx(values);

              setSubmitting(false);
              dispatch(closeModal());
            } else {
              // else it's a duplicate and they don't want to replace it - let them keep editing.
              setSubmitting(false);
            }
          } catch (error) {
            setErrors({ auth: "Error updating the item." });
            setSubmitting(false);
          }
        }}>
        {/* NOTE: Can't edit repeat freq or time units */}
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form className='ui form'>
            {values && !values.isTransfer ? (
              <MyTextInput name='category' placeholder='Category' />
            ) : (
              <MySelectInput
                name='tgtAccountId'
                clearable={false}
                options={tgtAccountOptions}
                placeholder='Target Account'
              />
            )}
            {tgtAccountOptions.length > 0 && <MyCheckboxInput name='isTransfer' label='Transfer' />}
            <MyDateInput name='date' placeholder='Date' />
            <Grid>
              <Grid.Row>
                <Grid.Column width={11}>
                <MyTextInput name='amount' placeholder='0.00' type='number' label='Budgeted Amount' />
                </Grid.Column>
                <Grid.Column width={5}>
                <MyCheckboxInput name='isDone' label='Done' style={{ marginTop: "15px" }} />
                <MyCheckboxInput name='isSavings' label='Savings' style={{ marginTop: "5px" }} />
                </Grid.Column>
              </Grid.Row>
            </Grid><br />
            <MyCheckboxInput name='isAutoBalance' label='Automatic Balance' />
            {values && !values.isAutoBalance && (
              <MyTextInput
                name='overrideBalance'
                placeholder='0.00'
                type='number'
                label='Balance'
              />
            )}
            {!txToEdit && <MyCheckboxInput name='isRepeating' label='Repeat' />}
            {!txToEdit && values && values.isRepeating && (
              <>
                <MyTextInput name='frequency' label='Every' type='number' />
                <MySelectInput name='timeUnits' placeholder='Units' options={timeUnitsOptions} />
              </>
            )}
            <MySelectInput name='goalId' placeholder='Goal' options={goalOptions} />
            <MyTextArea name='notes' placeholder='Notes' />
            {errors.auth && (
              <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
            )}
            <SaveCancelConfirmDeleteButtons
              showDelete={txToEdit != null}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
              onDelete={onDelete}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
