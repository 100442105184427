import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getApp, initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


// console.log("configuring RELEASE firebase", window.location.hostname);

var firebaseConfig =
  window.location.hostname === "localhost"
    ? {
        apiKey: "AIzaSyBPa8_WeTvAfrxTyAxfGh6VDHoZX93RNfs",
        authDomain: "sb20-staging.firebaseapp.com",
        // Point to the RTDB emulator running on localhost.
        // In almost all cases the ns (namespace) is your project ID.
        databaseURL: "http://localhost:9000?ns=sb20-staging",
        projectId: "sb20-staging",
        storageBucket: "sb20-staging.appspot.com",
        messagingSenderId: "311029238947",
        appId: "1:311029238947:web:3c376732bda72336b78316",
      }
    : window.location.hostname.includes("sb20-staging")
    ? {
        apiKey: "AIzaSyBPa8_WeTvAfrxTyAxfGh6VDHoZX93RNfs",
        authDomain: "sb20-staging.firebaseapp.com",
        databaseURL: "https://sb20-staging.firebaseio.com",
        projectId: "sb20-staging",
        storageBucket: "sb20-staging.appspot.com",
        messagingSenderId: "311029238947",
        appId: "1:311029238947:web:3c376732bda72336b78316",
      }
    : {
        apiKey: "AIzaSyAEimFE_fUyqaWFjTmrOnKp7ZYdHoLd8xY",
        authDomain: "www.sarahsbudget.com",
        databaseURL: "https://sb20-13dd3.firebaseio.com",
        projectId: "sb20-13dd3",
        storageBucket: "sb20-13dd3.appspot.com",
        messagingSenderId: "844336524542",
        appId: "1:844336524542:web:9ae16ff885b4466bbe09af",
      };
const firebaseApp = initializeApp(firebaseConfig);
      
if (window.location.hostname === "localhost") {
  console.log("localhost detected!");
  const db = getFirestore();
  connectFirestoreEmulator(db, 'localhost', 8080);
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5001);
}
export function getFirestoreDb() {
  return getFirestore(firebaseApp);
}
export function getFirebaseApp() {
  return firebaseApp;
}

export async function cleanupTestEnv() {
  // pass, needed for the unit test mocks
}

export async function clearFirestoreData() {
  // pass, needed for the unit test mocks
}

export function setFirestoreDb() {
  // pass, needed for the unit test mocks
}

export function isTestEnv() {
  return false;
}
