import { createStore, applyMiddleware } from "redux";
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from "redux-thunk";
import { allReducers } from "./rootReducer";
import { verifyAuth } from '../components/Auth/Reducer/authActions';

export function configureStore () {
  const store = createStore(allReducers, composeWithDevTools(applyMiddleware(thunk)))

  store.dispatch(verifyAuth());

  return store;
};
