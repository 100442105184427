import { createSelector } from "reselect";
import _ from "lodash";

export const getBudgetMonths = (state) => state.budget.months;
const getBudgetUIState = (state) => state.budgetui;

export const budgetSortedMonthIdsSelector = createSelector(getBudgetMonths, (months) => {
  return Object.keys(months).sort();
});

export const filteredSortedBudgetMonthIdsSelector = createSelector(
  [budgetSortedMonthIdsSelector, getBudgetUIState],
  (sortedMonthIds, { firstBudgetYmLoaded, lastBudgetYmLoaded }) => {
    // filter keeps the sorted order
    return sortedMonthIds.filter((id) => id >= firstBudgetYmLoaded && id <= lastBudgetYmLoaded);
  }
);

// The budget list uses this to get txs to show in the UI. It's a subset of
// budget.months.
export const budgetMonthsToViewSelector = createSelector(
  [getBudgetMonths, filteredSortedBudgetMonthIdsSelector],
  (months, sortedMonthIds) => {
    return _.reduce(
      sortedMonthIds,
      (res, monthId) => {
        res[monthId] = _.cloneDeep(months[monthId]);
        return res;
      },
      {}
    );
  }
);

export const budgetSortedAllTxIdsSelector = createSelector(getBudgetMonths, (months) => {
  if (!months || !Object.keys(months) || Object.keys(months).length === 0) {
    return [];
  }
  const allKeys = _.reduce(
    Object.keys(months),
    (res, curMonth) => {
      return res.concat(months[curMonth].sortedTxIds); // alt is Object.keys(curMonth.txs)
    },
    []
  );
  // Keys are already date_category and that's the sort order we want.
  return allKeys.sort();
});
