import React from "react";
import { useSelector } from "react-redux";
import { Container, Divider } from "semantic-ui-react";
import { useAccounts } from "../../hooks/useAccounts";
import useBudget from "../../hooks/useBudget";
import SampleBudgetMonthsForm from "./SampleBudgetMonthsForm/SampleBudgetMonthsForm";
import SampleSpendingMonthsForm from "./SampleSpendingMonthsForm/SampleSpendingMonthsForm";

export default function TestComponent() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(
  //     setUser({
  //       uid: "scott-uid",
  //       displayName: "Scotty G",
  //       email: null,
  //       photoURL: null,
  //       providerId: "google.com",
  //       providerUidDontUse: "11111111",
  //       myStuff: {
  //         selectedAccountId: "",
  //         hiddenGoalIds: [],
  //       },
  //     })
  //   );
  // }, [dispatch]);
  const budget = useSelector((state) => state.budget);
  const selectedAccountId = budget.selectedAccountId;
  const user = useSelector((state) => state.auth.currentUser);

  useAccounts(user.uid);

  useBudget(selectedAccountId);


  // function handleTxSaved(values, isNewTx) {
  //   console.log("values", values);
  //   console.log("isNewTx", isNewTx);
  // }

  // const txToEdit = {
  //   category: "Edit me",
  //   date: new Date("Jan 22, 2021"),
  //   amount: -543.0,
  //   isTransfer: false,
  //   notes: "My Notes",
  //   goalId: null,
  //   isAutoBalance: true,
  // };

  return (
    <Container className='main'>
      <div style={{ marginTop: "75px" }}>
        <h1>Test Area</h1>

        {/* <AccountsPage /> */}

        <h2>Sample Budget Months</h2>
        <SampleBudgetMonthsForm />
        <Divider />
        <h2>Sample Spending Months</h2>
        <SampleSpendingMonthsForm />

        {/* <Button
          positive
          onClick={() =>
            dispatch(openModal("TestModal", { header: "Hello world" }))
          }
          content='Edit Tx'
        /> */}
        {/* <Button
          positive
          onClick={() =>
            dispatch(openModal("BudgetItemDetailsModal", {
              title: "Edit Item Details",
              txToEdit: txToEdit,
              onSaveBudgetTx: (values) => handleTxSaved(values, !txToEdit)
            }))
          }
          content='Edit Item'
        /> */}

        {/* <AddAccountForm /> */}

        {/* <ItemPlansList plans={sampleData.sampleItemPlans}/> */}

        {/* <RecItem
          recId='rec1'
          atDate={new Date()}
          atUsage={12000}
          usageUnitsDesc='mi'
          item={{ icon: '../../../assets/images/subaru.png', name: "Scott's 2014 Subaru Outback" }}
          summary='3000 mile oil change'
          description='Oil change, Rotate tires, Check fluids, Check brakes'
          isSchedulable
          onSkipRec={this.handleSkipRec}
          onSnoozeRec={this.handleSnoozeRec}
          onScheduleRec={this.handleScheduleRec}
          />
       */}
      </div>
    </Container>
  );
}
