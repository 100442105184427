import * as sbm from "../sampleBudgetMonth";
import * as ssm from "../sampleSpendingMonth";
import * as budgetdb from "../../budgetdb";
import * as spendingdb from "../../spendingdb";
import moment from "moment";
import { prevYyyyMm, toYyyyMm } from "../../../util/helpers";
import { dataFromSnapshot } from "../../firestore/firestoreService";
import { getDoc, setDoc } from "firebase/firestore";
import { getMonthEndBalance } from "../../../components/Budget/Reducer/budgetHelpers";
import _ from "lodash";

// This doesn't do any repeating txs. That would be much more complicated because
// we would have to execute each repeat tx and merge the old repeating with the
// new repeating.
// Technically a budget should have all consecutive months, even if they're empty. If
// you create a gap then meta will be wrong and if you delete the month, meta will
// be really wrong.
export async function createTestBudgetMonth(
  accountId,
  ymToCreate,
  monthType = "normal",
  isFullMonth = true,
  isBalanceOverridesAllowed = true
) {
  // console.log(`account ${accountId} ym ${ymToCreate} type ${monthType}`);

  try {
    let testMonth;
    switch (monthType) {
      case "normal":
      default:
        testMonth = sbm.createRandomMonth(ymToCreate, 30, isFullMonth, isBalanceOverridesAllowed);
        break;
      case "large":
        testMonth = sbm.createRandomMonth(ymToCreate, 75, isFullMonth, isBalanceOverridesAllowed);
        break;
      case "huge":
        testMonth = sbm.createRandomMonth(ymToCreate, 300, isFullMonth, isBalanceOverridesAllowed);
        break;
      case "tiny":
        testMonth = sbm.createRandomMonth(ymToCreate, 2, isFullMonth, isBalanceOverridesAllowed);
        break;
      case "empty":
        testMonth = {
          id: ymToCreate,
          startBalance: 0,
          //  TODO: Add isFullMonth checkbox to the test UI
          isFullMonth,
          txs: {},
        };
        break;
    }

    const monthToCreateRef = budgetdb.getMonthRef(accountId, ymToCreate);

    // get the previous month so we can figure out the new month's start balance
    const prevMonth = await budgetdb.getMonth(accountId, prevYyyyMm(ymToCreate));
    // Insert or overwrite the budget month in the DB
    // If prevMonth doesn't exist, getMonthEndBalance will return 0.
    testMonth.startBalance = getMonthEndBalance(prevMonth);
    await setDoc(monthToCreateRef, testMonth);

    // ymToCreate is YYYYMM for testMonth.
    await updateFutureBalances(accountId, testMonth, ymToCreate);

    return testMonth;
  } catch (error) {
    console.log(`Error creating test budget month ${accountId} ${ymToCreate}`, error);
    throw error;
  }
}

export async function deleteTestBudgetMonth(accountId, ymToDelete) {
  try {
    const monthToDeleteRef = budgetdb.getMonthRef(accountId, ymToDelete);
    let origMonth = dataFromSnapshot(await getDoc(monthToDeleteRef));
    if (!origMonth) {
      return;
    }
    // Clear txs so we can update future balances after deleting the month.
    // startBalance stays correct.
    origMonth.txs = {};

    // Middle of the budget - can have holes for empty months.
    await monthToDeleteRef.delete();

    // Future months get a new start balance until the next OR.
    await updateFutureBalances(accountId, origMonth, ymToDelete);
  } catch (error) {
    console.log("Error deleting test budget month", error);
    throw error;
  }
}

async function updateFutureBalances(accountId, month, ym) {
  let curMonth = month;
  let nextYm = ym;
  let hasOR = false;
  try {
    if (!accountId || !month || !ym) {
      throw new Error("Error updating account balance.");
    }
    while (!hasOR) {
      // get end balance and update next month's start balance. Repeat until OR.
      const curMonthEndBal = getMonthEndBalance(curMonth);
      nextYm = toYyyyMm(moment(nextYm, "YYYYMM").add(1, "month").toDate());

      curMonth = await budgetdb.getMonth(accountId, nextYm);
      if (!curMonth) {
        // No more months
        break;
      }
      // update should work because we checked if next month exists above
      await budgetdb.updateMonthStartBalance(accountId, nextYm, curMonthEndBal);
      // Update our copy in memory so the next call to getMonthEndBalance works.
      curMonth.startBalance = curMonthEndBal;

      // Move cur = next month
      hasOR = curMonth.txs && _.some(_.values(curMonth.txs), { isBalanceOverride: true });
    }
  } catch (error) {
    console.log("Error updating future balances", error);
    throw error;
  }
}

export async function createTestSpendingMonth(accountId, ymToCreate, monthType = "normal") {
  console.log(`account ${accountId} ym ${ymToCreate} type ${monthType}`);

  try {
    let testMonth;
    switch (monthType) {
      case "normal":
      default:
        testMonth = ssm.createRandomMonth(ymToCreate, 30);
        break;
      case "large":
        testMonth = ssm.createRandomMonth(ymToCreate, 75);
        break;
      case "huge":
        testMonth = ssm.createRandomMonth(ymToCreate, 300);
        break;
      case "tiny":
        testMonth = ssm.createRandomMonth(ymToCreate, 2);
        break;
      case "empty":
        testMonth = {
          id: ymToCreate,
          txs: {},
        };
        break;
    }

    const monthToCreateRef = spendingdb.getMonthRef(accountId, ymToCreate);

    // Insert or overwrite the spending month in the DB
    await setDoc(monthToCreateRef, testMonth);

    return testMonth;
  } catch (error) {
    console.log(`Error creating test spending month ${accountId} ${ymToCreate}`, error);
    throw error;
  }
}

export async function deleteTestSpendingMonth(accountId, ymToDelete) {
  try {
    // Middle of the spending - can have holes for empty months.
    await spendingdb.getMonthRef(accountId, ymToDelete).delete();
  } catch (error) {
    console.log("Error deleting test spending month", error);
    throw error;
  }
}
