import React from "react";
import SharedOwnerDetails from "./SharedOwnerDetails";
import { Item } from "semantic-ui-react";
import LoadingComponent from "../UI/LoadingComponent";
// import _ from "lodash";

// sharedOwners should NOT include the current user!
const NonMemoSharedOwnersList = ({
  loading,
  sharedOwners,
  onDeleteOwnerClicked,
  sharedItemName,
}) => {
  // console.log("Rendering SharedOwnerList");
  return (
    <Item.Group>
      {!loading ? (
        sharedOwners.map((owner) => (
          <SharedOwnerDetails
            owner={owner}
            sharedItemName={sharedItemName}
            key={owner.id}
            onDeleteOwnerClicked={onDeleteOwnerClicked}
          />
        ))
      ) : (
        <Item>
          <Item.Content>
            <LoadingComponent />
          </Item.Content>
        </Item>
      )}
    </Item.Group>)
};
// const SharedOwnersList = NonMemoSharedOwnersList;

// Is this faster than re-rendering? I'm not convinced...
// const isOwnersListSame = (prevProps, nextProps) => {
//   const prev = _.map(prevProps.sharedOwners, "userId");
//   const next = _.map(nextProps.sharedOwners, "userId");
//   return _.isEqual(prev, next);
// }

// This is faster and should handle the common case of accounts that aren't shared
const isOwnersListSame = ({ sharedOwners: prevOwners }, { sharedOwners: nextOwners }) => {
  // Returning false tells React to re-render. It doesn't have to be technically correct.
  return prevOwners.length === 0 && nextOwners.length === 0;
};

const SharedOwnersList = React.memo(NonMemoSharedOwnersList, isOwnersListSame);

export default SharedOwnersList;
