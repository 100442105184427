import _ from "lodash";
import {
  SPENDING_ASYNC_START,
  SPENDING_ASYNC_FINISH,
  SPENDING_ASYNC_ERROR,
  CLEAR_SPENDING_TXS,
  SPENDING_MONTHS_LOADED,
  CLEAR_SPENDING,
} from "./spendingConstants";

const initialState = {
  // Below is UI context
  loading: false,
  error: null,
  months: {
    // 'yyyyMM': {
    //   id: 'yyyyMM',
    //   txs: {
    //     // txid: {fields}
    //   },
    //   // Calculated fields for the UI (not saved in the DB)
    //   sortedTxIds: [{id, date}],  // Need id+date because id is a guid so we can't get date from it
    //   incomeActual: 0,
    //   expenseActual: 0,
    // },
  },
};

export default function spendingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SPENDING_ASYNC_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SPENDING_ASYNC_FINISH:
      return {
        ...state,
        loading: false,
      };
    case SPENDING_ASYNC_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case CLEAR_SPENDING:
      return _.cloneDeep(initialState);

    case CLEAR_SPENDING_TXS:
      return {
        ...state,
        months: {},
      };
    case SPENDING_MONTHS_LOADED:
      return {
        ...state,
        months: payload.months,
      };

    default: {
      return state;
    }
  }
}
