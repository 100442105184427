import { useDispatch } from "react-redux";
import { listenToSpendingMonthsAction } from "../components/Spending/Reducer/spendingActions";
import { convertMonthEntityToTxs } from "../components/Spending/Reducer/spendingHelpers";
import * as spendingdb from "../sbdb.client/spendingdb";
import useFirestoreCollection from "./useFirestoreCollection";
import _ from "lodash";

export default function useBudget(accountId) {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => spendingdb.getSpendingMonthsQuery(accountId),
    data: (monthEntities) => {
      dispatch(
        listenToSpendingMonthsAction(
          _.reduce(
            monthEntities,
            (res, monthEntity) => {
              res[monthEntity.id] = convertMonthEntityToTxs(monthEntity);
              return res;
            },
            {}
          )
        )
      );
    },
    deps: [dispatch, accountId],
  });
}
