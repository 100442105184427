import React, { useState } from "react";
import { Button, Confirm, Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../Reducer/modalActions";

// onSubmit happens automatically because the save button is type=submit. Just make sure
// this component is inside a form and the form's onSubmit will be called automatically.
export default function SaveCancelConfirmDeleteButtons({ showDelete, isSubmitting, isValid, dirty, onDelete }) {
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  function handleConfirmDelete() {
    setConfirmDelete(true);
  }
  function handleDeleteCanceled() {
    setConfirmDelete(false);
  }
  function handleDeleteConfirmed() {
    // BudgetPage fills in the parameter to onDelete in handleTxClicked.
    onDelete && onDelete();
    setConfirmDelete(false);
    // Stop editing this tx.
    dispatch(closeModal());
  }

  return (
    <Grid centered columns={3} divided='vertically'>
      <Grid.Row>
        <Grid.Column floated='left'>
          <Button
            floated='left'
            loading={isSubmitting}
            disabled={isSubmitting}
            type='button'
            size='large'
            color='grey'
            content='Cancel'
            onClick={() => dispatch(closeModal())}
          />
        </Grid.Column>
        <Grid.Column floated='right'>
          <Button
            floated='right'
            loading={isSubmitting}
            disabled={!isValid || !dirty || isSubmitting}
            type='submit'
            size='large'
            positive
            content='Save'
          />
        </Grid.Column>
      </Grid.Row>
      {/* TODO: Horiz divider, different color than Cancel button */}
      {showDelete && (
        <Grid.Row>
          <Grid.Column>
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type='button'
              size='large'
              negative
              compact
              onClick={handleConfirmDelete}
              content='Delete'
            />
            <Confirm
              open={confirmDelete}
              onCancel={handleDeleteCanceled}
              onConfirm={handleDeleteConfirmed}
              confirmButton='Delete'
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
}
