import * as accountstestdb from "../../../sbdb.client/util/test/accountstestdb";
import { testAsyncStart, testAsyncFinish, testAsyncError } from "./testActions";

export function createTestBudgetMonthThunk(accountId, ymToCreate, monthType) {
  return async function (dispatch) {
    try {
      dispatch(testAsyncStart());
      await accountstestdb.createTestBudgetMonth(accountId, ymToCreate, monthType);
      dispatch(testAsyncFinish());
    } catch (error) {
      dispatch(testAsyncError(error));
    }
  };
}

export function deleteTestBudgetMonthThunk(accountId, ymToDelete) {
  return async function (dispatch) {
    try {
      dispatch(testAsyncStart());
      await accountstestdb.deleteTestBudgetMonth(accountId, ymToDelete);
      dispatch(testAsyncFinish());
    } catch (error) {
      dispatch(testAsyncError(error));
    }
  };
}

export function createTestSpendingMonthThunk(accountId, ymToCreate, monthType) {
  return async function (dispatch) {
    try {
      dispatch(testAsyncStart());
      await accountstestdb.createTestSpendingMonth(accountId, ymToCreate, monthType);
      dispatch(testAsyncFinish());
    } catch (error) {
      dispatch(testAsyncError(error));
    }
  };
}

export function deleteTestSpendingMonthThunk(accountId, ymToDelete) {
  return async function (dispatch) {
    try {
      dispatch(testAsyncStart());
      await accountstestdb.deleteTestSpendingMonth(accountId, ymToDelete);
      dispatch(testAsyncFinish());
    } catch (error) {
      dispatch(testAsyncError(error));
    }
  };
}
