import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import MyTextInput from "../../UI/Form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { deleteAccountThunk, updateAccountThunk } from "../Reducer/accountsThunks";
import { Button, Confirm, List, Ref, Segment } from "semantic-ui-react";
import useSelectedAccount from "../../../hooks/useSelectedAccount";
import LoadingComponent from "../../UI/LoadingComponent";
import { asyncClearError } from "../../Async/Reducer/asyncActions";
import AppPageMenu from "../../UI/Menus/AppPageMenu";
import { changeSelectedBudgetAccountThunk } from "../../Budget/Reducer/budgetThunks";

export default function AccountDetailsPage(props) {
  // Get ID from the URL
  let { id: showAccountId } = useParams();
  const dispatch = useDispatch();

  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);
  const { accounts } = useSelector((state) => state.accounts);
  const { loading: asyncLoading, error } = useSelector((state) => state.async);
  const { selectedAccountId: selectedBudgetAccountId } = useSelector((state) => state.budget);
  const [loadingPage, setLoadingPage] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const user = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  const contextRef = createRef();

  // Listen to the firestore account info doc => accounts.selectedAccount
  useSelectedAccount(!confirmDelete && showAccountId);

  const loading = asyncLoading || loadingPage || !selectedAccount;

  useEffect(
    function () {
      setLoadingPage(true);
      if (error) {
        // Recover by going back to the accounts page.
        dispatch(asyncClearError());

        navigate("/app/accounts");
      }

      setLoadingPage(false);
    },
    [dispatch, error, user.uid, user.linkToken, navigate]
  );

  if (loading) {
    return <LoadingComponent content='Loading account...' />;
  }
  if (!showAccountId) {
    navigate('/app/accounts');
    return;
  }

  const { name, description } = selectedAccount;

  function handleConfirmDelete() {
    setConfirmDelete(true);
  }
  function handleDeleteCanceled() {
    setConfirmDelete(false);
  }
  function handleDeleteConfirmed() {
    async function doDeleteConfirmed() {
      // Delete the account for all users.
      await dispatch(deleteAccountThunk(showAccountId));
      if (showAccountId === selectedBudgetAccountId) {
        const diffAccountId =
          accounts.find((accountF) => accountF.id !== showAccountId)?.id ?? null;
        await dispatch(changeSelectedBudgetAccountThunk(user.uid, diffAccountId));
      }

      setConfirmDelete(false);
      navigate("/app/accounts");
    }
    doDeleteConfirmed();
  }

  return (
    <Formik
      initialValues={{ name, description }}
      validationSchema={Yup.object({
        name: Yup.string().required("Please enter an account name"),
      })}
      onSubmit={async (values, { resetForm, setSubmitting, setErrors }) => {
        try {
          await dispatch(updateAccountThunk(showAccountId, values));
          setSubmitting(false);
          resetForm({ values: { name, description } });
        } catch (error) {
          setErrors({ auth: "Error updating the account." });
          setSubmitting(false);
        }
      }}>
      {({ isSubmitting, isValid, dirty, errors, resetForm }) => (
        <Ref innerRef={contextRef}>
          <div>
            <AppPageMenu
              loading={loading}
              ref={contextRef}
              title={selectedAccount?.name ?? "Looking for Account..."}
              subtitle='Account Details'
            />
            <Form className='ui form' style={{ marginTop: "75px" }}>
              <Button basic onClick={() => navigate("/app/accounts/")} disabled={isSubmitting}>
                &lt; Accounts
              </Button>

              <Segment className='editgoalaccount' basic>
                <MyTextInput name='name' placeholder='Account Name' />
                <MyTextInput name='description' placeholder='Description' />

                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  style={{ visibility: dirty ? "visible" : "hidden" }}
                  type='submit'
                  size='large'
                  positive
                  content='Save Changes'
                />
                <Button
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                  style={{ visibility: dirty ? "visible" : "hidden" }}
                  size='large'
                  content='Cancel'
                  onClick={() => resetForm()}
                />

                <List selection>
                  <List.Item as={Link} to={`/app/repeat/${showAccountId}`}>
                    Repeating Items &gt;
                  </List.Item>
                  <List.Item as={Link} to={`/app/accountsharing/${showAccountId}`}>
                    Account Sharing &gt;
                  </List.Item>
                </List>
                <div style={{ marginTop: "70px", textAlign: "center" }}>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type='button'
                    size='large'
                    negative
                    compact
                    onClick={handleConfirmDelete}
                    content='Delete'
                  />
                  <Confirm
                    open={confirmDelete}
                    header='Are you sure?'
                    content='This will delete the account for all shared owners.'
                    onCancel={handleDeleteCanceled}
                    onConfirm={handleDeleteConfirmed}
                    confirmButton='Delete'
                    size='tiny'
                  />
                </div>
              </Segment>
            </Form>
          </div>
        </Ref>
      )}
    </Formik>
  );
}
