import React from "react";
import { Button, Container, Label } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import MyTextInput from "../../UI/Form/MyTextInput";
import MySelectInput from "../../UI/Form/MySelectInput";
import * as Yup from "yup";
import { createTestSpendingMonthThunk, deleteTestSpendingMonthThunk } from "../Reducer/testThunks";

const monthTypes = [
  { key: "empty", text: "Empty", value: "empty" },
  { key: "tiny", text: "Tiny", value: "tiny" },
  { key: "normal", text: "Normal", value: "normal" },
  { key: "large", text: "Large", value: "large" },
  { key: "huge", text: "Huge", value: "huge" },
];

export default function SampleSpendingMonthsForm() {
  const accountId = useSelector((state) => state.spending.selectedAccountId);
  const { loading: testRunning } = useSelector((state) => state.test);
  const dispatch = useDispatch();

  if (!accountId) {
    return <h2>No selected account</h2>;
  }

  // Two submit buttons: https://github.com/formium/formik/issues/214#issuecomment-513027013
  return (
    <Formik
      initialValues={{ ymToCreate: "", monthType: "" }}
      validationSchema={Yup.object({
        ymToCreate: Yup.string().required(),
        monthType: Yup.string().required(),
      })}
      onSubmit={async ({ ymToCreate, monthType, isCreate }, { setSubmitting, setErrors }) => {
        try {
          if (isCreate) {
            await dispatch(createTestSpendingMonthThunk(accountId, ymToCreate, monthType));
            console.log("Success creating test spending month");
          } else {
            // Technically it should be ymToDelete, but we're reusing the create form!
            await dispatch(deleteTestSpendingMonthThunk(accountId, ymToCreate));
            console.log("Success deleting test spending month");
          }
          setSubmitting(false);
        } catch (error) {
          setErrors({ auth: "Error creating the test spending month." + error.message });
          setSubmitting(false);
        }
      }}>
      {({ setFieldValue, handleSubmit, isSubmitting, isValid, dirty, errors }) => (
        <Form className='ui form'>
          <Container text>
            <MyTextInput name='ymToCreate' placeholder='YYYYMM' />
            <MySelectInput name='monthType' placeholder='Month type' options={monthTypes} />
            {errors.auth && (
              <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
            )}
            <Button
              loading={isSubmitting || testRunning}
              disabled={!isValid || !dirty || isSubmitting}
              type='button'
              size='large'
              positive
              content='Create the Month'
              onClick={() => {
                setFieldValue("isCreate", true, false);
                handleSubmit();
              }}
            />
            <Button
              loading={isSubmitting || testRunning}
              disabled={!isValid || !dirty || isSubmitting}
              type='button'
              size='large'
              negative
              content='Delete the Month'
              onClick={() => {
                setFieldValue("isCreate", false, false);
                handleSubmit();
              }}
            />
          </Container>
        </Form>
      )}
    </Formik>
  );
}
