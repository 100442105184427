import { createSelector } from "reselect";
import { getSpendingMonths } from "./spendingSelectors";
import _ from "lodash";
import moment from "moment";
import { parseYyyyMm } from "../../../util/helpers";

export const firstSpendingDateSelector = createSelector(getSpendingMonths, (months) => {
  if (_.isEmpty(months)) {
    return null;
  }
  return parseYyyyMm(_.min(Object.keys(months)));
});
export const lastSpendingDateSelector = createSelector(getSpendingMonths, (months) => {
  if (_.isEmpty(months)) {
    return null;
  }
  return moment(_.max(Object.keys(months)), "YYYYMM")
    .endOf("month")
    .toDate();
});
