import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../UI/Form/MyTextInput";
import { Button } from "semantic-ui-react";

// currentOwnersEmails: array of email addresses so we can check for duplicates.
// onAddOwnerClicked: async callback that takes the new email address so callers can insert the owner into the DB.
// After the "Add owner" button is clicked (and validation is successful), this form will clear the email textbox.
export default function AddSharedOwnerForm({ loading, currentOwnersEmails, onAddOwnerClicked }) {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        // Check for duplicate owner email (see isDuplicate below)
        email: Yup.string()
          .email()
          .required("*")
          .test("duplicate-test", "That person already uses this budget account", function (value) {
            return !currentOwnersEmails?.includes(value);
          }),
      })}
      onSubmit={async ( values, { resetForm, setSubmitting, setErrors }) => {
        try {
          onAddOwnerClicked && await onAddOwnerClicked(values.email);
          resetForm();
        } catch (error) {
          setErrors({ auth: "Error updating the item." });
        } finally {
          setSubmitting(false);
        }
      }}>
      {({ isSubmitting, isValid, dirty, errors, values }) => (
        <Form className='ui form'>
          <MyTextInput name='email' placeholder="New owner's email" />
          <Button
            type='submit'
            loading={loading || isSubmitting}
            disabled={loading || isSubmitting}
            icon='plus'
            content='Add a shared owner'
          />
        </Form>
      )}
    </Formik>
  );
}
