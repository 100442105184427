import _ from "lodash";
import {
  GOALS_LOADED,
  LISTEN_TO_SELECTED_GOAL,
  SHOW_HIDDEN_GOALS,
  SET_HIDDEN_GOAL_IDS,
  CLEAR_GOALS
} from "./goalsConstants";

// NOTE: Use Async start/finish/error.

const initialState = {
  goals: [], // id, name, targetDate, targetAmount, budgetedAmount, actualAmount, owners, accountBudgetTxs
  selectedGoal: null,
  // For the UI:
  showHiddenGoals: false,
  hiddenGoalIds: [],
}

export default function budgetReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GOALS_LOADED:
      return {
        ...state,
        goals: payload.goals,
      };

    case LISTEN_TO_SELECTED_GOAL:
      return {
        ...state,
        selectedGoal: payload.selectedGoal,
      }

    case SHOW_HIDDEN_GOALS:
      return {
        ...state,
        showHiddenGoals: payload.showHiddenGoals,
      }

    case SET_HIDDEN_GOAL_IDS:
      return {
        ...state,
        hiddenGoalIds: payload.hiddenGoalIds,
      }

    case CLEAR_GOALS:
      return _.cloneDeep(initialState);

    default: {
      return state;
    }
  }
}
