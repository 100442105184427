import {
  SET_ACCOUNTS,
  LISTEN_TO_SELECTED_ACCOUNT,
  ACCOUNT_CREATED,
  CLEAR_ACCOUNT_CREATED,
  CLEAR_ACCOUNTS
} from "./accountsConstants";

// NOTE: Use Async start/finish/error.

// Accounts can become undefined if we run unit tests. Not a problem - just reload the page.
// For the default params: need to have = {} because destucturing undefined throws an error, but
// destructuring {} results in defaults for each field (the empty arrays).

export function clearAccountsAction() {
  return {
    type: CLEAR_ACCOUNTS,
  }
}

export function listenToAccountsAction(accounts = []) {
  return {
    type: SET_ACCOUNTS,
    payload: {
      accounts,
    },
  };
}

export function listenToSelectedAccountAction(selectedAccount) {
  return {
    type: LISTEN_TO_SELECTED_ACCOUNT,
    payload: {
      selectedAccount,
    },
  };
}

export function accountCreatedAction(newAccountId) {
  return {
    type: ACCOUNT_CREATED,
    payload: {
      newAccountId,
    },
  };
}
export function clearAccountCreatedAction() {
  return {
    type: CLEAR_ACCOUNT_CREATED,
    payload: {},
  };
}
