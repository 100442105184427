import {
  BUDGET_ASYNC_START,
  BUDGET_ASYNC_FINISH,
  BUDGET_ASYNC_ERROR,
  CLEAR_BUDGET,
  CLEAR_BUDGET_TXS,
  BUDGET_MONTHS_LOADED,
  CHANGE_SELECTED_ACCOUNT,
} from "./budgetConstants";

export function budgetAsyncStart() {
  return {
    type: BUDGET_ASYNC_START,
  };
}

export function budgetAsyncFinish() {
  return {
    type: BUDGET_ASYNC_FINISH,
  };
}

export function budgetAsyncError(error) {
  console.log("error", error);
  return {
    type: BUDGET_ASYNC_ERROR,
    payload: {
      error,
    },
  };
}

export function selectedAccountChangedAction(accountId) {
  return {
    type: CHANGE_SELECTED_ACCOUNT,
    payload: {
      accountId,
    },
  };
}

export function listenToBudgetMonthsAction(months) {
  return {
    type: BUDGET_MONTHS_LOADED,
    payload: {
      months,
    },
  };
}

export function clearBudgetTxsAction() {
  return {
    type: CLEAR_BUDGET_TXS,
    payload: {},
  };
}

export function clearBudgetAction() {
  return {
    type: CLEAR_BUDGET,
  };
}
