import { createSelector } from "reselect";
import _ from "lodash";

export const getSpendingMonths = (state) => state.spending.months;
const getSpendingUIState = (state) => state.spendingui;

export const spendingSortedMonthIdsSelector = createSelector(getSpendingMonths, (months) => {
  return Object.keys(months).sort();
});

export const loadedSpendingMonthIdsSortedSelector = createSelector(
  [spendingSortedMonthIdsSelector, getSpendingUIState],
  (sortedMonthIds, { firstSpendingYmLoaded, lastSpendingYmLoaded }) => {
    // filter keeps the sorted order
    return sortedMonthIds.filter((id) => id >= firstSpendingYmLoaded && id <= lastSpendingYmLoaded);
  }
);

// The spending list uses this to get txs to show in the UI. It's a subset of
// spending.months.
export const spendingMonthsToViewSelector = createSelector(
  [getSpendingMonths, loadedSpendingMonthIdsSortedSelector],
  (months, sortedMonthIds) => {
    return _.reduce(
      sortedMonthIds,
      (res, monthId) => {
        res[monthId] = _.cloneDeep(months[monthId]);
        return res;
      },
      {}
    );
  }
);

export const spendingSortedAllTxIdsSelector = createSelector(getSpendingMonths, (months) => {
  if (!months || !Object.keys(months) || Object.keys(months).length === 0) {
    return [];
  }
  const allKeys = _.reduce(
    Object.keys(months),
    (res, curMonth) => {
      return res.concat(months[curMonth].sortedTxIds); // alt is Object.keys(curMonth.txs)
    },
    []
  );
  // Keys are already date_category and that's the sort order we want.
  return allKeys.sort();
});
