import { combineReducers } from "redux";
import testReducer from "../components/TestArea/Reducer/testReducer";
import modalReducer from "../components/UI/Modals/Reducer/modalReducer";
import authReducer from "../components/Auth/Reducer/authReducer";
import asyncReducer from "../components/Async/Reducer/asyncReducer";
import appReducer from "../app/Reducer/appReducer";
import profileReducer from "../components/User/Reducer/profileReducer";
import accountsReducer from "../components/Accounts/Reducer/accountsReducer";
import budgetReducer from "../components/Budget/Reducer/budgetReducer";
// Spending = Actual
import spendingReducer from "../components/Spending/Reducer/spendingReducer";
import repeatTxsReducer from "../components/RepeatTxs/Reducer/repeatTxsReducer";
import budgetuiReducer from "../components/Budget/Reducer/UI/budgetuiReducer";
import spendinguiReducer from "../components/Spending/Reducer/UI/spendinguiReducer";
import goalsReducer from "../components/Goals/Reducer/goalsReducer";

  export const allReducers = combineReducers({
    test: testReducer,
    modals: modalReducer,
    auth: authReducer,
    async: asyncReducer,
    app: appReducer,
    profile: profileReducer,
    accounts: accountsReducer,
    budget: budgetReducer,
    spending: spendingReducer,
    repeat: repeatTxsReducer,
    budgetui: budgetuiReducer,
    spendingui: spendinguiReducer,
    goals: goalsReducer,
  });
