import { getFirestoreDb } from "../app/configureFirebase";
import { getDoc, arrayRemove, updateDoc, doc, setDoc } from "firebase/firestore";
import { dataFromSnapshot } from "./firestore/firestoreService";
import moment from "moment";
import cuid from "cuid";
import _ from "lodash";

export function getRepeatTxsRef(accountId) {
  if (!accountId) {
    return null;
  }
  const db = getFirestoreDb();
  return doc(db, `accounts/${accountId}/repeat`, "1");
}

export function getRepeatTxsQuery(accountId) {
  if (!accountId) {
    return null;
  }
  const db = getFirestoreDb();
  return doc(db, `accounts/${accountId}/repeat`, '1');
}

export async function getRepeatTxs(accountId) {
  try {
    if (!accountId) {
      return null;
    }
    const repeatDoc = dataFromSnapshot(await getDoc(getRepeatTxsRef(accountId)));
    if (!repeatDoc || !repeatDoc.txs || repeatDoc.txs.length === 0) {
      return [];
    }
    return repeatDoc.txs;
  } catch (error) {
    console.log(`Error getting repeat txs for account ${accountId}`, error);
    throw error;
  }
}

export async function createRepeatTx(accountId, repeatTx) {
  try {
    if (!accountId || !repeatTx) {
      throw new Error("Error creating repeat item. Missing info.");
    }
    // NOTE: This might be the first repeat tx, or it might be a duplicate startDate/category
    // so we have to get the repeat doc, check it, and set it back to the DB. Don't change
    // this to a Firestore update call unless you can account for these cases.
    let repeatDoc = dataFromSnapshot(await getDoc(getRepeatTxsRef(accountId)));
    if (!repeatDoc || !repeatDoc.txs) {
      repeatDoc = {
        txs: [repeatTx],
      };
    } else {
      // If it's a duplicate [category, startDate] then
      // update it with the new info from repeat. Filter out the old before
      // we concat the new.
      const repeatTxsNoDup = repeatDoc.txs.filter(
        (tx) =>
          tx.category !== repeatTx.category ||
          !moment(tx.startDate).isSame(repeatTx.startDate, "day")
      );
      repeatDoc.txs = [...repeatTxsNoDup, { ...repeatTx, id: cuid() }];
    }
    await setDoc(getRepeatTxsRef(accountId), repeatDoc);
  } catch (error) {
    console.log("Error creating repeat tx", error);
    throw error;
  }
}

export async function updateRepeatTx(accountId, oldTx, newTx) {
  try {
    if (!accountId || !oldTx || !newTx) {
      throw new Error("Error updating repeat item. Missing info.");
    }
    if (oldTx.id !== newTx.id || !moment(oldTx.startDate).isSame(newTx.startDate, 'day')) {
      console.log("Bug: old and new repeatTx don't match id or startDate in repeatdb.updateRepeatTx");
      return;
    }
    const repeatDoc = dataFromSnapshot(await getDoc(getRepeatTxsRef(accountId)));
    const updateIndex = _.findIndex(repeatDoc.txs, { id: oldTx.id });
    repeatDoc.txs.splice(updateIndex, 1, newTx);
    await updateDoc(getRepeatTxsRef(accountId), { txs: repeatDoc.txs });
  } catch (error) {
    console.log(`Error updating repeat tx in ${accountId}`, error);
    throw error;
  }
}

export async function deleteRepeatTx(accountId, oldTx) {
  try {
    if (!accountId || !oldTx) {
      throw new Error("Error deleting repeat item. Missing info.");
    }
    await updateDoc(getRepeatTxsRef(accountId), 
      { txs: arrayRemove(oldTx) }
    );
  } catch (error) {
    console.log(`Error deleting repeat tx ${oldTx.id} from account ${accountId}`, error);
    throw error;
  }
}
