import {
  SET_USER,
  SIGN_IN_USER,
  SIGN_OUT_USER,
  REGISTER_USER_UNSUBSCRIBE_FUNCS,
  SET_LINK_TOKEN,
  BEGIN_LOGIN,
} from "./authConstants";

const initialState = {
  authenticated: false,
  currentUser: null,
  unsubscribeFuncs: [],
  currentLocation: null,
  loggingIn: false,
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN_USER:
      const {
        email,
        photoURL,
        id: uid,
        displayName,
        providerData,
        subscriptionLevel,
        subscriptionProvider,
        subscriptionExpiration,
      } = payload.user;
      return {
        ...state,
        authenticated: true,
        currentUser: {
          email,
          photoURL,
          uid,
          displayName,
          // providerId is 'google', 'facebook', etc.
          providerId: providerData && providerData[0].providerId,
          // providerUid is the user ID according to the login provider so we can
          // associate the user with that service provider later.
          // Specifically, when we get a Delete-User request from Facebook, we only
          // get the uid according to Facebook, which doesn't match the uid that Firestore
          // Auth assigned to the user, and we have to find that user so we need to
          // match Facebook's uid with Firestore's uid.
          // ** Everywhere else in SB we use Firestore's uid. **
          providerUidDontUse: providerData && providerData[0].uid,
          // 0 is free, 1 is trial period (entered payment info), 2 is paid subscribers, 3 is special free person
          subscriptionLevel: subscriptionLevel ?? 0,
          // 0 is none (free trial), 1 is Stripe, 2 is special free person
          subscriptionProvider,
          subscriptionExpiration,
          linkToken: null,
        },
        loggingIn: false,
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
        unsubscribeFuncs: [],
        loggingIn: false,
      };
    case REGISTER_USER_UNSUBSCRIBE_FUNCS:
      return {
        ...state,
        unsubscribeFuncs: [...payload.unsubscribeFuncs],
      };
    case BEGIN_LOGIN:
      return {
        ...state,
        loggingIn: true,
      };
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        currentUser: {
          ...payload.user,
          linkToken: null,
        },
      };

    case SET_LINK_TOKEN:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          linkToken: payload.linkToken,
        },
      };
    default:
      return state;
  }
}
