import _ from "lodash";
import {
  REPEAT_ASYNC_START,
  REPEAT_ASYNC_FINISH,
  REPEAT_ASYNC_ERROR,
  REPEAT_TXS_LOADED,
  CLEAR_REPEAT,
} from "./repeatTxsConstants";

const initialState = {
  loading: false,
  error: null,
  sortedRepeatTxs: [],  // { id, category, amount, startDate, isTransfer, goalId, frequency, timeUnits }
};

export default function repeatItemsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REPEAT_ASYNC_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REPEAT_ASYNC_FINISH:
      return {
        ...state,
        loading: false,
      };
    case REPEAT_ASYNC_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case CLEAR_REPEAT:
      return _.cloneDeep(initialState);

    case REPEAT_TXS_LOADED:
      return {
        ...state,
        sortedRepeatTxs: payload.sortedRepeatTxs,
      };

    default: {
      return state;
    }
  }
}
