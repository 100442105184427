import React from "react";
import { format } from "date-fns";
import { Button, Card, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function GoalsListItem({
  goal: { id: goalId, name, targetDate, targetAmount, budgetedAmount, actualAmount, isHidden }, onHideUnhideClicked
}) {
  return (
    <Card>
      <Card.Content textAlign='center'>
        <Card.Header>{name}</Card.Header>
        <Card.Meta>Target: {format(targetDate, "PP")}</Card.Meta>
        <Card.Description>
          <Grid columns='equal'>
            <Grid.Row stretched verticalAlign='middle'>
              <Grid.Column textAlign='right'>
                Budgeted: {budgetedAmount.toFixed(2)}
                <br />
                Actual: {actualAmount.toFixed(2)}
              </Grid.Column>
              <Grid.Column>
                <span style={{ fontSize: "1.4em" }}>/ {targetAmount.toFixed(2)}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button as={Link} basic to={`/app/goals/${goalId}`} color='purple'>
          Edit
        </Button>
        <Button floated='right' basic onClick={() => onHideUnhideClicked(goalId, !isHidden)}>
          {isHidden ? 'Unhide' : 'Hide'}
        </Button>
      </Card.Content>
    </Card>
  );
}
