import React from "react";
import { Dropdown } from "semantic-ui-react";

export default function AccountListMenu({ selectedAccountId, sortedAccounts, onSelectAccount }) {
  // TIP: Don't separate Dropdown and Dropdown.Menu - the dropdown won't open.
  return (
    <Dropdown item icon='dropdown' pointing="top right" >
      <Dropdown.Menu>
        {sortedAccounts.map((account) => (
          <Dropdown.Item
            key={account.id}
            text={account.name}
            onClick={() => onSelectAccount(account.id)}
            active={account.id === selectedAccountId}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
