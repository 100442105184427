import React, { useEffect, useState } from "react";
import { Visibility, Segment, Button, Divider } from "semantic-ui-react";
import BudgetListMonthItemNoDrag from "./BudgetListMonthItem/BudgetListMonthItemNoDrag";
import { toMonthDescription } from "../../../../util/helpers";
import { useSelector } from "react-redux";
import * as budgettxs from "../../../../sbdb.client/budgetdb.txs";
import * as Scroll from "react-scroll";
import {
  moreToLoadBackwardSelector,
  moreToLoadForwardSelector,
} from "../../Selector/UI/budgetuiSelectors";
import { lastBudgetFullYmSelector } from "../../Selector/metaSelectors";

// No Drag-and-drop because you use a dragging motion to scroll on mobile devices!
export default function BudgetListNoDrag({
  loading,
  sortedMonthIds,
  months,
  onLoadMore,
  onTxClicked,
  onCreateNewMonthClicked,
}) {
  const [topPrevVisible, setTopPrevVisible] = useState(false);
  const [bottomPrevVisible, setBottomPrevVisible] = useState(false);
  const moreToLoadForward = useSelector(moreToLoadForwardSelector);
  const moreToLoadBackward = useSelector(moreToLoadBackwardSelector);
  const firstBudgetYmLoaded = useSelector((state) => state.budgetui.firstBudgetYmLoaded);
  const lastBudgetYm = useSelector(lastBudgetFullYmSelector);

  function scrollToYm(ym) {
    Scroll.scroller.scrollTo(ym, {
      // WARNING: Setting smooth: true BREAKS scrolling after the first time. The first
      // backward month will look good, but then each additional month will jump to the
      // beginning of that month.
      offset: -90,
    });
  }

  function handleUpdate(e, { calculations }) {
    const {
      direction,
      // height,
      // width,
      // topPassed,
      // bottomPassed,
      // pixelsPassed,
      // percentagePassed,
      topVisible,
      bottomVisible,
      // fits,
      // passing,
      // onScreen,
      // offScreen
    } = calculations;
    // console.log("calculations", calculations);
    // console.log(
    //   `loading ${loading}, direction ${direction}, moreFwd ${moreToLoadForward}, moreBk ${moreToLoadBackward}`
    // );
    // console.log(
    //   `bottomVis ${bottomVisible} (prev ${bottomPrevVisible}), topVis ${topVisible} (prev ${topPrevVisible})`
    // );

    // First time we load the page, or if we haven't loaded enough txs, get more.
    if (!loading && bottomVisible && topVisible) {
      if (moreToLoadForward) {
        // console.log("BdList INITIAL loading more forward");
        onLoadMore("forward");
      } else if (moreToLoadBackward) {
        // console.log("BdList INITIAL loading more backward");
        onLoadMore("backward");
        // Scroll to the month after the one we just loaded so it doesn't jump to
        // the start of the previous month.
        if (firstBudgetYmLoaded) {
          scrollToYm(firstBudgetYmLoaded);
        }
      }
    } else if (
      !loading &&
      direction === "down" &&
      moreToLoadForward &&
      bottomVisible &&
      !bottomPrevVisible
    ) {
      // console.log("BdList DOWN loading more forward");
      onLoadMore("forward");
    }
    // TODO: handle cases with not much data (1 item, 1 tx per month, missing months) - we should load as much as possible until the view is full at least
    else if (
      !loading &&
      direction === "up" &&
      moreToLoadBackward &&
      topVisible &&
      !topPrevVisible
    ) {
      // console.log("BdList UP loading more backward");
      onLoadMore("backward");
      // Scroll to the month after the one we just loaded so it doesn't jump to
      // the start of the previous month.
      if (firstBudgetYmLoaded) {
        scrollToYm(firstBudgetYmLoaded);
      }
    }

    setTopPrevVisible(topVisible);
    setBottomPrevVisible(bottomVisible);
  }

  useEffect(() => {
    // Fire an event to get Semantic-UI to call handleUpdate once more after things have loaded.
    // Need to make sure we're showing enough txs to fill the view.
    window.dispatchEvent(new Event('resize'));
  }, []);

  // Visibility:
  // fireOnMount: so we load txs right away. Otherwise handleUpdate won't fire until
  //    the user scrolls, but there's nothing to scroll yet.
  // updateOn repaint: in case we haven't loaded enough txs to fill the screen;
  //    SUI will fire handleUpdate again and we'll see that top and bottom are still
  //    both visible.
  // TODO: Figure out why we're re-rendering constantly, set updateOn='repaint' in Visibility and log something in onUpdate to see the stream.
  // TODO: No budget items? Tell the user to create one.
  return (
    <>
      <Visibility onUpdate={handleUpdate} fireOnMount offset='80'>
        <Segment basic>
          {months &&
            sortedMonthIds.map((monthId) => {
              // ym (monthId) ==> the react-scroll Element name so we can scroll to it.
              // month ==> data for the month: txs, amounts actual/budgeted
              // monthName ==> user-friendly 'Month, Year' string the top of each month (header).
              // key ==> React needs a unique key for each list item.
              return (
                <BudgetListMonthItemNoDrag
                  ym={monthId}
                  month={months[monthId]}
                  monthName={toMonthDescription(monthId)}
                  key={monthId}
                  onTxClicked={onTxClicked}
                />
              );
            })}
        </Segment>
      </Visibility>
      {!moreToLoadForward && lastBudgetYm < budgettxs.MAX_BUDGET_YM && (
        // And not at max budget date (18 mos into the future)
        <div style={{ textAlign: "center", paddingBottom: "30px" }}>
          <Divider />
          <Button primary content='Create New Month' onClick={() => onCreateNewMonthClicked()} />
        </div>
      )}
    </>
  );
}
