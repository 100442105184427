import { CLEAR_SPENDING_UI, SPENDING_UI_SHOW_MONTHS, SPENDING_UI_SET_CLOSEST_TXID_TO_TODAY } from "./spendinguiConstants"

export function clearSpendingUIAction() {
  return {
    type: CLEAR_SPENDING_UI,
  }
}

// Pass in strings: yyyyMM
export function spendinguiShowMonthsAction(fromMonth, toMonth) {
  return {
    type: SPENDING_UI_SHOW_MONTHS,
    payload: {
      firstSpendingYmLoaded: fromMonth,
      lastSpendingYmLoaded: toMonth,
    }
  }
}

export function spendinguiSetClosestTxIdToTodayAction(txId) {
  return {
    type: SPENDING_UI_SET_CLOSEST_TXID_TO_TODAY,
    payload: {
      closestTxIdToToday: txId,
    }
  }
}
