import React, { createRef } from "react";
import BudgetMonthHeaderViewNoDrag from "./BudgetMonthHeaderViewNoDrag";
import BudgetListItemNoDrag from "../BudgetListItem/BudgetListItemNoDrag";
import { useSelector } from "react-redux";
import { closestTxDateToTodaySelector } from "../../../Selector/UI/budgetuiSelectors";
import { Divider, Grid, Sticky } from "semantic-ui-react";
import _ from "lodash";
import { useGoals } from "../../../../../hooks/useGoals";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const ROW_COLOR_EVEN_MONTH = "evenmonth";
const ROW_COLOR_ODD_MONTH = "oddmonth";
const ROW_COLOR_TODAY = "todaycolor";

export default function BudgetListMonthItemNoDrag({
  month,
  ym,
  monthName,
  onTxClicked,
}) {
  const { incomeBudgeted, expenseBudgeted, savingsBudgeted, incomeActual, expenseActual, savingsActual, txs } = month;
  const closestTxDateToToday = useSelector(closestTxDateToTodaySelector);
  const user = useSelector((state) => state.auth.currentUser);
  const goals = useSelector((state) => state.goals.goals);
  const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const ymColor = parseInt(ym) % 2 === 0 ? ROW_COLOR_EVEN_MONTH : ROW_COLOR_ODD_MONTH;

  useGoals(user.uid);

  function getRowColor(txDate, ix) {
    // "Today" = next upcoming date
    // Today is highlighted (all txs with today's date, or closest to today, but not before).
    // Yes, closest_to_today will be wrong if we load way in the future before we load around today,
    // but it will correct itself as the user scrolls up to today.
    // All odd rows get color.
    // Odd months get one color and even months get a different color.
    // All even rows are default color.
    return closestTxDateToToday && moment(closestTxDateToToday).isSame(txDate, "day")
      ? ROW_COLOR_TODAY
      : ix % 2 === 1
      ? ymColor
      : null; // SUI doesn't accept 'white', but null is okay.
  }

  const contextRef = createRef();
  // The Card used in BudgetMonthHeaderViewNoDrag centers itself in its parent
  // so make the column as wide as possible to work on all screen sizes.
  const monthHeaderItem = (
    <Grid centered columns={16}>
      <Grid.Column width={16}>
        <BudgetMonthHeaderViewNoDrag
          ym={ym}
          monthName={monthName}
          incomeBudgeted={incomeBudgeted}
          expenseBudgeted={expenseBudgeted}
          savingsBudgeted={savingsBudgeted}
          incomeActual={incomeActual}
          expenseActual={expenseActual}
          savingsActual={savingsActual}
        />
      </Grid.Column>
    </Grid>
  );

  return (
    <Grid>
      <Grid.Column>
        <div ref={contextRef}>
          {!isMobile ? (
            <Sticky context={contextRef} offset={135}>
              {monthHeaderItem}
            </Sticky>
          ) : (
            monthHeaderItem
          )}
          <Divider horizontal />
          {month.sortedTxIds.map((txId, ix) => {
            const goalName = _.find(goals, { id: txs[txId].goalId })?.name ?? "";
            return (
              <BudgetListItemNoDrag
                txId={txId}
                tx={txs[txId]}
                key={txId}
                goalName={goalName}
                rowColor={getRowColor(txs[txId].date, ix)}
                onClick={() => onTxClicked(txId)}
              />
            );
          })}
        </div>
      </Grid.Column>
    </Grid>
  );
}
