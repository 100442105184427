import {
  APP_LOADED,
} from "./appConstants";

const initialState = {
  appInitialized: false,
};

export default function asyncReducer(state = initialState, { type, payload }) {
  switch (type) {
    case APP_LOADED:
      return {
        ...state,
        appInitialized: true,
      };
    default:
      return state;
  }
}
