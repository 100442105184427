import * as goalsdb from "../../../sbdb.client/goalsdb";
import * as mystuffdb from "../../../sbdb.client/mystuffdb";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../Async/Reducer/asyncActions";

export function createGoalThunk(uid, userDisplayName, email, goalName, targetDate, targetAmount) {
  return async function (dispatch) {
    try {
      // ActionStart gives us a loading indicator
      dispatch(asyncActionStart());

      // We're listening to goals so we don't need to update redux store here.
      await goalsdb.createGoal(uid, userDisplayName, email, goalName, targetDate, targetAmount);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function updateGoalThunk(goalId, newValues) {
  return async function (dispatch) {
    try {
      dispatch(asyncActionStart());

      // We're listening to goals so we don't need to update redux store here.
      await goalsdb.updateGoal(goalId, newValues);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}
export function addUserToGoalThunk(newUserId, email, displayName, goalId) {
  return async function (dispatch) {
    try {
      dispatch(asyncActionStart());

      // We're listening to goals so we don't need to update redux store here.
      await goalsdb.addUserToGoal(newUserId, email, displayName, goalId);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function removeUserFromGoalThunk(userId, goalId) {
  return async function (dispatch) {
    try {
      dispatch(asyncActionStart());

      // We're listening to goals so we don't need to update redux store here.
      await goalsdb.removeUserFromGoal(userId, goalId);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function hideGoalForUserThunk(userId, goalId, hideTheGoal) {
  return async function (dispatch) {
    try {
      dispatch(asyncActionStart());

      // We're listening to mystuff so we don't need to update redux store here.
      // To clarify, in authActions, mystuff changes will update goals.hiddenGoalIds
      // and our goals selectors will recalculate shown goals.
      if (hideTheGoal) {
        await mystuffdb.hideGoalForUser(userId, goalId);
      } else {
        await mystuffdb.unhideGoalForUser(userId, goalId);
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}
export function deleteGoalThunk(goalId) {
  return async function (dispatch) {
    try {
      dispatch(asyncActionStart());

      await goalsdb.deleteGoal(goalId);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}
