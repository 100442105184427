import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useFirestoreDoc from "../../hooks/useFirestoreDoc";
import { Button, Divider, Message, Ref } from "semantic-ui-react";
import { Media } from "../../util/responsive";
import { getRepeatTxsQuery } from "../../sbdb.client/repeatdb";
import { listenToRepeatTxsAction } from "./Reducer/repeatTxsActions";
import _ from "lodash";
import moment from "moment";
import { openModal } from "../UI/Modals/Reducer/modalActions";
import { updateRepeatTxThunk, deleteRepeatTxThunk } from "./Reducer/repeatTxsThunks";
import RepeatTxsList from "./RepeatTxsList/RepeatTxsList";
import useSelectedAccount from "../../hooks/useSelectedAccount";
import LoadingComponent from "../UI/LoadingComponent";
import { asyncClearError } from "../Async/Reducer/asyncActions";
import AppPageMenu from "../UI/Menus/AppPageMenu";
import { useGoals } from "../../hooks/useGoals";
import { useAccounts } from "../../hooks/useAccounts";

export default function RepeatTxsPage(props) {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);
  const { loading: asyncLoading, error } = useSelector((state) => state.async);
  const { sortedRepeatTxs, loading: repeatTxsLoading } = useSelector((state) => state.repeat);
  const contextRef = createRef();
  const [ loadingPage, setLoadingPage ] = useState(true);
  let { id: accountId } = useParams();
  const navigate = useNavigate();
  const loading = repeatTxsLoading || asyncLoading || loadingPage;
  const user = useSelector((state) => state.auth.currentUser);

  useAccounts(user.uid);
  useGoals(user.uid);
  useSelectedAccount(accountId);

  useFirestoreDoc({
    query: () => getRepeatTxsQuery(accountId),
    data: (repeatTxsDoc) => {
      // TODO: Add enough repeat txs to overflow the document size and see if we can limit them rather than just crash.
      // TODO: Add enough budget txs to a month to overflow the document size and see if we can limit them rather than just crash.
      // Add freqDays so we can sort by that first (then category)
      // TODO: Make this a helper so we can unit-test it.
      const allRepeatTxsWithFreqDays = _.map(repeatTxsDoc.txs, (repTx) => ({
        ...repTx,
        freqDays: moment.duration(repTx.frequency, repTx.timeUnits).asDays(),
      }));
      const newSortedRepeatTxs = _.sortBy(allRepeatTxsWithFreqDays, ["freqDays", "category"]).map(
        (tx) => {
          delete tx.freqDays;
          return tx;
        }
      );

      dispatch(listenToRepeatTxsAction(newSortedRepeatTxs));
    },
    deps: [dispatch, accountId],
  });

  useEffect(function () {
    setLoadingPage(true);
    if (error) {
      // Recover by going back to the previous page (budget or account details).
      dispatch(asyncClearError());
      navigate(-1);
    }

    // Make sure the accountID was specified in the URL and it belongs to the user.
    if (!loading && (!accountId || !selectedAccount)) {
      navigate(-1);
    }
    setLoadingPage(false);
  }, [dispatch, navigate, error, accountId, selectedAccount, loading]);

  if (loading) {
    return <LoadingComponent content='Loading account...' />;
  }

  function handleTxClicked(repeatTx) {
    // Open the details modal:
    dispatch(
      openModal("RepeatTxDetailsModal", {
        title: "Edit Repeat Item",
        accountId,
        txToEdit: repeatTx,
        onSubmit: (values) => handleTxSaved(repeatTx, values),
        onDelete: () => handleTxDeleted(repeatTx),
      })
    );
  }
  async function handleTxSaved(oldTx, newValues) {
    const newTx = {
      ...oldTx,
      category: newValues.isTransfer ? newValues.tgtAccountId : newValues.category,
      amount: newValues.amount,
      isTransfer: newValues.isTransfer,
      goalId: newValues.goalId || null,
      frequency: newValues.frequency,
      timeUnits: newValues.timeUnits,
    };
    // Can't create a repeat tx from this page - can only update category (name), amount,
    // and goal id.
    await dispatch(updateRepeatTxThunk(accountId, oldTx, newTx));
  }

  async function handleTxDeleted(oldTx) {
    // When it gets here from RepeatTxDetailsModal, the user has confirmed the delete
    await dispatch(deleteRepeatTxThunk(accountId, oldTx));
  }

  return (
    <Ref innerRef={contextRef}>
      <div>
      <AppPageMenu
          loading={loading}
          ref={contextRef}
          title={selectedAccount?.name ?? "Looking for Account..."}
          activePageName="repeat"
          subtitle="Repeating Items"
        />
        <div style={{marginTop: "75px"}}>
        <Button basic onClick={() => navigate(-1)} >&lt; Back</Button>
        </div>

        {!loading && sortedRepeatTxs.length === 0 ? (
          <div style={{ paddingTop: "35px" }}>
            <Divider horizontal hidden />
            <Message>
              <Message.Header>No repeating items</Message.Header>
            </Message>
          </div>
        ) : (
          <div style={{ paddingTop: "20px" }}>
            {/* <BudgetListWithDrag
            as={Media}
            greaterThanOrEqual='computer'
            loading={loading}
            months={months}
            onTxClicked={handleTxClicked}
          /> */}
            <RepeatTxsList
              as={Media}
              at='mobile'
              loading={loading}
              sortedRepeatTxs={sortedRepeatTxs}
              onTxClicked={handleTxClicked}
            />
          </div>
        )}
      </div>
    </Ref>
  );
}
