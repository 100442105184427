import React, { createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Divider, Message, Ref, Segment } from "semantic-ui-react";
import LoadingComponent from "../UI/LoadingComponent";
import AppPageMenu from "../UI/Menus/AppPageMenu";
import { openModal } from "../UI/Modals/Reducer/modalActions";
import AccountsList from "./AccountsList/AccountsList";
import { getSortedAccountsSelector } from "./Selector/accountsSelectors";
import { useAccounts } from "../../hooks/useAccounts";
import { useNavigate } from "react-router-dom";

export default function AccountsPage() {
  const user = useSelector((state) => state.auth.currentUser);
  const { loading, error } = useSelector((state) => state.async);
  const myAccounts = useSelector(getSortedAccountsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contextRef = createRef();

  useAccounts(user.uid);

  if (error) {
    navigate('/error');
    return;
  }

  return (
    <Ref innerRef={contextRef}>
      <div>
        <AppPageMenu
          loading={loading}
          ref={contextRef}
          title="Accounts"
          activePageName="accounts"
          showAddButton
          addButtonText='New Account'
          onAddButtonClicked={() => dispatch(openModal("AddAccountModal"))}
        />
        <div style={{ paddingTop: "65px" }}>
          {loading ? (
            <LoadingComponent content='Loading Accounts...' />
          ) : myAccounts && myAccounts.length > 0 ? (
            <Segment basic>
              <AccountsList loading={loading} accounts={myAccounts} />
            </Segment>
          ) : (
            <>
              <Divider horizontal hidden />
              <Message>
                <Message.Header>No accounts found.</Message.Header>
                <p>
                  <br />
                  <Button onClick={() => dispatch(openModal("AddAccountModal"))}>
                    Create a budget
                  </Button>
                </p>
              </Message>
            </>
          )}
        </div>
      </div>
    </Ref>
  );
}
