import useFirestoreCollection from "./useFirestoreCollection";
import * as goalsdb from "../sbdb.client/goalsdb";
import { useDispatch } from "react-redux";
import { listenToGoalsAction } from "../components/Goals/Reducer/goalsActions";
import _ from "lodash";

export function useGoals(userId) {
  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => goalsdb.getGoalsQuery(userId),
    data: (goalEntities) => {
      // We don't need owners or budgeted txs on this page.
      const goalDescs = _.map(goalEntities, (goalEntity) =>
        _.pick(goalEntity, [
          "id",
          "name",
          "targetDate",
          "targetAmount",
          "budgetedAmount",
          "actualAmount",
        ])
      );
      const newSortedGoals = _.sortBy(goalDescs, ["targetDate", "name"]);
      dispatch(listenToGoalsAction(newSortedGoals));
    },
    deps: [dispatch, userId],
  });
}
