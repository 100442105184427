import moment from "moment";
import cuid from "cuid";

export function createRandomMonth(ymToCreate, numTxs) {
  let sampleMonth = {
    id: ymToCreate,
    txs: {},
  };

  const daysInYm = moment(ymToCreate, "YYYYMM").daysInMonth();

  for (let ix = 0; ix < numTxs; ix++) {
    const nextDate = moment(
      ymToCreate +
        Math.floor(Math.random() * daysInYm + 1)
          .toString()
          .padStart(2, "0"),
      "YYYYMMDD"
    ).toDate();

    const payee = "Payee " + Math.round(Math.random()*100000);
    sampleMonth.txs[cuid()] = {
      amount:
        nextDate.getDate() === 2 || nextDate.getDate() === 17
          ? 1000 + 10 * Math.floor(200 * Math.random())
          : -10 * Math.floor(100 * Math.random()),
      payee,
      date: nextDate,
    };
  }

  return sampleMonth;
}

