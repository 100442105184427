// sorted goals hidden or showing

import { createSelector } from "reselect";
import _ from "lodash";

const isShowHiddenGoals = (state) => state.goals.showHiddenGoals;
const getGoals = (state) => state.goals.goals;
const getSelectedGoal = (state) => state.goals.selectedGoal;
const getUserHiddenGoalIds = (state) => state.goals.hiddenGoalIds;
const getCurrentUser = (state) => state.auth.currentUser;

// For the goals page where we show goals, and maybe hidden goals.
export const getSortedGoalsToShowSelector = createSelector(
  [isShowHiddenGoals, getGoals, getUserHiddenGoalIds],
  (showHiddenGoals, goals, hiddenGoalIds) => {
    const goalsWithIsHidden = _.map(goals, (goalM) => ({
      ...goalM,
      isHidden: hiddenGoalIds.includes(goalM.id),
    }));
    return _.sortBy(
      _.filter(goalsWithIsHidden, (goalFilt) => showHiddenGoals || !goalFilt.isHidden),
      ["targetDate", "name"]
    );
  }
);

// For the goal sharing page, to show the other owners
export const getSortedGoalSharedOwnersSelector = createSelector(
  [getSelectedGoal, getCurrentUser],
  (selectedGoal, user) => {
    // The user doesn't need to see themself in the shared list.
    const sharedOwnerIds = selectedGoal.ownerIds.filter((ownerUidF) => ownerUidF !== user.uid);
    const ownersArray = _.map(sharedOwnerIds, (ownerIdM) => ({
      id: ownerIdM,
      ...selectedGoal.owners[ownerIdM], // displayName, email
    }));
    return _.sortBy(ownersArray, "displayName");
  }
);

// For the dropdown when we assign a goal to a budget tx
export const getSortedShownGoalsSelector = createSelector(
  [getGoals, getUserHiddenGoalIds],
  (goals, hiddenGoalIds) => {
    return _.sortBy(
      _.filter(goals, (goalFilt) => !hiddenGoalIds.includes(goalFilt.id)),
      ["name"]
    );
  }
);
