import { format } from "date-fns";
import moment from "moment";

export function roundTo2DecimalPlaces(num) {
  // Thanks: https://stackoverflow.com/a/11832950
  return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function toYyyyMm(theDate) {
  return theDate ? format(theDate, "yyyyMM") : "";
}

// TODO: Unit test - what happens for undefined, empty string? Should return today or undefined?
export function parseYyyyMm(ym) {
  if (!ym) {
    return null;
  }
  return moment(ym, "YYYYMM").toDate();
}

// Parse ym, add a month, and convert back to ym so we don't have to worry about
// wrapping to a new year.
export function nextYyyyMm(ym) {
  if (!ym) {
    return null;
  }
  return toYyyyMm(moment(ym, "YYYYMM").add(1, "month").toDate());
}
export function prevYyyyMm(ym) {
  if (!ym) {
    return null;
  }
  return toYyyyMm(moment(ym, "YYYYMM").subtract(1, "month").toDate());
}

export function parseBudgetTxIdToDate(txId) {
  return txId ? moment(txId.slice(0, 8), "YYYYMMDD").toDate() : null;
}

export function parseBudgetTxIdToYyyyMm(txId) {
  return txId ? txId.slice(0, 6) : "";
}

export function toMonthDescription(ym) {
  if (!ym) {
    return null;
  }
  return moment(ym, "YYYYMM").format("MMMM YYYY");
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getSbEnv() {
  return window.location.hostname === "localhost"
    ? "dev"
    : window.location.hostname.includes("sb20-staging")
    ? "staging"
    : "production";
}
