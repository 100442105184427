import React, { createRef } from "react";
import { Divider, Loader, Segment, Sticky } from "semantic-ui-react";
import RepeatTxsListItem from "./RepeatTxsListItem/RepeatTxsListItem";

export default function RepeatTxsList({ loading, sortedRepeatTxs, onTxClicked }) {
  // For now, show all repeat txs. If we care later, we can add react-window for large budgets (>500 repeat items).
  const contextRef = createRef();

  return (
    <div ref={contextRef} className='ui'>
      <Segment basic>
        <Sticky context={contextRef} offset={133}>
          <RepeatTxsListItem isHeader={true} />
          <Divider />
        </Sticky>
        {sortedRepeatTxs &&
          sortedRepeatTxs.map((repeatTx) => {
            return (
              <RepeatTxsListItem
                repeatTx={repeatTx}
                key={repeatTx.id}
                onClick={() => onTxClicked(repeatTx)}
              />
            );
          })}
        {loading && <Loader content='Loading...' active />}
      </Segment>
    </div>
  );
}
