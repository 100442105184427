import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Message, Ref } from "semantic-ui-react";
import {
  changeSelectedSpendingAccountThunk,
  setSpendingTxSplitsThunk,
} from "./Reducer/spendingThunks";
// TODO: We might not need a large-screen version of the spending page/list. Remove this comment if so.
import SpendingList from "./SpendingList/SpendingList";
import { openModal } from "../UI/Modals/Reducer/modalActions";
import {
  spendingMonthsToViewSelector,
  spendingSortedAllTxIdsSelector,
  loadedSpendingMonthIdsSortedSelector,
} from "./Selector/spendingSelectors";
import { spendinguiLoadMoreMonthsThunk } from "./Reducer/UI/spendinguiThunks";
import { spendinguiSetClosestTxIdToTodayAction } from "./Reducer/UI/spendinguiActions";
import * as Scroll from "react-scroll";
import { useAccounts } from "../../hooks/useAccounts";
import { getSortedAccountsSelector } from "../Accounts/Selector/accountsSelectors";
import AppPageMenu from "../UI/Menus/AppPageMenu";
import useBudget from "../../hooks/useBudget";
import useSpending from "../../hooks/useSpending";
import { getSplitsWithGoalIds } from "./Reducer/spendingHelpers";
import { getClosestTxIdToToday, getNewSplitsWithGoalIds } from "./Reducer/UI/spendinguiHelpers";
import { asyncClearError } from "../Async/Reducer/asyncActions";
import { toast } from "react-toastify";
import PageFooter from "../UI/PageFooter/PageFooter";

export default function SpendingPage() {
  const dispatch = useDispatch();
  const spending = useSelector((state) => state.spending);
  const { firstSpendingYmLoaded, closestTxIdToToday } = useSelector((state) => state.spendingui);
  const user = useSelector((state) => state.auth.currentUser);
  const { loading: asyncLoading, error } = useSelector((state) => state.async);
  const [loadingPage, setLoadingPage] = useState(true);
  const accounts = useSelector(getSortedAccountsSelector);
  // Loaded means the months in view.
  const spendingMonthsLoaded = useSelector(spendingMonthsToViewSelector);
  const { selectedAccountId, months: budgetMonths } = useSelector((state) => state.budget);
  const sortedMonthIds = useSelector(loadedSpendingMonthIdsSortedSelector);
  const contextRef = createRef();
  const sortedAllTxIds = useSelector(spendingSortedAllTxIdsSelector);
  const navigate = useNavigate();

  useAccounts(user.uid);

  const { loading: spendingLoading, amtsActual } = spending;

  useBudget(selectedAccountId);

  const loading = spendingLoading || asyncLoading || loadingPage;

  // First make sure we have a selected account ID that is ours.
  // Hooks have to come before logic, but if we have NO accounts, we'll redirect to the
  // accounts page below.
  useEffect(() => {
    async function doLoadingPage() {
      setLoadingPage(true);

      if (!loading) {
        // if user doesn't have that account any more (someone might have
        // removed them from a shared account or deleted that account),
        // select a different account.
        if (error) {
          const diffAccountId = accounts.find((accountF) => accountF.id !== selectedAccountId)?.id;
          // console.log("error", selectedAccountId, diffAccountId, _.map(accounts, "id"));
          if (diffAccountId) {
            dispatch(asyncClearError());
            // It's probably better to tell the user the account was deleted (or un-shared) before we just switch accounts.
            toast.warning(
              "The budget account is no longer available. Switching to a different budget account."
            );
            await dispatch(changeSelectedSpendingAccountThunk(user.uid, diffAccountId));
            return;
          } else {
            // No accounts? Go back to the accounts page to create one.
            toast.warning("The budget account is no longer available.");
            navigate("/app/accounts");
            return;
          }
        } else if (
          // !error &&
          !selectedAccountId ||
          !accounts.some((account) => account.id === selectedAccountId)
        ) {
          // console.log("No selected account", selectedAccountId, accounts[0]?.id, _.map(accounts, "id"));
          if (accounts.length > 0) {
            await dispatch(changeSelectedSpendingAccountThunk(user.uid, accounts[0].id));
            return;
          } else {
            // No accounts? Go back to the accounts page to create one.
            navigate("/app/accounts");
            return;
          }
        }
      }
      if (!firstSpendingYmLoaded) {
        dispatch(spendinguiLoadMoreMonthsThunk(true));
      }
      // This can be '' if we don't have any txs
      let newClosestTxIdToToday = getClosestTxIdToToday(spendingMonthsLoaded);
      if (closestTxIdToToday !== newClosestTxIdToToday) {
        dispatch(spendinguiSetClosestTxIdToTodayAction(newClosestTxIdToToday));
      }

      setLoadingPage(false);
    }
    doLoadingPage();
  }, [
    dispatch,
    loading,
    accounts,
    selectedAccountId,
    user.uid,
    firstSpendingYmLoaded,
    closestTxIdToToday,
    sortedAllTxIds,
    spendingMonthsLoaded,
    error,
    navigate,
  ]);

  useSpending(selectedAccountId);

  const selectedAccount = accounts.find((accountF) => accountF.id === selectedAccountId);

  function handleAccountSelected(accountId) {
    // Make sure they selected a different account
    async function doAccountSelected() {
      if (accountId !== selectedAccountId) {
        await dispatch(changeSelectedSpendingAccountThunk(user.uid, accountId));
      }
    }
    doAccountSelected();
  }

  function handleScrollToToday() {
    if (closestTxIdToToday) {
      Scroll.scroller.scrollTo(closestTxIdToToday, { offset: -120, delay: 100 });
    }
  }

  function handleEditSplits(ym, spendingTxId, budgetTxsNearDate) {
    const spendingTx = spendingMonthsLoaded[ym].txs[spendingTxId];
    const oldSplitsWithGoalIds = getSplitsWithGoalIds(spendingTx.splits, budgetMonths);

    // Open the splits modal:
    dispatch(
      openModal("SpendingItemSplitsModal", {
        title: "Assign Actual Amount to Budget",
        spendingTxToEdit: spendingTx,
        onSaveSplits: ({ splits: newSplits }) =>
          handleSplitsSaved(
            ym,
            spendingTxId,
            oldSplitsWithGoalIds,
            getNewSplitsWithGoalIds(newSplits, budgetMonths) // new splits
          ),
        // onDelete,
        budgetTxsNearDate,
      })
    );
  }

  // newSplit = {id, amount}
  async function handleAssignBudgetTx(ym, spendingTxId, oldSplits, newSplit) {
    const oldSplitsWithGoalIds = getSplitsWithGoalIds(oldSplits, budgetMonths);
    const newSplitsWithGoalIds = getNewSplitsWithGoalIds(newSplit, budgetMonths);

    // One split
    // Update budgetTx with spending tx's amount (call handleSplitsSaved with one split)
    await handleSplitsSaved(ym, spendingTxId, oldSplitsWithGoalIds, newSplitsWithGoalIds);
  }
  // oldSplits is required so we can disassociate the previous splits.
  // newSplits is the same object as newSplit (singular), but with more [budgetTxId]:amountActual pairs.
  async function handleSplitsSaved(ym, spendingTxId, oldSplitsWithGoalIds, newSplitsWithGoalIds) {
    await dispatch(
      setSpendingTxSplitsThunk(
        selectedAccountId,
        ym,
        spendingTxId,
        oldSplitsWithGoalIds,
        newSplitsWithGoalIds
      )
    );
  }

  function handleLoadMore(direction) {
    const forward = direction === "forward";
    dispatch(spendinguiLoadMoreMonthsThunk(forward));
  }

  return (
    <Ref innerRef={contextRef}>
      <div>
        <AppPageMenu
          loading={loading}
          ref={contextRef}
          title={selectedAccount?.name ?? "Looking for Account..."}
          activePageName='spending'
          showRepeatingTxsItem
          selectedAccountId={selectedAccountId}
          showScrollToToday
          onScrollToToday={handleScrollToToday}
          showAccountsMenu
          onAccountSelected={handleAccountSelected}
        />
        {!loading && sortedAllTxIds.length === 0 ? (
          <div style={{ paddingTop: "65px" }}>
            <Divider horizontal hidden />
            <Message>
              <Message.Header>
                No transactions found. Import some bank transactions.
              </Message.Header>
            </Message>
          </div>
        ) : (
          <div style={{ paddingTop: "65px" }}>
            <SpendingList
              loading={loading}
              sortedMonthIds={sortedMonthIds}
              spendingMonths={spendingMonthsLoaded}
              budgetMonths={budgetMonths}
              amtsActual={amtsActual}
              onLoadMore={handleLoadMore}
              onEditSplits={handleEditSplits}
              onAssignBudgetTx={handleAssignBudgetTx}
            />
          </div>
        )}
        <PageFooter />
      </div>
    </Ref>
  );
}
