import * as budgetdb from "../budgetdb";
import moment from "moment";
import cuid from "cuid";
import { toYyyyMm } from "../../util/helpers";

// Returns an object with {budget: {startBalance, txs}, repeating: {txs}}
export function createSampleMonth(nextDate) {
  let sampleMonth = {
    budget: {
      id: toYyyyMm(nextDate),
      startBalance: 0,
      isFullMonth: true,
      // Budget txs are an object with keys=id because the id is meaningful
      txs: {},
    },
    repeating: {
      // Repeat txs are an array because it's easier to work with since we
      // usually need all of them.
      txs: [],
    },
  };

  // Add txs to the month (each is a field in the month)
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 1);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Starting Balance")] = {
    isBalanceOverride: true,
    overrideBalance: 500.0,
    amount: 0.0,
    category: "Starting Balance",
    notes: "Sample month: Edit this item's Balance",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 2);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Pay")] = {
    isBalanceOverride: false,
    amount: 1500.0,
    category: "Pay",
    notes: "When you create a new item, specify if it should repeat or not",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Pay",
    amount: 1500.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 2,
    timeUnits: "weeks",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 3);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Mortgage")] = {
    isBalanceOverride: false,
    amount: -1200.0,
    category: "Mortgage",
    notes: "Rename this category to Rent if you want",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Mortgage",
    amount: -1200.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 3);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Savings")] = {
    isBalanceOverride: false,
    amount: -100.0,
    category: "Savings",
    notes:
      "Make this amount as big as possible without allowing your balance to go below a comfortable amount before your next paycheck",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Savings",
    amount: -100.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 4);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Entertainment")] = {
    isBalanceOverride: false,
    amount: -35.0,
    category: "Entertainment",
    notes: "Dinner out",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Entertainment",
    amount: -35.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "weeks",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 4);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Food")] = {
    isBalanceOverride: false,
    amount: -100.0,
    category: "Food",
    notes: "",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Food",
    amount: -100.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 2,
    timeUnits: "weeks",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 4);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Gas")] = {
    isBalanceOverride: false,
    amount: -30.0,
    category: "Gas",
    notes: "The Create New Month button will automatically create your repeating transactions.",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Gas",
    amount: -30.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 8);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Internet")] = {
    isBalanceOverride: false,
    amount: -27.0,
    category: "Internet",
    notes: "",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Internet",
    amount: -27.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 10);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Biking")] = {
    isBalanceOverride: false,
    amount: -50.0,
    category: "Biking",
    notes: "",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 10);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Books")] = {
    isBalanceOverride: false,
    amount: -40.0,
    category: "Books",
    notes: "",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 11);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Entertainment")] = {
    isBalanceOverride: false,
    amount: -35.0,
    category: "Entertainment",
    notes: "Going to the movies",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 11);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Food")] = {
    isBalanceOverride: false,
    amount: -75.0,
    category: "Food",
    notes: "",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 15);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Haircut")] = {
    isBalanceOverride: false,
    amount: -18.0,
    category: "Haircut",
    notes:
      "Adjust your Savings categories until the balance before your next paycheck is your minimum (comfortable) balance",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Haircut",
    amount: -18.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 5,
    timeUnits: "weeks",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 16);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Pay")] = {
    isBalanceOverride: false,
    amount: 1500.0,
    category: "Pay",
    notes:
      "Spreading budgeted items out over the month lets you know how much money you will have every day",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 17);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Savings")] = {
    isBalanceOverride: false,
    amount: -490.0,
    category: "Savings",
    notes:
      "This budget allows you to put money into savings (or make bigger credit card or loan payments) at the earliest possible date without wondering if you'll have enough for other bills",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 18);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Entertainment")] = {
    isBalanceOverride: false,
    amount: -35.0,
    category: "Entertainment",
    notes: "",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 18);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Food")] = {
    isBalanceOverride: false,
    amount: -100.0,
    category: "Food",
    notes: "",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 18);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Misc")] = {
    isBalanceOverride: false,
    amount: -50.0,
    category: "Misc",
    notes: "Everyday stuff",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Misc",
    amount: -50.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 18);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Phone")] = {
    isBalanceOverride: false,
    amount: -65.0,
    category: "Phone",
    notes: "",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Phone",
    amount: -65.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 18);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Utilities")] = {
    isBalanceOverride: false,
    amount: -40.0,
    category: "Utilities",
    notes: "",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Utilities",
    amount: -40.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 20);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "TV")] = {
    isBalanceOverride: false,
    amount: -45.0,
    category: "TV",
    notes: "Cable, Satellite, etc",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "TV",
    amount: -45.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 23);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Gas")] = {
    isBalanceOverride: false,
    amount: -25.0,
    category: "Gas",
    notes: "",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Gas",
    amount: -25.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 25);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Car")] = {
    isBalanceOverride: false,
    amount: -250.0,
    category: "Car Payment",
    notes: "This will be paid off in no time!",
    date: nextDate,
  };
  sampleMonth.repeating.txs.push({
    category: "Car",
    amount: -250.0,
    isTransfer: false,
    startDate: nextDate,
    frequency: 1,
    timeUnits: "months",
    id: cuid(),
  });
  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 25);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Entertainment")] = {
    isBalanceOverride: false,
    amount: -35.0,
    category: "Entertainment",
    notes: "",
    date: nextDate,
  };

  nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth(), 25);
  sampleMonth.budget.txs[budgetdb.makeId(nextDate, "Food")] = {
    isBalanceOverride: false,
    amount: -75.0,
    category: "Food",
    notes: "",
    date: nextDate,
  };

  return sampleMonth;
}

export function createRandomMonth(ymToCreate, numTxs, isFullMonth, isBalanceOverridesAllowed) {
  let sampleMonth = {
    id: ymToCreate,
    startBalance: 0,
    isFullMonth,
    txs: {},
  };

  const daysInYm = moment(ymToCreate, "YYYYMM").daysInMonth();

  for (let ix = 0; ix < numTxs; ix++) {
    const nextDate = moment(
      ymToCreate +
        Math.floor(Math.random() * daysInYm + 1)
          .toString()
          .padStart(2, "0"),
      "YYYYMMDD"
    ).toDate();

    const category = "Category " + Math.round(Math.random()*100000);
    const txId = budgetdb.makeId(nextDate, category);
    sampleMonth.txs[txId] = {
      isBalanceOverride: isBalanceOverridesAllowed && Math.random() < 0.06,
      overrideBalance: isBalanceOverridesAllowed ? 100 + Math.floor(10000 * Math.random()) : 0,
      amount:
        nextDate.getDate() === 2 || nextDate.getDate() === 17
          ? 1000 + 10 * Math.floor(200 * Math.random())
          : -10 * Math.floor(100 * Math.random()),
      category,
      notes: "",
      date: nextDate,
    };
    if (Object.is(-0, sampleMonth.txs[txId].amount)) {
      sampleMonth.txs[txId].amount = 0;
    }
  }

  return sampleMonth;
}
