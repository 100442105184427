import React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

export function BudgetFeaturesMenu({ showRepeatTxsItem, showTxsItem, activeItem, accountId }) {
  // accountId is required for showRepeatTxsItem because we can get there from
  // the account details page (for any account). Other menu items use selectedAccountId.
  if (showRepeatTxsItem && !accountId) {
    throw new Error("Repeat txs menu item requires an account id.");
  }

  // This menu is different depending on which page you're on.
  return (
    <Menu vertical>
      <Menu.Item as={Link} to={"/app/budget"} active={activeItem === "budget"}>
        Budget
      </Menu.Item>
      {showRepeatTxsItem && (
        <Menu.Item as={Link} to={`/app/repeat/${accountId}`} active={activeItem === "repeat"}>
          Repeating Items
        </Menu.Item>
      )}
      {showTxsItem && (
      <Menu.Item as={Link} to={"/app/spending"} active={activeItem === "spending"}>
        Transactions
      </Menu.Item>
      )}
      <Menu.Item as={Link} to={"/app/goals"} active={activeItem === "goals"}>
        Goals
      </Menu.Item>
      <Menu.Item as={Link} to={"/app/accounts"} active={activeItem === "accounts"}>
        Accounts
      </Menu.Item>
    </Menu>
  );
}
