import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Item, Container } from "semantic-ui-react";
import { signOutUser } from "../../Auth/Reducer/authActions";
import LoadingComponent from "../../UI/LoadingComponent";

export default function DeletionPage() {
  const { loading, error } = useSelector((state) => state.async);
  const { authenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (authenticated) {
    dispatch(signOutUser());
    return;
  }
  if (error) {
    navigate('/error');
    return;
  }
  if (loading) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingComponent content='Loading...' />
      </div>
    );
  }

  return (
    <div style={{ paddingTop: "125px" }}>
      <Container basic size='large' text>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>Data Deletion Status</Item.Header>
              <Item.Description>
                <p>
                  Your data was not found. Thanks for using Sarah's Budget!
                </p>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Container>
    </div>
  );
}
