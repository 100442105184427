import React, { createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Message, Ref, Segment } from "semantic-ui-react";
import { showHiddenGoalsAction } from "./Reducer/goalsActions";
import { openModal } from "../UI/Modals/Reducer/modalActions";
import GoalsList from "./GoalsList/GoalsList";
import AppPageMenu from "../UI/Menus/AppPageMenu";
import { getSortedGoalsToShowSelector } from "./Selector/goalsSelectors";
import { useGoals } from "../../hooks/useGoals";
import { useNavigate } from "react-router-dom";
import { hideGoalForUserThunk } from "./Reducer/goalsThunks";

export default function GoalsPage() {
  const user = useSelector((state) => state.auth.currentUser);
  const { loading, error } = useSelector((state) => state.async);
  const { showHiddenGoals } = useSelector((state) => state.goals);
  const sortedGoalsToShow = useSelector(getSortedGoalsToShowSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contextRef = createRef();

  useGoals(user.uid);

  if (error) {
    navigate('/error');
    return;
  }

  async function handleHideUnhideClicked(goalId, hideTheGoal) {
    await dispatch(hideGoalForUserThunk(user.uid, goalId, hideTheGoal));
  }

  return (
    <Ref innerRef={contextRef}>
      <div>
        <AppPageMenu
          loading={loading}
          ref={contextRef}
          title="Goals"
          activePageName="goals"
          showHiddenGoalsButton
          showHiddenGoalsChecked={showHiddenGoals}
          onShowHiddenGoalsClicked={(checked) => dispatch(showHiddenGoalsAction(checked))}
          showAddButton
          addButtonText='New Goal'
          onAddButtonClicked={() => dispatch(openModal("AddGoalModal"))}
        />
        <div style={{ paddingTop: "65px" }}>
          {sortedGoalsToShow && sortedGoalsToShow.length > 0 ? (
            <Segment basic>
              <GoalsList goals={sortedGoalsToShow} onHideUnhideClicked={handleHideUnhideClicked} />
            </Segment>
          ) : (
            <Segment basic>
              <Divider horizontal hidden />
              <Message>
                <Message.Header>No goals found.</Message.Header>
                <p>
                  <br />
                  <Button onClick={() => dispatch(openModal("AddGoalModal"))}>Create a goal</Button>
                </p>
              </Message>
            </Segment>
          )}
        </div>
      </div>
    </Ref>
  );
}
