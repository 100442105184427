import { collection, getDocs, limit, orderBy, query, writeBatch } from "firebase/firestore";

export const deleteCollection = async (db, collectionPath, batchSize) => {
  const collectionRef = collection(db, collectionPath);
  const delQuery = query(collectionRef, orderBy("__name__"), limit(batchSize));

  await deleteQueryBatch(db, delQuery);
};

const deleteQueryBatch = async (db, query) => {
  const snapshot = await getDocs(query);

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    // When there are no documents left, we are done
    return;
  }

  // Delete documents in a batch
  const batch = writeBatch(db);
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  await deleteQueryBatch(db, query);
};

// https://firebase.google.com/docs/firestore/quotas#limits
export function makeFSId(fromString) {
  if (fromString.startsWith("__") && fromString.endsWith("__")) {
    fromString = fromString.slice(2, fromString.length - 2);
  }
  if (!fromString) {
    throw new Error("ID String is empty");
  }
  if (fromString === "." || fromString === "..") {
    throw new Error("ID String is invalid");
  }
  // This takes care of / and non-UTF-8 chars. It also replaces spaces with %20.
  // category can't have a dot in it or Firestore will create a sub-object.
  //   ^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$
  // It also can't match the above regex: https://stackoverflow.com/a/52850529
  fromString = fromString.replace(/\./g, "_");
  fromString = fromString.replace(/\*/g, "_");
  fromString = fromString.replace(/\?/g, "_");
  fromString = fromString.replace(/__/g, "_");
  fromString = fromString.replace(/__/g, "_");
  if (fromString.startsWith("_"))
  {
    fromString = fromString.slice(1);
  }
  return encodeURIComponent(fromString).slice(0, 200);
}
