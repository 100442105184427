import React, { createRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Ref, Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { openModal } from "../UI/Modals/Reducer/modalActions";
import AppPageMenu from "../UI/Menus/AppPageMenu";
// import WhySBIsAwesome from "./Promotion/WhySBIsAwesome";
import SBIsClosing from "./Promotion/SBIsClosing";
// import SignUpForSBCard from "./Promotion/SignUpForSBCard";
// import WhySBIsMoreAwesome from "./Promotion/WhySBIsMoreAwesome";
import PageFooter from "../UI/PageFooter/PageFooter";

export default function HomePage() {
  const auth = useSelector((state) => state.auth);
  const authenticated = auth.authenticated;
  const contextRef = createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(
    function () {
      if (authenticated) {
        navigate("/app/budget");
        return (null);
      }
    },
    [authenticated, navigate]
  );

  return (
    <div>
      <Ref innerRef={contextRef}>
        <div>
          {authenticated && (
            <>
              <AppPageMenu title="Enjoy the new website!   See what's new." titleLink='/whatsnew' ref={contextRef} />
            </>
          )}
            <div style={{marginTop: "80px", textAlign: "center"}}>
            <h1>Sarah's Budget is closing :(</h1>
              <SBIsClosing  />
              <div style={{ textAlign: "center", marginTop: "30px", paddingBottom: "50px" }}>
          {!authenticated ?
                <Button color='blue' content="Login or Register" 
                onClick={() => dispatch(openModal("LoginModal"))}
                />
                :
                <Button color='green' content="Go to your budget" onClick={() => navigate("/app/budget")} />
              }
              </div>
          </div>
          <PageFooter />
        </div>
      </Ref>
    </div>
  );
};
