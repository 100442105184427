import React, { createRef } from "react";
import { useSelector } from "react-redux";
import { Ref, Header, Segment } from "semantic-ui-react";
import AppPageMenu from "../UI/Menus/AppPageMenu";
// import SignUpForSBCard from "./Promotion/SignUpForSBCard";

export default function AboutUsPage() {
  const auth = useSelector((state) => state.auth);
  const authenticated = auth.authenticated;
  const contextRef = createRef();
  return (
    <div>
      <Ref innerRef={contextRef}>
        <div>
          <div>
            {authenticated && (
              <>
                <AppPageMenu title=' ' ref={contextRef} />
              </>
            )}
          </div>
          <div>
            <div
              className='ui vertical masthead center aligned segment basic'
              style={{ marginTop: "80px" }}>
              <Segment basic size='large'>
                <Header size='huge' textAlign='center'>
                  About Us
                </Header>
              </Segment>
            </div>
            <div className='ui vertical center aligned segment basic'>
              <div style={{ maxWidth: "700px", margin: "auto", textAlign: "left" }}>
                <h1>A cashflow manager to plan for life</h1>
                <blockquote className='blockquote text-center'>
                  <p className='mb-0'>"Failing to plan is planning to fail."</p>
                  <footer className='blockquote-footer'>Alan Lakein</footer>
                </blockquote>
                <p>
                  When you were in junior high, you probably learned basic personal finance like how
                  to balance a check book and make a simple monthly budget. Then when you started
                  out on your own, money was tight and you had to really pay attention to avoid
                  having to borrow from family or friends or running up the credit cards.
                </p>
                <p>
                  Nobody prepared you for tracking tons of regular expenses like rent, utilities,
                  gas, phone, and food, as well as infrequent expenses like insurance, tuition, your
                  Amazon Prime subscription, and car registration, as well as significant one-time
                  expenses like buying or refinancing a house, having a baby, medical bills, or
                  going on vacation. On top of that, you'd like to pay off debt and then build up
                  savings. It's too much to keep track of in your head - You need a plan.
                </p>
                <h3>Our story</h3>
                <p>
                  It started out as a tool to help plan my cashflow in college. One semester I
                  barely had enough gas money to drive home from Michigan to Colorado. It was $90
                  for gas, $40 for a motel, and I had $140 in my account.
                </p>
                <p>
                  To prevent that from happening again, I made a spreadsheet to track expenses and
                  show me how much money I would have at the end of each semester. Later I added
                  macros (little programs) to the spreadsheet to automatically create a new month
                  and update the balances. Then I added a macro that downloaded my bank account
                  transactions so I could track actual spending vs. the plan. The cashflow planner
                  was proving to be very useful and I didn't have to worry about gas money any more.
                </p>
                <p>
                  A few years later, my future wife, Sarah, used the cashflow planner to pay off
                  some significant debt before we were married. She was able to see how much money
                  would be left over after every paycheck so she could put that extra toward the
                  payments right away and pay off the debt quicker.
                </p>
                <p>
                  The next evolution was when I wanted to learn web programming so I created the
                  first version of sarahsbudget.com. For about 10 years, only Sarah and I used it
                  and it was my learning project.
                </p>
                <p>
                  Then I decided to make a mobile app so the cashflow planner was an obvious choice.
                  I moved the database into the cloud and updated the website. Then I created a
                  mobile app that used the same cloud database. After almost 20 years in the making,
                  SarahsBudget.com was ready for everyone to use.
                </p>
                <h3>What about other budget tools?</h3>
                <ul>
                  <li>
                    Many other tools show your past spending and call it a budget. A budget should
                    let you control the future, not just see the past
                  </li>
                  <li>
                    Other budgets are completely separated from your account balances (and separated
                    from real life)
                  </li>
                  <li>
                    Other budgets don't make it easy to see how much money will be left in your
                    account after you've paid the bills and other expenses
                  </li>
                  <li>
                    Other budgets don't make it easy to budget anything other than generic monthly
                    repeating categories. What about big yearly expenses? What about significant
                    one-time expenses like vacations?
                  </li>
                  <li>
                    Many other budgets are tied to just you or just your computer. You should be
                    able to share your finances with your family or use your budget from anywhere.
                  </li>
                </ul>
                <p>
                  You've found what I think is the best budget tool anywhere. SarahsBudget.com will
                  help you make a plan to gain control over your finances; the rest is up to you.
                  Sign up for free and try it out.
                </p>
                <p>Take control of your cashflow!</p>
                {/* <div style={{ textAlign: "center" }}>
                  <p>
                    <SignUpForSBCard />
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Ref>
    </div>
  );
}
