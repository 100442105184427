import React from "react";
import { useDispatch } from "react-redux";
import { Button, Header, Modal } from "semantic-ui-react";
import ModalWrapper from "../UI/Modals/ModalWrapper";
import { closeModal } from "../UI/Modals/Reducer/modalActions";

export default function TestModal({ header, size = "mini" }) {
  const dispatch = useDispatch();
  const modalButtons = (
    <>
      <Button content='Save' onClick={() => dispatch(closeModal())} />
      <Button content='Cancel' onClick={() => dispatch(closeModal())} />
    </>
  );

  return (
    <ModalWrapper
      size={size} header={header} buttons={modalButtons}
      >
      <Modal.Content image>
        <Modal.Description>
          <Header>Hello world</Header>
          <p>We've found the following gravatar image associated with your e-mail address.</p>
          <p>Is it okay to use this photo?</p>
        </Modal.Description>
      </Modal.Content>
    </ModalWrapper>
  );
}
