import React from 'react'

function NotFound () {
  return (
    <div style={{marginTop: '100px', marginLeft: '50px'}}>
      <h1>Error 404 not found!</h1>
    </div>
  )
}

export default NotFound
