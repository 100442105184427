import useFirestoreDoc from "./useFirestoreDoc";
import { listenToSelectedAccountAction } from "../components/Accounts/Reducer/accountsActions";
import { useDispatch } from "react-redux";
import * as accountsdb from "../sbdb.client/accountsdb";
import _ from "lodash";

export default function useSelectedAccount(accountId) {
  const dispatch = useDispatch();

  useFirestoreDoc({
    // Full account info, with owners: { uid: { displayName, email } }
    query: () => accountsdb.getAccountRef(accountId),
    data: (accountInfoDoc) => {
      const ownersArray = _.map(accountInfoDoc.ownerIds, (ownerIdM) => ({
        id: ownerIdM,
        ...accountInfoDoc.owners[ownerIdM], // displayName, email
      }));
      const accountInfo = {
        id: accountId,
        name: accountInfoDoc.name,
        description: accountInfoDoc.description,
        owners: _.sortBy(ownersArray, "displayName"),
      };
      dispatch(listenToSelectedAccountAction(accountInfo));
    },
    deps: [dispatch, accountId],
  })
};
