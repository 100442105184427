import moment from "moment";
import { budgetuiShowMonthsAction } from "./budgetuiActions";
import { nextYyyyMm, prevYyyyMm, toYyyyMm } from "../../../../util/helpers";
import { firstBudgetDateSelector, lastBudgetDateSelector } from "../../Selector/metaSelectors";

export function getNextYmToLoad(
  isLoadForward,
  firstBudgetDate,
  lastBudgetDate,
  oldFirstYmLoaded,
  oldLastYmLoaded
) {
  // Empty budget, can't load anything.
  if (!firstBudgetDate || !lastBudgetDate) {
    return { fromYm: "", toYm: "" };
  }

  const firstBudgetYm = toYyyyMm(firstBudgetDate);
  const lastBudgetYm = toYyyyMm(lastBudgetDate);
  let fromYm = oldFirstYmLoaded;
  let toYm = oldLastYmLoaded;
  
  // Stretch the range back or forward from what was previously loaded, if necessary.
  if (!oldFirstYmLoaded || !oldLastYmLoaded) {
    const today = new Date();
    const thisYm = toYyyyMm(today);
    // Nothing loaded yet? Load this month if in budget, or last budget month.
    if (moment().isBetween(firstBudgetDate, lastBudgetDate)) {
      fromYm = thisYm;
      toYm = lastBudgetYm;
    } else {
      fromYm = lastBudgetYm;
      toYm = lastBudgetYm;
    }
  } else {
    if (isLoadForward) {
      fromYm = oldFirstYmLoaded;
      // Forward one month
      toYm = nextYyyyMm(oldLastYmLoaded);
    } else {
      // Backward one month
      fromYm = prevYyyyMm(oldFirstYmLoaded);
      toYm = oldLastYmLoaded;
    }

    // Check if the new bounds are in the budget (clip them)
    if (fromYm < firstBudgetYm) {
      fromYm = firstBudgetYm;
    }
    if (toYm > lastBudgetYm) {
      toYm = lastBudgetYm;
    }
}

  return { fromYm, toYm };
}

export function budgetuiLoadMoreMonthsThunk(isLoadForward) {
  return function (dispatch, getState) {
    const state = getState();
    const firstBudgetDate = firstBudgetDateSelector(state);
    const lastBudgetDate = lastBudgetDateSelector(state);
    const {
      firstBudgetYmLoaded: oldFirstYmLoaded, // Change name to oldFirstDateLoaded for this scope
      lastBudgetYmLoaded: oldLastYmLoaded,
    } = state.budgetui;

    // console.log(`budgetuiLoadMoreMonthsThunk ${isLoadForward}`);

    const { fromYm, toYm } = getNextYmToLoad(
      isLoadForward,
      firstBudgetDate,
      lastBudgetDate,
      oldFirstYmLoaded,
      oldLastYmLoaded
    );

    // This handles first time oldFirstYmLoaded is null too
    if (fromYm !== oldFirstYmLoaded || toYm !== oldLastYmLoaded) {
      // console.log(`budgetuiValidateLoadedMonths from ${fromYm} to ${toYm}`);
      dispatch(budgetuiShowMonthsAction(fromYm, toYm));
    }
  };
}
