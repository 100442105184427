import { getFirestoreDb } from "../app/configureFirebase";
import { dataFromSnapshot } from "./firestore/firestoreService";
import { getDocs, getDoc, where, collection, orderBy, limitToLast, updateDoc, doc, query } from "firebase/firestore";
import _ from "lodash";

export function getSpendingMonthsQuery(accountId) {
  if (!accountId) {
    return null;
  }
  const db = getFirestoreDb();
  return query(collection(db, `accounts/${accountId}/spending`),
    where("id", ">=", "201501"),
    where("id", "<=", "500001")); // <= 500001 so we don't listen to other docs
}

export function getMonthRef(accountId, yyyymm) {
  if (!accountId || !yyyymm) {
    return null;
  }
  const db = getFirestoreDb();
  return doc(db, `accounts/${accountId}/spending`, yyyymm);
}
// Txs are in 1-month blocks. It's up to the caller to put together a list of txs from
// the month data, which means figure out the balances.
export async function getMonth(accountId, yyyymm) {
  try {
    if (!accountId || !yyyymm) {
      return null;
    }
    return dataFromSnapshot(await getDoc(getMonthRef(accountId, yyyymm)));
  } catch (error) {
    console.log(`Error getting spending month ${accountId} ${yyyymm}`, error);
    throw error;
  }
}

// Returns YM for the last imported month.
export async function getLastYm(accountId) {
  if (!accountId) {
    return null;
  }
  const db = getFirestoreDb();
  const lastMonthQuery = query(
    collection(db, `accounts/${accountId}/spending`),
    where("id", ">=", "201501"),
    orderBy("id"),
    limitToLast(1)
  );
  const lastMonthSnapshot = await getDocs(lastMonthQuery);
  return lastMonthSnapshot.docs.length === 1 && lastMonthSnapshot.docs[0].exists()
    ? lastMonthSnapshot.docs[0].id
    : null;
}

// Call the one in spendingdb.txs, NOT this one. It updates the budget txs and goals too.
export async function updateSpendingTxSplits(accountId, spendingTxYm, spendingTxId, newSplits) {
  try {
    if (!accountId || !spendingTxYm || !spendingTxId || !newSplits) {
      throw new Error("Error assigning actual tx to the budget. Missing info.");
    }
    // Turn { [budgetTxId]: { goalId, amount} } into { [budgetTxId]: amount }
    const newSplitsNoGoalIds = _.reduce(
      Object.keys(newSplits),
      (res, budgetTxId) => {
        res[budgetTxId] = newSplits[budgetTxId].amount;
        return res;
      },
      {}
    );
    await updateDoc(getMonthRef(accountId, spendingTxYm), {
      [`txs.${spendingTxId}.splits`]: newSplitsNoGoalIds,
    });
  } catch (error) {
    console.log("Error updating actual tx budget amounts", error);
    throw error;
  }
}
