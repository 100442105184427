import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-less/semantic.less";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./app/App";
import ScrollToTop from "./util/ScrollToTop";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store/configureStore";
import { MediaContextProvider } from "./util/responsive";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

if (window.location.hostname === "localhost") {
  axios.defaults.baseURL = "http://localhost:5001/sb20-staging/us-central1";
  // axios.defaults.baseURL = "https://us-central1-sb20-staging.cloudfunctions.net";
} else if (window.location.hostname.includes("sb20-staging")) {
  axios.defaults.baseURL = "https://us-central1-sb20-staging.cloudfunctions.net";
} else {
  // TODO: Change this to our hosted URL (sarahsbudget.com)
  axios.defaults.baseURL = "https://us-central1-sb20-13dd3.cloudfunctions.net";
}
// console.log("axios.defaults.baseURL", axios.defaults.baseURL);

const store = configureStore();

const rootEl = document.getElementById("root");

function render() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <MediaContextProvider>
            <ScrollToTop />
            {/* <ReduxToastr position='bottom-right' transitionIn='fadeIn' transitionOut='fadeOut' /> */}
            <App />
          </MediaContextProvider>
        </Router>
      </Provider>
    </React.StrictMode>,
    rootEl
  );
}

if (module.hot) {
  module.hot.accept("./app/App", function () {
    setTimeout(render);
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
