import { createSelector } from "reselect";
import { toYyyyMm } from "../../../../util/helpers";
import { firstSpendingDateSelector, lastSpendingDateSelector } from "../../Selector/metaSelectors";

const getFirstSpendingYmLoaded = (state) => state.spendingui.firstSpendingYmLoaded;
const getLastSpendingYmLoaded = (state) => state.spendingui.lastSpendingYmLoaded;

export const moreToLoadBackwardSelector = createSelector(
  [getFirstSpendingYmLoaded, firstSpendingDateSelector],
  (fromYm, firstSpendingDate) => {
    const firstSpendingYm = toYyyyMm(firstSpendingDate);
    // console.log(`moreToLoadBACKSel: fromYm ${fromYm}, firstYm ${firstSpendingYm}`);
    return firstSpendingYm && fromYm > firstSpendingYm;
  }
);
export const moreToLoadForwardSelector = createSelector(
  [getLastSpendingYmLoaded, lastSpendingDateSelector],
  (toYm, lastSpendingDate) => {
    const lastSpendingYm = toYyyyMm(lastSpendingDate);
    // console.log(`moreToLoadFWDSel: toYm ${toYm}, lastYm ${lastSpendingYm}`);
    return lastSpendingDate && toYm < lastSpendingYm;
  }
);


// TODO: if spending.sortedAllTxIds.filter(>fromMonth <toMonth).length > 500?, remove a month from either end.
