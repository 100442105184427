import * as accountsdb from "../../../sbdb.client/accountsdb";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../Async/Reducer/asyncActions";
import { accountCreatedAction, listenToAccountsAction } from "./accountsActions";

export function createAccountThunk(
  uid,
  userDisplayName,
  email,
  accountName,
  description,
  makeSampleMonth
) {
  return async function (dispatch, getState) {
    try {
      // ActionStart gives us a loading indicator
      dispatch(asyncActionStart());

      // We're listening to accounts so we don't need to update redux store here.
      const newAccountId = await accountsdb.createAccount(
        uid,
        userDisplayName,
        email,
        accountName,
        description,
        makeSampleMonth
      );
      // We need to add the first account to our redux store sooner so the budget page will
      // use it and won't redirect back to the account page. Otherwise the budget page sees
      // 0 accounts and redirects back to the accounts page too fast.
      if (getState().accounts && getState().accounts.accounts.length === 0) {
        dispatch(listenToAccountsAction([{id: newAccountId, name: accountName, description}]));
      }
      dispatch(accountCreatedAction(newAccountId));

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function updateAccountThunk(accountId, newValues) {
  return async function (dispatch) {
    try {
      // ActionStart gives us a loading indicator
      dispatch(asyncActionStart());

      // We're listening to accounts so we don't need to update redux store here.
      await accountsdb.updateAccount(accountId, newValues);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function addUserToAccountThunk(newUserId, email, displayName, accountId) {
  return async function (dispatch) {
    try {
      dispatch(asyncActionStart());

      // We're listening to accounts so we don't need to update redux store here.
      await accountsdb.addUserToAccount(newUserId, email, displayName, accountId);

      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(`Error updating account: ${accountId}`, error);
      dispatch(asyncActionError(error));
    }
  };
}

export function removeUserFromAccountThunk(userId, accountId) {
  return async function (dispatch) {
    try {
      dispatch(asyncActionStart());

      // Do this last because after this, the user won't have permission to update
      // any account docs.
      // We're listening to accounts so we don't need to update redux store here.
      // removeUserFromAccount will not let us remove the last owner. Call
      // deleteAccount instead in that case.
      await accountsdb.removeUserFromAccount(userId, accountId);

      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(`Error updating account: ${accountId}`, error);
      dispatch(asyncActionError(error));
    }
  };
}

// delete account for all users
export function deleteAccountThunk(accountId) {
  return async function (dispatch) {
    try {
      dispatch(asyncActionStart());

      await accountsdb.deleteAccount(accountId);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}
