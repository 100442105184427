import { GOALS_LOADED, LISTEN_TO_SELECTED_GOAL, SHOW_HIDDEN_GOALS, SET_HIDDEN_GOAL_IDS, CLEAR_GOALS } from "./goalsConstants";

// NOTE: Use Async start/finish/error.

export function listenToGoalsAction(goals) {
  return {
    type: GOALS_LOADED,
    payload: {
      goals,
    },
  };
}

export function listenToSelectedGoalAction(selectedGoal) {
  return {
    type: LISTEN_TO_SELECTED_GOAL,
    payload: {
      selectedGoal,
    },
  };
}

export function showHiddenGoalsAction(showHiddenGoals) {
  return {
    type: SHOW_HIDDEN_GOALS,
    payload: {
      showHiddenGoals,
    },
  };
}

export function setHiddenGoalIdsAction(hiddenGoalIds) {
  return {
    type: SET_HIDDEN_GOAL_IDS,
    payload: {
      hiddenGoalIds,
    }
  }
}

export function clearGoalsAction() {
  return {
    type: CLEAR_GOALS,
  }
}