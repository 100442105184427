import React from "react";
import { useSelector } from 'react-redux';
import TestModal from "../../TestArea/TestModal";
import LoginModal from "../../Auth/Login/LoginModal";
import AddAccountModal from "../../Accounts/AddAccountModal/AddAccountModal";
import AddGoalModal from "../../Goals/AddGoalModal/AddGoalModal";
import BudgetItemDetailsModal from "../../Budget/BudgetItemDetails/BudgetItemDetailsModal";
import RepeatTxDetailsModal from "../../RepeatTxs/RepeatTxDetails/RepeatTxDetailsModal";
import SpendingItemSplitsModal from "../../Spending/SpendingItemSplits/SpendingItemSplitsModal";

export default function ModalManager() {
  const modalLookup = {
    TestModal,
    LoginModal,
    AddAccountModal,
    AddGoalModal,
    BudgetItemDetailsModal,
    RepeatTxDetailsModal,
    SpendingItemSplitsModal,
  };

  const currentModal = useSelector((state) => state.modals);
  let renderedModal;
  if (currentModal?.modalType) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
