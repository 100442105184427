import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

// BE SURE TO PUT ME IN A DIMMER.DIMMABLE!
export default function LoadingComponent({inverted = true, content = 'Loading...'}) {
  return (
    <Dimmer inverted={inverted} active={true} page>
      <Loader content={content}/>
    </Dimmer>
  )
}
