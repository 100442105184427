import React from "react";
import { useSelector } from "react-redux";
import { Menu, Container, Label } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import SignedOutMenu from "./SignedOutMenu";
import SignedInMenu from "./SignedInMenu";
import { Media } from "../../../util/responsive";

//TODO: const NAVBAR_COLOR = "#2196F3";

export default function NavBar() {
  // console.log("NavBar");
  const { authenticated, loggingIn } = useSelector((state) => state.auth);

  // AppPageMenu is at z-index 50 so we have to be 1 above it so SignedInMenu will pop up over it.
  // Otherwise you can't see the "logout" menu item.
  return (
    <Menu fixed='top' style={{ zIndex: "51" }} color='yellow' inverted>
      <Container>
        <Menu.Item to='/' header>
          <Link to='/' style={{marginTop: '3px', marginRight: '10px'}}>
            <img src='/assets/images/MoneyAppIcon.png' alt='logo' height='45px' />
          </Link>
          <Link to='/'>
            {" "}
            <Label as={Media} greaterThanOrEqual='tablet' size='huge' color='grey' basic >
              Sarah's Budget
            </Label>
          </Link>
        </Menu.Item>

        {/* On mobile, put these into a drop-down menu. */}
        <Menu.Item as={NavLink} to='/aboutus' header>
          About Us
        </Menu.Item>
        {/* <Menu.Item as={NavLink} to="/blog" header>
          Blog
        </Menu.Item> */}
        {/* <Menu.Item as={NavLink} to="/donate" name="Donate" position="right" /> */}
        {/* <Menu.Item as={NavLink} to="/test" name="Test" /> */}

        {authenticated ? <SignedInMenu /> : <SignedOutMenu loading={loggingIn} />}
      </Container>
    </Menu>
  );
}
