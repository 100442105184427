import React from "react";
import { Modal, Divider } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import LoginForm from "./LoginForm";
import { closeModal } from "../../UI/Modals/Reducer/modalActions";
import { useNavigate } from "react-router-dom";

export default function LoginModal() {
  const dispatch = useDispatch();
  // Grab "open" from the store here rather than making us pass it down
  // from ModalManager -> YourModal -> ModalWrapper.
  const open = useSelector(state => state.modals?.open || false);
  const navigate = useNavigate();

  function handleClose() {
    navigate(-1);
    dispatch(closeModal())
  }

  return (
    <Modal size='mini' open={open} onClose={handleClose}>
      <Modal.Header>Login to Sarah's Budget</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <LoginForm />
          <br />
          <Divider horizontal>Not accepting new users</Divider>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
