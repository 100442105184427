import _ from "lodash";
import { BUDGET_UI_SHOW_MONTHS, BUDGET_UI_SET_CLOSEST_TXID_TO_TODAY, CLEAR_BUDGET_UI } from "./budgetuiConstants";

const initialState = {
  firstBudgetYmLoaded: "",
  lastBudgetYmLoaded: "",
  closestTxIdToToday: "",
};

export default function budgetReducer(state = initialState, { type, payload }) {
  switch (type) {
    case BUDGET_UI_SHOW_MONTHS:
      if (
        state.firstBudgetYmLoaded !== payload.firstBudgetYmLoaded ||
        state.lastBudgetYmLoaded !== payload.lastBudgetYmLoaded
      ) {
        return {
          ...state,
          firstBudgetYmLoaded: payload.firstBudgetYmLoaded,
          lastBudgetYmLoaded: payload.lastBudgetYmLoaded,
        };
      }
      break;

    case BUDGET_UI_SET_CLOSEST_TXID_TO_TODAY:
      if (state.closestTxIdToToday !== payload.closestTxIdToToday) {
        return {
          ...state,
          closestTxIdToToday: payload.closestTxIdToToday,
        };
      }
      break;

    case CLEAR_BUDGET_UI:
      return _.cloneDeep(initialState);

    default:
      // No-op, return original state below.
      break;
  }
  return state;
}
