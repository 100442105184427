import {
  repeatAsyncError,
  repeatAsyncFinish,
  repeatAsyncStart,
} from "./repeatTxsActions";
import * as repeatdb from "../../../sbdb.client/repeatdb";

export function updateRepeatTxThunk(accountId, oldTx, newTx) {
  return async function (dispatch) {
    try {
      dispatch(repeatAsyncStart());

      await repeatdb.updateRepeatTx(accountId, oldTx, newTx);

      dispatch(repeatAsyncFinish());
    } catch (error) {
      dispatch(repeatAsyncError(error));
    }
  }
}
export function deleteRepeatTxThunk(accountId, oldTx) {
  return async function (dispatch) {
    try {
      dispatch(repeatAsyncStart());

      await repeatdb.deleteRepeatTx(accountId, oldTx);

      dispatch(repeatAsyncFinish());
    } catch (error) {
      dispatch(repeatAsyncError(error));
    }
  };
}

