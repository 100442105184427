import React, { useState } from "react";
import { format } from "date-fns";
import { Grid, Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";
import { getBudgetTxSelectOptions } from "../../Reducer/UI/spendinguiHelpers";

// TODO: Colors for positive/negative amounts?

// 2-lines, narrower, for mobile devices
// rowColor is background (alternating row/month colors)
// onEditSplits: (ym, spendingTxId, budgetTxsNearDate)
// onAssignBudgetTx: (ym, spendingTxId, oldSplits, newSplit)
export default function SpendingListItem({
  ym,
  txId,
  tx,
  rowColor,
  budgetTxsNearDate,
  // onEditSplits,
  onAssignBudgetTx,
}) {
  const noneOption = [{ key: 0, text: "[None]", value: 0 }];
  const initialAssocBudgetTxId =
    tx.splits && Object.keys(tx.splits).length > 0 ? Object.keys(tx.splits)[0] : 0;
  const [assocBudgetTxId, setAssocBudgetTxId] = useState(initialAssocBudgetTxId);
  const { closestTxIdToToday } = useSelector((state) => state.spendingui);
  // TODO: if multiple splits, show "<multiple> MMM DD, YYYY Category -Amt.00..." and no other options (NOT a Select component, just a label?).
  // if one split or not assigned, show "[None]" with other options
  // const noneOption = [{ key: 0, text: "[None]", value: null }];
  // const budgetTxOptions = noneOption.concat(getBudgetTxSelectOptions(budgetTxsNearDate));

  // react-scroll uses Element to scroll to this specific item.
  const dateWithNameForScrolling =
    txId !== closestTxIdToToday ? (
      format(tx.date, "MMM d")
    ) : (
      <Element name={txId}>{format(tx.date, "MMM d")}</Element>
    );

  const budgetTxSelectOptions = noneOption.concat(getBudgetTxSelectOptions(budgetTxsNearDate));

  return (
    <Grid className='compact'>
      <Grid.Row color={rowColor}>
        <Grid.Column width={2}>{dateWithNameForScrolling}</Grid.Column>
        <Grid.Column width={8}>{tx.payee}</Grid.Column>
        <Grid.Column width={3} textAlign='right'>
          {tx.amount.toFixed(2)}
        </Grid.Column>
      </Grid.Row>
      {budgetTxsNearDate && budgetTxsNearDate.length > 0 && (
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={11}>
            <Dropdown
              selection
              value={assocBudgetTxId}
              options={budgetTxSelectOptions}
              onChange={(e, { value }) => {
                setAssocBudgetTxId(value);
                onAssignBudgetTx(ym, txId, tx.splits, [
                  {
                    id: value,
                    amount: tx.amount,
                  },
                ]);
              }}
            />
            {/* TODO: <Button icon='tasks' content='split' onClick={() => onEditSplits(ym, txId, budgetTxsNearDate)} /> */}
          </Grid.Column>
          <Grid.Column width={3}></Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
}
