import React from "react";
import { Button, Label } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../UI/Modals/Reducer/modalActions";
import ModalWrapper from "../../UI/Modals/ModalWrapper";
import MyTextInput from "../../UI/Form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyCheckboxInput from "../../UI/Form/MyCheckboxInput";
import { createAccountThunk } from "../Reducer/accountsThunks";
import { useNavigate } from "react-router-dom";

export default function AddAccountModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid, displayName: userDisplayName, email } = useSelector(state => state.auth.currentUser) || {};

  return (
    <ModalWrapper size='mini' header='New Account'>
      <Formik
        initialValues={{ name: "", description: "", makeSampleMonth: true }}
        validationSchema={Yup.object({
          name: Yup.string().required('Please enter an account name'),
        })}
        onSubmit={async ({name: accountName, description, makeSampleMonth}, { setSubmitting, setErrors }) => {
          try {
            // createAccountThunk automatically selects the new account. We're assuming that
            // if you create an account, you want to use it next.
            // Ignore VS Code's warning about await - it absolutely makes a difference because it waits for
            // the thunk to finish before moving on and this is critical here.
            await dispatch(createAccountThunk(uid, userDisplayName, email, accountName, description, makeSampleMonth));
            setSubmitting(false);
            dispatch(closeModal());

            navigate("/app/budget");
          }
          catch (error) {
            setErrors({ auth: "Error creating the account." });
            setSubmitting(false);
          }
        }}>
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='name' placeholder='Account Name' />
            <MyTextInput name='description' placeholder='Description' />
            <MyCheckboxInput name='makeSampleMonth' label='Create a sample month' />
            {errors.auth && (
              <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
            )}
            {/* type=button fixes a warning: https://stackoverflow.com/a/47848508 */}
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type="button"
              size='large'
              negative
              content='Cancel'
              onClick={() => dispatch(closeModal())}
            />
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              size='large'
              positive
              content='Create the Account'
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
