import { MODAL_CLOSE, MODAL_OPEN } from "./modalConstants";

const initialState = {
  modalType: null,
  modalProps: {},
  open: false,
};

export default function modalReducer(state = initialState, { type, payload }) {
  switch (type) {
    case MODAL_OPEN:
      const { modalType, modalProps } = payload;
      return {
        modalType,
        modalProps,
        // ModalWrapper grabs "open" via a selector.
        open: true
      };
    case MODAL_CLOSE:
      // Keep modalType because semantic-ui reports a warning if you remove the modal
      // after it's been rendered (it tries to update the modal component after it's
      // been unmounted, which is a memory leak). So instead of returning null, set
      // open=false.
      return {
        modalType: state.modalType,
        modalProps: {
          ...state.modalProps,
        },
        // ModalWrapper grabs "open" via a selector.
        open: false
      };
    default:
      return state;
  }
}
