import {
  BUDGET_ASYNC_START,
  BUDGET_ASYNC_FINISH,
  BUDGET_ASYNC_ERROR,
  CHANGE_SELECTED_ACCOUNT,
  CLEAR_BUDGET_TXS,
  BUDGET_MONTHS_LOADED,
  CLEAR_BUDGET,
} from "./budgetConstants";
import _ from "lodash";

const initialState = {
  // Below is UI context
  loading: false,
  error: null,
  selectedAccountId: "",
  months: {
    // 'yyyyMM': {
    //   id: 'yyyyMM',
    //   startBalance: 0,
    //   txs: {
    //     // txid: {fields}
    //   },
    //   // Calculated fields for the UI (not saved in the DB)
    //   sortedTxIds: [],
    //   incomeBudgeted: 0,
    //   expenseBudgeted: 0,
    //   incomeActual: 0,
    //   expenseActual: 0,
    // },
  },
};

export default function budgetReducer(state = initialState, { type, payload }) {
  switch (type) {
    case BUDGET_ASYNC_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BUDGET_ASYNC_FINISH:
      return {
        ...state,
        loading: false,
      };
    case BUDGET_ASYNC_ERROR:
      return {
        ...state,
        loading: false,
        error: _.cloneDeep(payload.error),
      };
    case CHANGE_SELECTED_ACCOUNT:
      if (state.selectedAccountId !== payload.accountId) {
        return {
          ...state,
          selectedAccountId: payload.accountId,
        };
      }
      // else
      return state;

    case CLEAR_BUDGET:
      return _.cloneDeep(initialState);

    case CLEAR_BUDGET_TXS:
      return {
        ...state,
        months: {},
      };
    case BUDGET_MONTHS_LOADED:
      return {
        ...state,
        months: _.cloneDeep(payload.months),
      };

    default: {
      return state;
    }
  }
}
