import { spendinguiShowMonthsAction } from "./spendinguiActions";
import { nextYyyyMm, prevYyyyMm, toYyyyMm } from "../../../../util/helpers";
import { firstSpendingDateSelector, lastSpendingDateSelector } from "../../Selector/metaSelectors";

export function getNextYmToLoad(
  isLoadForward,
  firstSpendingDate,
  lastSpendingDate,
  oldFirstYmLoaded,
  oldLastYmLoaded
) {
  // Empty spending, can't load anything.
  if (!firstSpendingDate || !lastSpendingDate) {
    return { fromYm: "", toYm: "" };
  }

  const firstSpendingYm = toYyyyMm(firstSpendingDate);
  const lastSpendingYm = toYyyyMm(lastSpendingDate);
  let fromYm = oldFirstYmLoaded;
  let toYm = oldLastYmLoaded;
  
  // Stretch the range back or forward from what was previously loaded, if necessary.
  if (!oldFirstYmLoaded || !oldLastYmLoaded) {
    // Nothing loaded yet? Load the last imported month.
    fromYm = lastSpendingYm;
    toYm = lastSpendingYm;
  } else {
    if (isLoadForward) {
      fromYm = oldFirstYmLoaded;
      // Forward one month
      toYm = nextYyyyMm(oldLastYmLoaded);
    } else {
      // Backward one month
      fromYm = prevYyyyMm(oldFirstYmLoaded);
      toYm = oldLastYmLoaded;
    }

    // Check if the new bounds are in the spending (clip them)
    if (fromYm < firstSpendingYm) {
      fromYm = firstSpendingYm;
    }
    if (toYm > lastSpendingYm) {
      toYm = lastSpendingYm;
    }
}

  return { fromYm, toYm };
}

export function spendinguiLoadMoreMonthsThunk(isLoadForward) {
  return function (dispatch, getState) {
    const state = getState();
    const firstSpendingDate = firstSpendingDateSelector(state);
    const lastSpendingDate = lastSpendingDateSelector(state);
    const {
      firstSpendingYmLoaded: oldFirstYmLoaded, // Change name to oldFirstDateLoaded for this scope
      lastSpendingYmLoaded: oldLastYmLoaded,
    } = state.spendingui;

    // console.log(`spendinguiLoadMoreMonthsThunk ${isLoadForward}`);

    const { fromYm, toYm } = getNextYmToLoad(
      isLoadForward,
      firstSpendingDate,
      lastSpendingDate,
      oldFirstYmLoaded,
      oldLastYmLoaded
    );

    // This handles first time oldFirstYmLoaded is null too
    if (fromYm !== oldFirstYmLoaded || toYm !== oldLastYmLoaded) {
      // console.log(`spendinguiValidateLoadedMonths from ${fromYm} to ${toYm}`);
      dispatch(spendinguiShowMonthsAction(fromYm, toYm));
    }
  };
}
