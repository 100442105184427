import React from "react";
import { Button, Label } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../UI/Modals/Reducer/modalActions";
import ModalWrapper from "../../UI/Modals/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { createGoalThunk } from "../Reducer/goalsThunks";
import MyTextInput from "../../UI/Form/MyTextInput";
import MyDateInput from "../../UI/Form/MyDateInput";

export default function AddGoalModal() {
  const dispatch = useDispatch();
  const { uid, displayName: userDisplayName, email } = useSelector(state => state.auth.currentUser);

  return (
    <ModalWrapper size='mini' header='New Goal'>
      <Formik
        initialValues={{ name: "", targetAmount: "", targetDate: new Date() }}
        validationSchema={Yup.object({
          name: Yup.string().required('Please enter a goal name'),
          targetDate: Yup.date('Please enter a valid date').required('Please enter a target date'),
          targetAmount: Yup.number('Please enter a valid amount').required('Please enter a target amount'),
          })}
        onSubmit={async ({name: goalName, targetDate, targetAmount}, { setSubmitting, setErrors }) => {
          try {
            await dispatch(createGoalThunk(uid, userDisplayName, email, goalName, targetDate, targetAmount));
            setSubmitting(false);
            dispatch(closeModal());
          }
          catch (error) {
            setErrors({ auth: "Error creating the goal." });
            setSubmitting(false);
          }
        }}>
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='name' placeholder='Goal Name' />
            <MyDateInput name='targetDate' placeholder='Target Date' />
            <MyTextInput name='targetAmount' placeholder='+ for saving, - for spending' type='number' label='Target Amount' />
            {errors.auth && (
              <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
            )}
            {/* type=button fixes a warning: https://stackoverflow.com/a/47848508 */}
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type="button"
              size='large'
              negative
              content='Cancel'
              onClick={() => dispatch(closeModal())}
            />
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              size='large'
              positive
              content='Create the Goal'
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
