import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Icon } from "semantic-ui-react";
import { socialLogin } from "../../../sbdb.client/firestore/firebaseService";
import { closeModal } from "../../UI/Modals/Reducer/modalActions";
import { useMediaQuery } from 'react-responsive'
import { beginLoginAction, signOutUser } from "../Reducer/authActions";

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  async function handleSocialLogin(provider) {
    dispatch(closeModal());
    try {
      dispatch(beginLoginAction());
      await socialLogin(provider, isMobile);
    }
    catch (error) {
      toast.error(error.message);
      // This clears the store.auth.loggingIn flag so the button is enabled again.
      dispatch(signOutUser());
      navigate('/');
    }
  }

  return (
    <>
      <Button onClick={async () => await handleSocialLogin("google")} type='button' fluid color='google plus'>
        <Icon name='google plus' />
        Login with Google
      </Button>
    </>
  );
}
