import React from "react";
import { Menu, Image, Dropdown } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { signOutFirebase } from "../../../sbdb.client/firestore/firebaseService"
import { toast } from 'react-toastify';

export default function SignedInMenu() {
  // console.log("Signed in menu");
  const {currentUserProfile} = useSelector(state => state.profile);
  const navigate = useNavigate();

  async function handleSignOut() {
    try {
      navigate('/');
      await signOutFirebase();
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <Menu.Item position='right'>
        <Image avatar spaced='right' src={currentUserProfile?.photoURL || "/assets/images/user.jpg"} />
        <Dropdown pointing='top right' text={currentUserProfile?.displayName}>
          <Dropdown.Menu>
            {/* <Dropdown.Item as={Link} to={`/profile/${currentUserProfile?.id}`} text='My Profile' icon='user' /> */}
            <Dropdown.Item onClick={handleSignOut} text='Sign Out' icon='power' />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  );
};

