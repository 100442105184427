import * as mystuffdb from "../../../sbdb.client/mystuffdb";
import * as spendingtxs from "../../../sbdb.client/spendingdb.txs";
import { selectedAccountChangedAction } from "../../Budget/Reducer/budgetActions";
import {
  spendingAsyncError,
  spendingAsyncFinish,
  spendingAsyncStart,
  clearSpendingTxsAction,
} from "./spendingActions";
import { spendinguiShowMonthsAction } from "./UI/spendinguiActions";

// Update myStuff in the DB. We're listening for changes to myStuff so
// we don't need to update spending.selectedAccountId in the store
export function changeSelectedSpendingAccountThunk(userId, accountId) {
  return async function (dispatch) {
    try {
      // null accountId is valid if the user deletes all their accounts.
      // null userId is NOT okay (unauthorized).
      if (!userId) {
        return;
      }
      dispatch(spendingAsyncStart());

      dispatch(clearSpendingTxsAction());
      dispatch(spendinguiShowMonthsAction("", ""));
      await mystuffdb.setSelectedAccountId(userId, accountId);
      dispatch(selectedAccountChangedAction(accountId));

      dispatch(spendingAsyncFinish());
    } catch (error) {
      dispatch(spendingAsyncError(error));
    }
  };
}

export function setSpendingTxSplitsThunk(
  accountId,
  spendingTxYm,
  spendingTxId,
  oldSplitsWithGoalIds,
  newSplitsWithGoalIds
) {
  return async function (dispatch) {
    try {
      dispatch(spendingAsyncStart());

      await spendingtxs.updateSpendingTxSplits(
        accountId,
        spendingTxYm,
        spendingTxId,
        oldSplitsWithGoalIds,
        newSplitsWithGoalIds
      );

      dispatch(spendingAsyncFinish());
    } catch (error) {
      dispatch(spendingAsyncError(error));
    }
  };
}

// TODO: Should we let people delete actual txs? (try to make sure we don't re-import the deleted tx) Privacy reasons?
// We would have to remove the amount from the associated budget txs and their goals
/*
export function deleteSpendingTxThunk(accountId, tx) {
  return async function (dispatch) {
    try {
      dispatch(spendingAsyncStart());

      await spendingtxs.deleteSpendingTx(accountId, tx);

      dispatch(spendingAsyncFinish());
    } catch (error) {
      dispatch(spendingAsyncError(error));
    }
  };
}
*/
