import {
  SET_ACCOUNTS,
  LISTEN_TO_SELECTED_ACCOUNT,
  ACCOUNT_CREATED,
  CLEAR_ACCOUNT_CREATED,
  CLEAR_ACCOUNTS,
} from "./accountsConstants";
import _ from "lodash";

const initialState = {
  accounts: [], // { id, name, description }
  selectedAccount: null, // { id, name, description, owners: [ { id, displayName, email } ] }
  lastCreatedAccountId: null,
  // lastUpdateStarted,
  // lastUpdateFinished,
  // links: {
  //   [accountLinkId]: {
  //     bankId,
  //     bankName,
  //     bankAccountId,
  //     bankAccountName,
  //     bankAccountLast4,
  //     status: null,
  //     balance: null,
  //   }
  // }
};

export default function accountsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: _.cloneDeep(payload.accounts),
      };

    case CLEAR_ACCOUNTS:
      return _.cloneDeep(initialState);

    case LISTEN_TO_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: _.cloneDeep(payload.selectedAccount),
      };

    case ACCOUNT_CREATED:
      return {
        ...state,
        lastCreatedAccountId: payload.newAccountId
      };

    case CLEAR_ACCOUNT_CREATED:
      return {
        ...state,
        lastCreatedAccountId: null
      };
    default: {
      return state;
    }
  }
}
