import useFirestoreCollection from "./useFirestoreCollection";
import * as accountsdb from "../sbdb.client/accountsdb";
import { useDispatch } from "react-redux";
import { listenToAccountsAction } from "../components/Accounts/Reducer/accountsActions";
import _ from "lodash";

export function useAccounts(userId) {
  const dispatch = useDispatch();
  // budget accounts
  useFirestoreCollection({
    query: () => accountsdb.getAccountsQuery(userId),
    data: (accountEntities) => {
      // We don't need owners on any page except account sharing and that page uses selectedAccount.
      const accountDescs = _.map(accountEntities, (accountEntity) =>
        _.pick(accountEntity, ["id", "name", "description"])
      );
      dispatch(listenToAccountsAction(accountDescs));
    },
    deps: [dispatch, userId],
  });
}
