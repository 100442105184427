import { createSelector } from "reselect";
import { getBudgetMonths } from "./budgetSelectors";
import _ from "lodash";
import moment from "moment";
import { parseYyyyMm } from "../../../util/helpers";

export const firstBudgetDateSelector = createSelector(getBudgetMonths, (months) => {
  if (_.isEmpty(months)) {
    return null;
  }
  return parseYyyyMm(_.min(Object.keys(months)));
});
export const lastBudgetDateSelector = createSelector(getBudgetMonths, (months) => {
  if (_.isEmpty(months)) {
    return null;
  }
  return moment(_.max(Object.keys(months)), "YYYYMM")
    .endOf("month")
    .toDate();
});

// TODO: BudgetList should use this when doing "Create New Month" so we don't have to query DB.
// Otherwise, remove this
export const lastBudgetFullYmSelector = createSelector(getBudgetMonths, (months) => {
  const fullYms = _.reduce(
    Object.entries(months),
    (res, entry) => {
      // entry[1] is the month object value
      if (entry[1].isFullMonth) {
        // entry[0] is the id: YM
        res.push(entry[0]);
      }
      return res;
    },
    []
  );
  return _.max(fullYms) || null;
});
