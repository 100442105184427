import {
  REPEAT_ASYNC_START,
  REPEAT_ASYNC_FINISH,
  REPEAT_ASYNC_ERROR,
  REPEAT_TXS_LOADED,
  CLEAR_REPEAT
} from "./repeatTxsConstants";

export function repeatAsyncStart() {
  return { type: REPEAT_ASYNC_START };
}

export function repeatAsyncFinish() {
  return { type: REPEAT_ASYNC_FINISH };
}

export function repeatAsyncError(error) {
  console.log('error', error);
  return { type: REPEAT_ASYNC_ERROR,
    payload: {
      error
    }
 };
}

export function clearRepeatAction() {
  return {
    type: CLEAR_REPEAT,
  }
}

export function listenToRepeatTxsAction(sortedRepeatTxs) {
  return {
    type: REPEAT_TXS_LOADED,
    payload: {
      sortedRepeatTxs,
    },
  };
}
