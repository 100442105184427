import {
  TEST_ASYNC_START,
  TEST_ASYNC_FINISH,
  TEST_ASYNC_ERROR,
  INCREMENT_COUNTER,
  DECREMENT_COUNTER,
  COUNTER_ACTION_FINISHED,
  COUNTER_ACTION_STARTED,
} from "./testConstants";

const initialState = {
  data: 41,
  loading: false,
  error: null,
};

export default function activityReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TEST_ASYNC_START:
      return { ...state, loading: true, error: null };
    case TEST_ASYNC_FINISH:
      return { ...state, loading: false };
    case TEST_ASYNC_ERROR:
      return { ...state, loading: false, error: payload.error };
    case INCREMENT_COUNTER:
      return { ...state, data: state.data + 1 };
    case DECREMENT_COUNTER:
      return { ...state, data: state.data - 1 };
    case COUNTER_ACTION_STARTED:
      return { ...state, loading: true };
    case COUNTER_ACTION_FINISHED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
