import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Message, Ref } from "semantic-ui-react";
import { Media } from "../../util/responsive";
import {
  createBudgetTxThunk,
  createBudgetMonthThunk,
  updateBudgetTxThunk,
  changeSelectedBudgetAccountThunk,
  deleteBudgetTxThunk,
} from "./Reducer/budgetThunks";
// import BudgetListWithDrag from "./BudgetList/WithDrag/BudgetListWithDrag";
import BudgetListNoDrag from "./BudgetList/NoDrag/BudgetListNoDrag";
import { closeModal, openModal } from "../UI/Modals/Reducer/modalActions";
import { format } from "date-fns";
import useBudget from "../../hooks/useBudget";
import _ from "lodash";
import {
  budgetMonthsToViewSelector,
  budgetSortedAllTxIdsSelector,
  filteredSortedBudgetMonthIdsSelector,
} from "./Selector/budgetSelectors";
import { budgetuiLoadMoreMonthsThunk } from "./Reducer/UI/budgetuiThunks";
import {
  budgetuiSetClosestTxIdToTodayAction,
  budgetuiShowMonthsAction,
} from "./Reducer/UI/budgetuiActions";
import * as Scroll from "react-scroll";
import { nextYyyyMm, parseBudgetTxIdToYyyyMm, prevYyyyMm, toYyyyMm } from "../../util/helpers";
import { lastBudgetFullYmSelector } from "./Selector/metaSelectors";
import { useAccounts } from "../../hooks/useAccounts";
import { getSortedAccountsSelector } from "../Accounts/Selector/accountsSelectors";
import AppPageMenu from "../UI/Menus/AppPageMenu";
import { asyncClearError } from "../Async/Reducer/asyncActions";
import { toast } from "react-toastify";
import PageFooter from "../UI/PageFooter/PageFooter";
import { clearAccountCreatedAction } from "../Accounts/Reducer/accountsActions";
import LoadingComponent from "../UI/LoadingComponent";

export default function BudgetPage() {
  // console.log("Budget Page!");
  const dispatch = useDispatch();
  const budget = useSelector((state) => state.budget);
  const { firstBudgetYmLoaded, lastBudgetYmLoaded, closestTxIdToToday } = useSelector(
    (state) => state.budgetui
  );
  const user = useSelector((state) => state.auth.currentUser);
  const { loading: asyncLoading, error } = useSelector((state) => state.async);
  const [loadingPage, setLoadingPage] = useState(true);
  const accounts = useSelector(getSortedAccountsSelector);
  const { lastCreatedAccountId } = useSelector((state) => state.accounts);
  const months = useSelector(budgetMonthsToViewSelector);
  const sortedMonthIds = useSelector(filteredSortedBudgetMonthIdsSelector);
  const contextRef = createRef();
  const sortedAllTxIds = useSelector(budgetSortedAllTxIdsSelector);
  const lastBudgetFullYm = useSelector(lastBudgetFullYmSelector);
  const navigate = useNavigate();

  useAccounts(user.uid);

  const { loading: budgetLoading, selectedAccountId, amtsBudgeted, amtsActual } = budget;

  const loading = budgetLoading || asyncLoading || loadingPage;

  // console.log(
  //   "Budget Page:",
  //   "budgetLoading", budgetLoading,
  //   "asyncLoading", asyncLoading,
  //   "loadingPage", loadingPage,
  //   "selectedAccountId", selectedAccountId,
  //   "lastCreatedAccountId", lastCreatedAccountId,
  //   "accounts", accounts
  // );

  // First make sure we have a selected account ID that is ours.
  // Hooks have to come before logic, but if we have NO accounts, we'll redirect to the
  // accounts page below.
  useEffect(() => {
    async function doLoadingPage() {

      if (!loading) {
        // if user doesn't have that account any more (someone might have
        // removed them from a shared account or deleted that account),
        // select a different account.
        if (error) {
          const diffAccountId =
            lastCreatedAccountId ||
            accounts.find((accountF) => accountF.id !== selectedAccountId)?.id;
          // console.log("error", selectedAccountId, diffAccountId, _.map(accounts, "id"));
          if (diffAccountId) {
            dispatch(asyncClearError());
            // In case user is editing a tx, close that popup
            dispatch(closeModal());
            // It's probably better to tell the user the account was deleted (or un-shared) before we just switch accounts.
            if (!lastCreatedAccountId) {
              toast.warning(
                "The budget account is no longer available. Switching to a different budget account."
              );
            }
            dispatch(changeSelectedBudgetAccountThunk(user.uid, diffAccountId));
            if (lastCreatedAccountId) {
              dispatch(clearAccountCreatedAction());
            }
            return;
          } else {
            // No accounts? Go back to the accounts page to create one.
            // toast.warning("The budget account is no longer available.");
            // TODO: This can get into an infinite loop if we get here after creating the first
            // account because the accounts page redirected to budget and now we're redirecting back.
            // navigate("/app/accounts");
            return;
          }
        } else if (lastCreatedAccountId) {
          dispatch(changeSelectedBudgetAccountThunk(user.uid, lastCreatedAccountId));
          dispatch(clearAccountCreatedAction());
          return;
        }
        // if there's no selected account, select the first account.
        else if (
          // !error &&
          !selectedAccountId ||
          !accounts.some((account) => account.id === selectedAccountId)
        ) {
          // console.log("No selected account", selectedAccountId, accounts[0]?.id, _.map(accounts, "id"));
          if (accounts.length > 0) {
            dispatch(changeSelectedBudgetAccountThunk(user.uid, accounts[0].id));
            return;
          } else {
            // No accounts? Go back to the accounts page to create one.
            navigate("/app/accounts");
            return;
          }
        }

        // If we don't have an account, don't continue.
        if (lastCreatedAccountId || !selectedAccountId) {
          return;
        }
        if (!lastCreatedAccountId && !selectedAccountId && accounts.length === 0) {
          navigate("/app/accounts");
        }
        if (!firstBudgetYmLoaded) {
          dispatch(budgetuiLoadMoreMonthsThunk(true));
        }

        // This can be '' if we don't have any txs
        // Find first tx at that date
        const todayFirstBudgetId = format(new Date(), "yyyyMMdd");
        let newClosestTxIdToToday =
          sortedAllTxIds && sortedAllTxIds.length > 0
            ? sortedAllTxIds.find((txId) => txId >= todayFirstBudgetId) ||
              sortedAllTxIds[sortedAllTxIds.length - 1]
            : "";
        if (closestTxIdToToday !== newClosestTxIdToToday) {
          dispatch(budgetuiSetClosestTxIdToTodayAction(newClosestTxIdToToday));
        }
      }

      setLoadingPage(false);
    }
    doLoadingPage();
  }, [
    dispatch,
    navigate,
    loading,
    accounts,
    selectedAccountId,
    user.uid,
    firstBudgetYmLoaded,
    closestTxIdToToday,
    sortedAllTxIds,
    error,
    lastCreatedAccountId,
  ]);

  useBudget(selectedAccountId);

  const selectedAccount = accounts.find((accountF) => accountF.id === selectedAccountId);

  function handleAccountSelected(accountId) {
    async function doAccountSelected() {
      // Make sure they selected a different account.
      if (accountId !== selectedAccountId) {
        dispatch(changeSelectedBudgetAccountThunk(user.uid, accountId));
      }
    }
    doAccountSelected();
  }

  function handleScrollToToday() {
    if (closestTxIdToToday) {
      Scroll.scroller.scrollTo(closestTxIdToToday, { offset: -120, delay: 100 });
    }
  }

  function handleTxClicked(txId) {
    // Open the details modal:
    const ym = parseBudgetTxIdToYyyyMm(txId);
    dispatch(
      openModal("BudgetItemDetailsModal", {
        title: "Edit Budget Item",
        txToEdit: months[ym].txs[txId],
        onSaveBudgetTx: (values) => handleTxSaved(months[ym].txs[txId], values),
        onDelete: () => handleTxDeleted(months[ym].txs[txId]),
      })
    );
  }
  function handleTxSaved(oldTx, { frequency, timeUnits, ...newValues }) {
    async function doTxSaved() {
      const newFields = _.pick(newValues, [
        "date",
        "category",
        "isTransfer",
        "amount",
        "isDone",
        "isSavings",
        "isBalanceOverride",
        "overrideBalance",
        "notes",
        "goalId",
      ]);
      if (newFields.isTransfer) {
        newFields.category = newValues.tgtAccountId;
      }
      const newTx = oldTx ? Object.assign(_.cloneDeep(oldTx), newFields) : newFields;
      if ("balance" in newTx) {
        delete newTx.balance;
      }
      // Snapshot these now in case the new budget tx is created super quick before we check
      // if the new tx is in the loaded months.
      const firstBudgetYmBeforeCreateTx = firstBudgetYmLoaded;
      const lastBudgetYmBeforeCreateTx = lastBudgetYmLoaded;
      if (!oldTx) {
        // TODO: If it would be a duplicate tx (or xfer tgt is dup), UI should warn the user and ask to replace it.
        dispatch(createBudgetTxThunk(selectedAccountId, newTx, frequency, timeUnits));
      } else {
        dispatch(updateBudgetTxThunk(selectedAccountId, oldTx, newTx));
      }
      // If the new tx isn't in the loaded months, load that part of the budget now.
      if (!lastBudgetYmBeforeCreateTx || toYyyyMm(newTx.date) > lastBudgetYmBeforeCreateTx) {
        dispatch(budgetuiShowMonthsAction(firstBudgetYmLoaded, nextYyyyMm(lastBudgetYmLoaded)));
      } else if (!firstBudgetYmBeforeCreateTx || toYyyyMm(newTx.date) < firstBudgetYmBeforeCreateTx) {
        dispatch(budgetuiShowMonthsAction(prevYyyyMm(firstBudgetYmLoaded), lastBudgetYmLoaded));
      }
    }
    doTxSaved();
  }

  function handleTxDeleted(tx) {
    async function doTxDeleted() {
      // When it gets here from BudgetItemDetailsModal, the user has confirmed the delete
      dispatch(deleteBudgetTxThunk(selectedAccountId, tx));
    }
    doTxDeleted();
  }

  function handleLoadMore(direction) {
    const forward = direction === "forward";
    dispatch(budgetuiLoadMoreMonthsThunk(forward));
  }

  function handleCreateNewMonthClicked() {
    // TODO: Do we have repeat txs here? If so, pass them to create new month.
    dispatch(createBudgetMonthThunk(selectedAccountId, { lastBudgetFullYm }));
    dispatch(budgetuiShowMonthsAction(firstBudgetYmLoaded, nextYyyyMm(lastBudgetYmLoaded)));
  }

  // TODO: For larger screens, >tablet, show goals in a sticky rail at the right

  return (
    <Ref innerRef={contextRef}>
      <div>
      {loading && <LoadingComponent content='Loading...' />}
        <AppPageMenu
          loading={loading}
          ref={contextRef}
          title={selectedAccount?.name ?? "Looking for Account..."}
          activePageName='budget'
          showRepeatingTxsItem
          selectedAccountId={selectedAccountId}
          showScrollToToday
          onScrollToToday={handleScrollToToday}
          showAccountsMenu
          onAccountSelected={handleAccountSelected}
          showAddButton
          addButtonText='New Item'
          onAddButtonClicked={() =>
            dispatch(
              openModal("BudgetItemDetailsModal", {
                title: "New Budget Item",
                onSaveBudgetTx: (values) => handleTxSaved(null, values),
              })
            )
          }
        />
        {!loading && sortedAllTxIds.length === 0 ? (
          <div style={{ paddingTop: "65px" }}>
            <Divider horizontal hidden />
            <Message>
              <Message.Header>No budget items</Message.Header>
            </Message>
          </div>
        ) : (
          <div style={{ paddingTop: "65px" }}>
            {/* <BudgetListWithDrag
            as={Media}
            greaterThanOrEqual='computer'
            loading={loading}
            sortedMonthIds={sortedMonthIds}
            months={months}
            amtsBudgeted={amtsBudgeted}
            amtsActual={amtsActual}
            onLoadMore={handleLoadMore}
            onTxClicked={handleTxClicked}
          /> */}
            <BudgetListNoDrag
              as={Media}
              at='mobile'
              loading={loading}
              sortedMonthIds={sortedMonthIds}
              months={months}
              amtsBudgeted={amtsBudgeted}
              amtsActual={amtsActual}
              onLoadMore={handleLoadMore}
              onTxClicked={handleTxClicked}
              onCreateNewMonthClicked={handleCreateNewMonthClicked}
            />
          </div>
        )}
        <PageFooter />
      </div>
    </Ref>
  );
}
