import * as mystuffdb from "../../../sbdb.client/mystuffdb";
import * as budgettxs from "../../../sbdb.client/budgetdb.txs";
import {
  budgetAsyncError,
  budgetAsyncFinish,
  budgetAsyncStart,
  clearBudgetTxsAction,
  selectedAccountChangedAction,
} from "./budgetActions";
import { budgetuiShowMonthsAction } from "./UI/budgetuiActions";

// Update myStuff in the DB.
// Don't call this from a myStuff listener callback! See selectedAccountChangedAction.
export function changeSelectedBudgetAccountThunk(userId, accountId) {
  return async function (dispatch) {
    try {
      // null accountId is valid if the user deletes all their accounts.
      // null userId is NOT okay (unauthorized).
      if (!userId) {
        return;
      }
      dispatch(budgetAsyncStart());

      dispatch(clearBudgetTxsAction());
      dispatch(budgetuiShowMonthsAction("", ""));
      dispatch(selectedAccountChangedAction(accountId));
      await mystuffdb.setSelectedAccountId(userId, accountId);

      dispatch(budgetAsyncFinish());
    } catch (error) {
      dispatch(budgetAsyncError(error));
    }
  };
}

export function createBudgetTxThunk(accountId, tx, frequency, timeUnits) {
  return async function (dispatch) {
    try {
      dispatch(budgetAsyncStart());

      await budgettxs.createBudgetTx(accountId, tx, frequency, timeUnits);

      dispatch(budgetAsyncFinish());
    } catch (error) {
      dispatch(budgetAsyncError(error));
    }
  };
}

// options: lastBudgetFullYm, selAccountRepeatTxs (see budgettxs.createNewMonth)
export function createBudgetMonthThunk(accountId, options) {
  return async function (dispatch) {
    try {
      dispatch(budgetAsyncStart());

      await budgettxs.createNewMonth(accountId, options);

      dispatch(budgetAsyncFinish());
    } catch (error) {
      dispatch(budgetAsyncError(error));
    }
  };
}

export function updateBudgetTxThunk(accountId, oldTx, newTx) {
  return async function (dispatch) {
    try {
      dispatch(budgetAsyncStart());

      await budgettxs.updateBudgetTx(accountId, oldTx, newTx);

      dispatch(budgetAsyncFinish());
    } catch (error) {
      dispatch(budgetAsyncError(error));
    }
  };
}

export function deleteBudgetTxThunk(accountId, tx) {
  return async function (dispatch) {
    try {
      dispatch(budgetAsyncStart());

      await budgettxs.deleteBudgetTx(accountId, tx);

      dispatch(budgetAsyncFinish());
    } catch (error) {
      dispatch(budgetAsyncError(error));
    }
  };
}
