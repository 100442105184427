import React from "react";
import { Element } from "react-scroll";
import { Card } from "semantic-ui-react";

export default function SpendingMonthHeaderView({
  ym,
  monthName,
  incomeActual,
  expenseActual,
}) {
  return (
    <Card centered style={{backgroundColor: 'rgba(255, 255, 255, 0.7)'}}>
      <Card.Content>
        <Card.Header textAlign='center'>
          <Element name={ym}>{monthName}</Element>
        </Card.Header>
        <Card.Meta textAlign='center' style={{color: 'rgba(0, 0, 0, 0.7)'}}>
          <span>
            Income: {!isNaN(incomeActual) && `${Math.round(incomeActual)}`}
          </span><br />
          <span>
            Expenses: {!isNaN(expenseActual) && `${Math.round(expenseActual)}`}
          </span>
        </Card.Meta>
      </Card.Content>
    </Card>
  );
}
