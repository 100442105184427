import { Timestamp } from "firebase/firestore";

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists()) {
    return undefined;
  }
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }
  // This is custom for our objects because we have nested txs with dates.
  if (data.txs) {
    if (Array.isArray(data.txs)) {
      // Repeat txs and actual txs are an array.
      for (const tx of data.txs) {
        if ("startDate" in tx) {
          // repeat txs
          tx.startDate = tx.startDate.toDate();
        } else if ("date" in tx) {
          // actual txs
          tx.date = tx.date.toDate();
        }
        // Fix -0
        if ("amount" in tx && tx.amount === -0) {
          tx.amount = 0;
        }
      }
    } else {
      // Budget txs are an object with keys = txid
      for (const txId of Object.keys(data.txs)) {
        if ("date" in data.txs[txId]) {
          // budget txs
          data.txs[txId].date = data.txs[txId].date.toDate();
        }
        // Fix -0
        if ("amount" in data.txs[txId] && Object.is(-0, data.txs[txId].amount)) {
          data.txs[txId].amount = 0;
        }
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}
