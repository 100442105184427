import {
  TEST_ASYNC_START,
  TEST_ASYNC_FINISH,
  TEST_ASYNC_ERROR,
  INCREMENT_COUNTER,
  DECREMENT_COUNTER,
  COUNTER_ACTION_FINISHED,
  COUNTER_ACTION_STARTED
} from './testConstants';

export function testAsyncStart() {
  return {
    type: TEST_ASYNC_START
  }
}
export function testAsyncFinish() {
  return {
    type: TEST_ASYNC_FINISH
  }
}
export function testAsyncError(error) {
  console.log('error', error);
  return {
    type: TEST_ASYNC_ERROR,
    payload: {
      error
    }
  }
}

export function incrementCounter () {
  return {
    type: INCREMENT_COUNTER
  };
};

export function decrementCounter () {
  return {
    type: DECREMENT_COUNTER
  };
};

export function startCounterAction () {
  return {
    type: COUNTER_ACTION_STARTED
  };
};

export function finishCounterAction () {
  return {
    type: COUNTER_ACTION_FINISHED
  };
};

const delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export function incrementAsync () {
    return async dispatch => {
        dispatch(startCounterAction())
        await delay(1000);
        dispatch({type: INCREMENT_COUNTER})
        dispatch(finishCounterAction())
    }
}

export function decrementAsync () {
  return async dispatch => {
    dispatch(startCounterAction());
    await delay(1000);
    dispatch({ type: DECREMENT_COUNTER });
    dispatch(finishCounterAction());
  };
};
