import _ from "lodash";
import { SPENDING_UI_SHOW_MONTHS, SPENDING_UI_SET_CLOSEST_TXID_TO_TODAY, CLEAR_SPENDING_UI } from "./spendinguiConstants";

const initialState = {
  firstSpendingYmLoaded: "",
  lastSpendingYmLoaded: "",
  closestTxIdToToday: "",
};

export default function spendingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SPENDING_UI_SHOW_MONTHS:
      if (
        state.firstSpendingYmLoaded !== payload.firstSpendingYmLoaded ||
        state.lastSpendingYmLoaded !== payload.lastSpendingYmLoaded
      ) {
        return {
          ...state,
          firstSpendingYmLoaded: payload.firstSpendingYmLoaded,
          lastSpendingYmLoaded: payload.lastSpendingYmLoaded,
        };
      }
      break;

    case SPENDING_UI_SET_CLOSEST_TXID_TO_TODAY:
      if (state.closestTxIdToToday !== payload.closestTxIdToToday) {
        return {
          ...state,
          closestTxIdToToday: payload.closestTxIdToToday,
        };
      }
      break;

    case CLEAR_SPENDING_UI:
      return _.cloneDeep(initialState);

    default:
      // No-op, return original state below.
      break;
  }
  return state;
}
