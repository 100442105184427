import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import { closeModal } from "./Reducer/modalActions";

// buttons are optional if children is a form with its own buttons.
export default function ModalWrapper({ children, size, header, buttons }) {
  const dispatch = useDispatch();
  // Grab "open" from the store here rather than making us pass it down
  // from ModalManager -> YourModal -> ModalWrapper.
  const open = useSelector(state => state.modals?.open || false);
  return (
    <Modal open={open} onClose={() => dispatch(closeModal())} size={size}>
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>{children}</Modal.Content>
      {buttons && <Modal.Actions>{buttons}</Modal.Actions>}
    </Modal>
  );
}
