import { format } from "date-fns";
import _ from "lodash";
import { parseBudgetTxIdToYyyyMm } from "../../../../util/helpers";
import moment from "moment";

export function getClosestTxIdToToday(spendingMonthsLoaded) {
  const today = moment().startOf("day");
  // Find first spending tx id >= today, or last tx id
  // { [ym]: { txs: { [id]: { date, payee, amount } } } }
  // Map months to [{txid, date}]
  // for each month, concat the txs from that month
  const txsFlat = _.flatMap(spendingMonthsLoaded, (month) =>
    _.flatMap(month.txs, (tx, txId) => ({ ...tx, id: txId }))
  );
  // sort by date
  const sortedTxs = _.sortBy(txsFlat, "date");
  // find it
  return sortedTxs && sortedTxs.length > 0
    ? sortedTxs.find((txF) => moment(txF.date).isSameOrAfter(today))?.id ??
        sortedTxs[sortedTxs.length - 1].id
    : "";
}

// budgetTxsArray should be a flat array (no months objects) of {id, date, category, amount}
export function getBudgetTxSelectOptions(budgetTxsArray) {
  if (!budgetTxsArray) {
    return [];
  }
  // else
  return budgetTxsArray.map((txNearDate) => ({
    key: txNearDate.id,
    text: `${format(txNearDate.date, "PP")}  ${txNearDate.category}  ${txNearDate.amount.toFixed(
      2
    )}`,
    value: txNearDate.id,
  }));
}

// newSplits are from Formik array of UI components
// Convert [ { id, amount } ] into { [budgetTxId]: { goalId, amount } }
export function getNewSplitsWithGoalIds(newSplits, budgetMonths) {
  console.log('newSplits', newSplits);
  if (!newSplits || newSplits.length === 0) {
    return {};
  }
  return _.reduce(
    newSplits,
    (res, newSplit) => {
      // 0 is the value from noneOption.
      if (newSplit.id === 0) {
        return res;
      }
      const newBudgetTxYm = parseBudgetTxIdToYyyyMm(newSplit.id);
      // Budget txs get deleted and that doesn't update the spending tx (splits). That's on
      // purpose: we already updated the budget and goals and if a budget tx doesn't exist
      // then the spending tx won't be associated with it.
      if (newBudgetTxYm in budgetMonths && newSplit.id in budgetMonths[newBudgetTxYm].txs) {
        const newBudgetTx = budgetMonths[newBudgetTxYm].txs[newSplit.id];
        res[newSplit.id] = {
          amount: newSplit.amount,
          goalId: newBudgetTx.goalId || null, // Firestore wants null, not undefined
        };
      }
      return res;
    },
    {}
  );
}
