import React from "react";
import { Label } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../UI/Modals/Reducer/modalActions";
import ModalWrapper from "../../UI/Modals/ModalWrapper";
import MyTextInput from "../../UI/Form/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyCheckboxInput from "../../UI/Form/MyCheckboxInput";
import MySelectInput from "../../UI/Form/MySelectInput";
import { useNavigate } from "react-router-dom";
import SaveCancelConfirmDeleteButtons from "../../UI/Modals/SaveCancelConfirmDeleteButtons/SaveCancelConfirmDeleteButtons";
import { getSortedShownGoalsSelector } from "../../Goals/Selector/goalsSelectors";
import { getSortedAccountsSelector } from "../../Accounts/Selector/accountsSelectors";

// values are the current Formik form values, used for conditionally showing fields.
export default function RepeatTxDetailsModal({ title, accountId, txToEdit, onSubmit, onDelete }) {
  const dispatch = useDispatch();

  const myAccounts = useSelector(getSortedAccountsSelector);
  // need [{id, name}], reduce from goals where isShowing, sort by name
  const shownGoals = useSelector(getSortedShownGoalsSelector);
  const navigate = useNavigate();

  // Repeat txs are edit-only. They can only be created by adding a new budget tx.
  if (!txToEdit) {
    navigate(-1);
    return;
  }

  const tgtAccounts = myAccounts.filter(accountF => accountF.id !== accountId);

  if (
    txToEdit &&
    txToEdit.isTransfer &&
    !tgtAccounts.find((tgtAccountF) => tgtAccountF.id === txToEdit.category)
  ) {
    return (
      <ModalWrapper size='mini' header={title}>
        <h2>
          This is a transfer and you don't have access to the target account. Ask the owner to share
          the account with you.
        </h2>
      </ModalWrapper>
      // TODO: Should we let the user delete the repeat xfer? (show an OK button too)
    );
  }

  const tgtAccountOptions = tgtAccounts
    .map((tgtAccount) => ({
      key: tgtAccount.id,
      text: tgtAccount.name,
      value: tgtAccount.id,
    }));

  const timeUnitsOptions = [
    { key: "days", text: "Days", value: "days" },
    { key: "weeks", text: "Weeks", value: "weeks" },
    { key: "months", text: "Months", value: "months" },
    { key: "years", text: "Years", value: "years" },
  ];

  const goalOptions =
    shownGoals && shownGoals.length > 0
      ? shownGoals.map((goal) => ({
          key: goal.id,
          text: goal.name,
          value: goal.id,
        }))
      : [{ key: "nogoal", text: "No Goals", value: null }];

  return (
    <ModalWrapper size='mini' header={title}>
      <Formik
        initialValues={{ ...txToEdit,
          tgtAccountId: txToEdit.isTransfer ? txToEdit.category : "",
          category: txToEdit.isTransfer ? "" : txToEdit.category
        }}
        validationSchema={Yup.object({
          category: Yup.string().when("isTransfer", {
            is: false,
            then: Yup.string().required(),
            otherwise: Yup.string(),
          }),
          tgtAccountId: Yup.string().when("isTransfer", {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string(),
          }),
          amount: Yup.number().required(),
          frequency: Yup.number().required(),
          timeUnits: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            onSubmit({
              ...txToEdit,
              ...values, // values doesn't have non-editable fields, like startDate so get it from txToEdit.
            });

            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            setErrors({ auth: "Error updating the item." });
            setSubmitting(false);
          }
        }}>
        {({ isSubmitting, isValid, dirty, errors, values }) => (
          <Form className='ui form'>
            {values && !values.isTransfer ? (
              <MyTextInput name='category' placeholder='Category' />
            ) : (
              <MySelectInput
                name='tgtAccountId'
                options={tgtAccountOptions}
                placeholder='Target Account'
              />
            )}
            {tgtAccountOptions.length > 0 && <MyCheckboxInput name='isTransfer' label='Transfer' />}
            <MyTextInput name='amount' placeholder='0.00' type='number' label='Budgeted' />
            <MyTextInput name='frequency' label='Every' type='number' />
            <MySelectInput name='timeUnits' placeholder='Units' options={timeUnitsOptions} />
            <MySelectInput name='goalId' placeholder='Goal' options={goalOptions} />
            {errors.auth && (
              <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
            )}
            <SaveCancelConfirmDeleteButtons
              showDelete={txToEdit != null}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
